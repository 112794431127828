import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    OnDestroy
} from '@angular/core';
import {
    fromEvent,
    of,
    Unsubscribable,
    Observable
} from 'rxjs';
import {
    map,
    pairwise,
    filter,
    exhaustMap
} from 'rxjs/operators';

interface ScrollPosition {
    scrollHeight: number;
    scrollTop: number;
    clientHeight: number;
}

@Directive({
    selector: '[vmwInfiniteScroll]',
})
export class VmwInfiniteScrollDirective implements AfterViewInit, OnDestroy {
    private userScrolls: Observable<{}>;
    private userScrollsSubscription: Unsubscribable;
    private _isActive = true;

    @Input()
    scrollPercentTrigger = 99.9;

    @Input()
    set enableInfiniteScroll(value: boolean) {
        if (this._isActive === undefined || this._isActive === value) {
            this._isActive = value;
            return;
        }
        this._isActive = value;

        if (value) {
            this.subscribeToScroll();
            return;
        }

        this.disableUserScroll();
    }

    @Output('onScroll') showMoreItems = new EventEmitter<void>();

    constructor(private containerEl: ElementRef) {}

    ngAfterViewInit() {
        this.streamScrollEvents();
        this.subscribeToScroll();
    }

    ngOnDestroy() {
        this.disableUserScroll();
    }

    private streamScrollEvents(): void {
        this.userScrolls = fromEvent(this.containerEl.nativeElement, 'scroll')
            .pipe(
                filter((e: any): boolean => this._isActive),
                map(
                    (e: any): ScrollPosition => ({
                        scrollHeight: e.target.scrollHeight,
                        scrollTop: e.target.scrollTop,
                        clientHeight: e.target.clientHeight
                    })
                ),
                pairwise(),
                filter(
                    positions =>
                        this.isUserScrollingDown(positions) &&
                        this.isPercentThresholdReached(positions[1])
                )
            );
    }

    private subscribeToScroll(): void {
        this.userScrollsSubscription = this.userScrolls
            .pipe(
                exhaustMap(() => of(this.showMoreItems.emit())))
            .subscribe();
    }

    private isUserScrollingDown = (positions: Array<ScrollPosition>) => {
        return positions[0].scrollTop < positions[1].scrollTop;
    }

    private isPercentThresholdReached = (position: ScrollPosition) => {
        return (position.scrollTop + position.clientHeight) / position.scrollHeight > this.scrollPercentTrigger / 100;
    }

    private disableUserScroll = () => {
        return this.userScrollsSubscription && this.userScrollsSubscription.unsubscribe();
    }
}
