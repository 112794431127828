// Generated at 2018-08-03T19:56:20.714Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AsyncService } from './Async';
import { InfoService } from './Info';
import { SampleExchangeContribution } from '../types/SampleExchangeContribution';
import { SampleExchangeSample } from '../types/SampleExchangeSample';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ContributionsService {
    public static instance: ContributionsService;

    public Async: AsyncService;
    public Info: InfoService;

    constructor(public config: TangoServiceConfiguration) {
        this.Async = new AsyncService(this.config);
        this.Info = new InfoService(this.config);

        if (!ContributionsService.instance) {
            ContributionsService.instance = this;
        }
    }

    createContribution(body: SampleExchangeContribution, headers: any = {}): Observable<SampleExchangeSample | any> {
        let s = new Subject<SampleExchangeSample | any>();

        let internalSuccessHandler = (result: SampleExchangeSample) => {
            s.next(new SampleExchangeSample(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/contributions', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
