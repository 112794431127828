import {
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import {
    ClrPopoverPosition,
    ClrAxis,
    ClrSide,
    ClrAlignment,
    ClrPopoverEventsService,
    ClrPopoverToggleService,
    ClrPopoverPositionService,
} from "@clr/angular";

import { KeyCodes } from "./key-codes";

import { VmwComboboxItem } from "./combobox-items/combobox-item.model";
import { VmwComboboxItemsComponent } from "./combobox-items/combobox-items.component";
import { VmwSimpleTranslateService } from '@vmw/ngx-utils';
import { TRANSLATIONS } from './combobox.l10n';

export const TIMEOUT = 200;

@Component({
    selector: "vmw-combobox",
    templateUrl: "./combobox.component.html",
    styleUrls: ["./combobox.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VmwComboboxComponent),    // tslint:disable-line:no-forward-ref
            multi: true
        },
        ClrPopoverEventsService,
        ClrPopoverToggleService,
        ClrPopoverPositionService,
    ]
})
export class VmwComboboxComponent<T extends {toString(): string} = string> implements ControlValueAccessor {
    private _id: string = new Date().toString();

    @ViewChild('inputControl', {static: true}) inputEle: ElementRef;

    @Input() items: Array<VmwComboboxItem<T>>;
    @Input() placeholder: string = "";
    @Input() ariaLabel: string = "";
    @Input() isAddNewAllowed: boolean = true;
    @Input() filterItemsWhenTyping: boolean = false;
    @Input() autoComplete: boolean = false;
    @Input() isValid: boolean = true;
    @Input() errorMessage: string = "";
    @Input() noItemsFoundString: string;
    @Input() multiSelect: boolean = false;
    @Input() filterIncludes: boolean = false;
    @Input() showSpinner: boolean = false;
    @Input() autoSelectOnPrefix: boolean = true;
    @Input() showTooltip: boolean = true;
    @Input() disabled: boolean = false;
    @Input() browserAutoComplete: string = 'on';
    @Input() debounceTime: number = 1000;
    @Input() set id(id: string) {
        this._id = id;
        if (this.inputEle) {
            this.renderer.setAttribute(this.inputEle.nativeElement, 'id', id);
            this.renderer.removeAttribute(this.elementRef.nativeElement, 'id');
        }
    }

    get id() {
        return this._id;
    }

    @Input() set position(pos: string) {
        switch(pos) {
            case 'bottom-right':
                this.clrPopoverPosition = {
                    axis: ClrAxis.VERTICAL,
                    side: ClrSide.AFTER,
                    anchor: ClrAlignment.END,
                    content: ClrAlignment.END,
                };
                break;
            default:
            case 'bottom-left':
                this.clrPopoverPosition = {
                    axis: ClrAxis.VERTICAL,
                    side: ClrSide.AFTER,
                    anchor: ClrAlignment.START,
                    content: ClrAlignment.START,
                };
                break;
        }
    }

    @Output() onLoadMore: EventEmitter<void> = new EventEmitter<void>();
    @Output() searchValue: EventEmitter<string> = new EventEmitter<string>();
    @Output() singleValueChanged: EventEmitter<VmwComboboxItem<T>> = new EventEmitter<VmwComboboxItem<T>>();

    public activeDescendantId: string;
    public displayImage: string;
    public hasSearchSubscribers: boolean = false;
    public showSuggestions: boolean = false;
    @ViewChild(VmwComboboxItemsComponent, { static: true })
    private comboboxItems: VmwComboboxItemsComponent<T>;
    @ViewChild("inputControl", { static: true })
    private input: ElementRef;
    /** The template passed in by the user */
    @ContentChild(TemplateRef, { static: false })
    public itemTemplate: TemplateRef<any>;
    private showingSuggestionsInProgress: boolean = false;

    public clrPopoverPosition: ClrPopoverPosition;

    // ControlValueAccessor interface implementation.
    private onChangeCallback = (_: any) => {};
    private onTouchedCallback = () => {};
    private keyUpPressed: KeyCodes;
    private currentSelection: VmwComboboxItem<T>|Array<VmwComboboxItem<T>>;
    private searchSubscription: Subscription;
    private searchSubject: Subject<string> = new Subject<string>();

    constructor(private elementRef: ElementRef,
                protected changeDetectorRef: ChangeDetectorRef,
                private clrSmartToggleService: ClrPopoverToggleService,
                private renderer: Renderer2,
                public translateService: VmwSimpleTranslateService) {
        // call the setter for the default position
        this.position = 'bottom-left';
        this.translateService.loadTranslationsForComponent('combobox', TRANSLATIONS);
    }

    private _displayValue: string = "";
    get displayValue(): string {
        return this._displayValue;
    }

    get ariaControlsId(): string {
        return `combobox-items-${this.id}`;
    }

    set displayValue(value: string) {
        if (!this.comboboxItems) {
            return;
        }
        this.searchSubject.next(value);
        if (this.filterItemsWhenTyping) {
            this.comboboxItems.filter(value);
        }
        if (!this.isAddNewAllowed &&
            !this.comboboxItems.findByDisplayValue(value) &&
            value) {
            return;
        }
        if (value !== this._displayValue) {
            this._displayValue = value.toString();
            let comboboxItem = this.comboboxItems.findByDisplayValue(value);
            if (!comboboxItem) {
                // Create a fake item if isAddNewAllowed is true and the
                // matching item is not found. Cast needed because for generic types, there won't be
                // any info other items have, just the toString() and browser won't let me cast it to T
                comboboxItem = new VmwComboboxItem<T>(value as any, value);
            }

            this.changeDetectorRef.detectChanges();

            if (this.onChangeCallback) {
                this.onChangeCallback(comboboxItem);
            }
        }
    }

    onKeyPress(event: KeyboardEvent) {
        const prefix = (this.displayValue ? this.displayValue : "") + event.key;
        if (!this.isAddNewAllowed && !this.comboboxItems.findByDisplayValue(prefix)
                && !this.hasSearchSubscribers) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === KeyCodes.Tab) {
            this.enterPressed();
        }
    }

    enterPressed() {
        const input =  this.input.nativeElement;
        let autocompleteItem;
        if (this.autoComplete) {
            if (!this.isAddNewAllowed && this.currentSelection && !Array.isArray(this.currentSelection)) {
                autocompleteItem = this.currentSelection;
            } else {
                autocompleteItem = this.comboboxItems.getFirstItemContainingPrefix(this.displayValue);
            }
        }
        if (autocompleteItem) {
            this.displayValue = autocompleteItem.displayValue.toString();
            this.displayImage = autocompleteItem.img;
            this.comboboxItems.selectItem(autocompleteItem);
            if (!this.multiSelect) {
                this.singleValueChanged.emit(autocompleteItem);
                this.hideList();
            }
            input.blur();
        } else if (this.currentSelection) {
            this.setDisplayValue(this.currentSelection);
            if (!this.isAddNewAllowed && !this.multiSelect && !Array.isArray(this.currentSelection)) {
                this.singleValueChanged.emit(this.currentSelection);
                this.hideList();
            }
            this.currentSelection = null;
            input.blur();
        } else if (this.isAddNewAllowed) {
            this.comboboxItems.clearSelection();
            this.hideList();
            input.blur();
        }
    }

    onKeyUp(event: KeyboardEvent) {
        const input =  this.input.nativeElement;
        let autocompleteItem;

        if(!this.comboboxItems){
            return;
        }
        switch (event.keyCode) {
            case KeyCodes.Delete:
            case KeyCodes.Backspace:
                return;
            case KeyCodes.Up:
                this.keyUpPressed = KeyCodes.Up;
                this.comboboxItems.selectPrev();
                break;
            case KeyCodes.Down:
                this.keyUpPressed = KeyCodes.Down;
                this.comboboxItems.selectNext();
                break;
            case KeyCodes.Enter:
                this.enterPressed();
                break;
            default:
                if (this.autoComplete) {
                    autocompleteItem = this.comboboxItems.getFirstItemContainingPrefix(this.displayValue);
                    if (autocompleteItem) {
                        const start = this.displayValue.length;
                        const end = autocompleteItem.displayValue.toString().length;
                        input.value = autocompleteItem.displayValue;
                        input.setSelectionRange(start, end);
                    }
                }
        }

        // Handle updating the currentSelection as the user types, issue was found
        // during testing of vSphere Client where incorrect value is emitted on document
        // click after user types in value.
        if (!this.multiSelect && this.isAddNewAllowed) {
            this.currentSelection = new VmwComboboxItem(input.value, input.value);
        }
    }

    onFocus() {
        this.showSuggestions = true;
        this.showingSuggestionsInProgress = true;

        if (this.multiSelect) {
            this.input.nativeElement.blur();
            return;
        }

        this.input.nativeElement.setSelectionRange(0, this.input.nativeElement.value.length);
    }

    hideList() {
        if (this.multiSelect) {
            return;
        }

        this.showingSuggestionsInProgress = false;

        // Timeout the hide to catch a click event on the list when the user
        // clicks the comobobox-items.
        setTimeout(() => {
            if (!this.showingSuggestionsInProgress) {
                this.showSuggestions = false;

                if (this.onTouchedCallback) {
                    this.onTouchedCallback();
                }

                this.changeDetectorRef.markForCheck();
            }
        }, TIMEOUT);
    }

    setActiveDescendant(id: string) {
        this.activeDescendantId = id;
    }

    setDisplayValue(selection: VmwComboboxItem<T>|Array<VmwComboboxItem<T>>) {
        if (this.keyUpPressed === KeyCodes.Up || this.keyUpPressed === KeyCodes.Down) {
            this.keyUpPressed = null;
            this.currentSelection = selection;
            return;
        }
        if (Array.isArray(selection)) {
            this.displayValue = selection.map((item: VmwComboboxItem<T>) => {
                return item.displayValue;
            }).join(", ");
            this.onChangeCallback(selection);
        } else {
            this.displayValue = selection.displayValue.toString();
            this.displayImage = selection.img;
            if (!selection.displayValue) { //value cleared
                this.comboboxItems.clearSelection();
                this.currentSelection = null;
                this.singleValueChanged.emit(null);
                this.hideList();
            }
        }
    }

    onItemClicked(item: VmwComboboxItem<T>) {
        if (!this.isAddNewAllowed && !this.multiSelect) {
            this.singleValueChanged.emit(item);
        }
        this.hideList();
    }

    ngOnInit() {
        if (!this.placeholder) {
            this.placeholder = "";
        }
        this.searchSubscription = this.searchSubject.pipe(
            debounceTime(this.debounceTime),
            distinctUntilChanged())
            .subscribe(text => this.searchValue.emit(text));
        this.hasSearchSubscribers = this.searchValue.observers.length > 0;

        this.clrSmartToggleService.openChange.subscribe((open: boolean) => {
            this.showSuggestions = open;
        });
    }

    ngOnDestroy() {
        if(this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }

    toggleComboboxItems() {
        this.showSuggestions = !this.showSuggestions;
        if (this.showSuggestions) {
            this.input.nativeElement.focus();
        }
        this.input.nativeElement.setSelectionRange(
            0, this.input.nativeElement.value.length);
    }

    // ControlValueAccessor interface implementation.
    writeValue(value: VmwComboboxItem<T>|Array<VmwComboboxItem<T>>) {
        if (!value) {
            this._displayValue = "";
            this.displayImage = "";
            this.currentSelection = null;
            if (this.comboboxItems) {
                this.comboboxItems.clearSelection();
            }
            return;
        }

        if (Array.isArray(value)) {
            this.setDisplayValue(value);
            this.comboboxItems.selectItems(value);

        } else if (value instanceof VmwComboboxItem) {
            if (!this.isAddNewAllowed) {
                const exactFoundItem = this.items?.find((item) => item === value);
                const itemFoundByDisplayName = this.comboboxItems?.findByDisplayValue(value.displayValue.toString());
                if (!exactFoundItem && !itemFoundByDisplayName) {
                    throw new Error(`The passed in combobox item was not found: ${value.displayValue.toString()}`);
                }
            }
            if (value.displayValue.toString() !== this._displayValue.toString()) {
                this._displayValue = value.displayValue.toString();
                if (this.comboboxItems) {
                    this.comboboxItems.selectItem(value);
                    this.currentSelection = value;
                }
            }
            this.displayImage = value.img;
        } else {
            throw new Error("The type should be an instance of ComboboxItem");
        }
    }

    /**
     * Set the function to be called when the control receives a change event.
     * This function is called by Angular when the NgModel binding is used.
     */
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    /**
     * Set the function to be called when the control receives a touch event.
     * This function is called by Angular when the NgModel binding is used.
     */
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    /**
     * A passthrough function to re-emit infinite scroll's event for loading more items
     */
    showMoreItems() {
        this.onLoadMore.emit();
    }

    @HostListener("document:click", ["$event"])
    documentClick(event: Event) {
        if (!this.showSuggestions) {
            return;
        }

        let foundItemsContainer = false;

        let target: any = event.target;

        while(target && target.parentElement) {
            if (target.id.toLowerCase() === 'combobox-items') {
                foundItemsContainer = true;
            }
            target = target.parentElement;
        }

        // Closes the dropd-down if the user clicked outside of the component.
        if (!this.elementRef.nativeElement.contains(event.target) &&
                !foundItemsContainer &&
                (<Element>event.target).className !== "clear-icon") {
            this.showSuggestions = false;
            this.enterPressed();
            this.onTouchedCallback();
        }
    }
}
