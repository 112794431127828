// tslint:disable


export class APIXFormDataContentDisposition {
    public type: string;
    public parameters: Object;
    public fileName: string;
    public creationDate: string;
    public modificationDate: string;
    public readDate: string;
    public size: number;
    public name: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
