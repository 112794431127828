// tslint:disable


export class SampleExchangeSampleFileHandling {
    public id: number;
    public fileExtension: string;
    public handlingType: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
