// Generated at 2018-08-03T19:56:17.033Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { ImportService } from './Import';
import { ProvidedApisService } from './ProvidedApis';
import { ResourcesService } from './Resources';
import { UsedApisService } from './UsedApis';
import { APIXRelease } from '../../types/APIXRelease';
import { APIXErrorResponse } from '../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class GetReleasesQueryParams {
    state?: string;
    name_substring?: string;
}

export class ReleasesService {
    public static instance: ReleasesService;

    public Import: ImportService;
    public ProvidedApis: ProvidedApisService;
    public Resources: ResourcesService;
    public UsedApis: UsedApisService;

    constructor(public config: TangoServiceConfiguration) {
        this.Import = new ImportService(this.config);
        this.ProvidedApis = new ProvidedApisService(this.config);
        this.Resources = new ResourcesService(this.config);
        this.UsedApis = new UsedApisService(this.config);

        if (!ReleasesService.instance) {
            ReleasesService.instance = this;
        }
    }

    getReleases(queryParams: GetReleasesQueryParams, headers: any = {}): Observable<Array<APIXRelease> | any> {
        let s = new Subject<Array<APIXRelease> | any>();

        let internalSuccessHandler = (result: Array<APIXRelease>) => {
            s.next(result.map(item => new APIXRelease(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };


        let result = this.config.transport.get('/apix/releases', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createRelease(body: APIXRelease, headers: any = {}): Observable<APIXRelease | APIXErrorResponse> {
        let s = new Subject<APIXRelease | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXRelease) => {
            s.next(new APIXRelease(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/apix/releases', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getRelease(release_id: string, headers: any = {}): Observable<APIXRelease | APIXErrorResponse> {
        let s = new Subject<APIXRelease | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXRelease) => {
            s.next(new APIXRelease(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_id: release_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/releases/{release_id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateRelease(release_id: string, body: APIXRelease, headers: any = {}): Observable<APIXRelease | APIXErrorResponse> {
        let s = new Subject<APIXRelease | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXRelease) => {
            s.next(new APIXRelease(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_id: release_id,
        };

        let queryParams = {};

        let result = this.config.transport.put('/apix/releases/{release_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteRelease(release_id: string, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_id: release_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/apix/releases/{release_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
