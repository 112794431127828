import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { CommonModule } from "@angular/common";

import { VmwSnackbarComponent } from "./snackbar.component";
import { VmwSnackComponent } from "./snack.component";

@NgModule({
    declarations: [
        VmwSnackbarComponent,
        VmwSnackComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        VmwSnackbarComponent,
        VmwSnackComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class VmwSnackbarModule {}
