import {
    Component,
    Output,
    EventEmitter,
    Input,
    ElementRef,
    ViewChild,
    OnChanges, OnDestroy
} from "@angular/core";

import { VmwSimpleTranslateService } from "@vmw/ngx-utils";

import { TRANSLATIONS } from './right-drawer.l10n';

const CLARITY_HEADER_HEIGHT = 60;
const FOCUSABLE_ELEMENTS = [
    'button:not(.tooltip-trigger)',
    '[href]',
    'input',
    'select',
    'textarea',
    '[tabindex]:not([tabindex="-1"]):not(.tooltip-trigger)',
].join(', ');

@Component({
    selector: "vmw-right-drawer",
    templateUrl: "right-drawer.component.html",
    styleUrls: ["./right-drawer.component.scss"],
})
export class VmwRightDrawerComponent implements OnChanges, OnDestroy {
    @Input() open: boolean = false;
    @Input() showBackdrop: boolean = true;
    @Input() pinnable: boolean = false;
    @Input() pinned: boolean = false;
    @Input() topOffset: number = 0;
    @Input() headerHeight: number = CLARITY_HEADER_HEIGHT;
    @Input() hideFixedBottom: boolean = false;
    @Input() closeOnUnpin: boolean = false;
    @Input() focusFirstDrawerElement: boolean = false;
    @Input() backdropOpacity: number = 0.85;

    @Output() show: EventEmitter<any> = new EventEmitter<any>();
    @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() hide: EventEmitter<any> = new EventEmitter<any>();
    @Output() showing: EventEmitter<any> = new EventEmitter<any>();
    @Output() hiding: EventEmitter<any> = new EventEmitter<any>();
    @Output() drawerPinStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() pinnedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() keyboardFocusOut: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('rightDrawer', {read: ElementRef, static: false}) rightDrawerRef: ElementRef;

    backdrop = false;
    visible = false;
    isHiding = false;
    slideDelayMillis = 200; // must change CSS animation as well

    private isMouseUsed: boolean = false;
    private mouseDownListener: () => void;
    private mouseUpListener: () => void;
    private focusOutListener: (e: any) => void;
    private drawerElement: HTMLElement;

    public constructor(public translateService: VmwSimpleTranslateService,
                       private elementRef: ElementRef) {
        this.translateService.loadTranslationsForComponent('right-drawer', TRANSLATIONS);

        this.drawerElement = elementRef.nativeElement;

        this.mouseDownListener = () => {
            this.isMouseUsed = true;
        };

        this.mouseUpListener = () => {
            this.isMouseUsed = false;
        };

        this.focusOutListener = (e) => {
            const isNewElementFocused = e.relatedTarget !== null && e.relatedTarget !== document.body;
            const drawerContainsFocusedElement = this.drawerElement.contains(e.relatedTarget as Node);

            if (!this.isMouseUsed && isNewElementFocused && !drawerContainsFocusedElement) {
                this.keyboardFocusOut.emit();
            }
        }

        document.addEventListener('mousedown', this.mouseDownListener, true);
        document.addEventListener('mouseup', this.mouseUpListener, true);
        this.drawerElement.addEventListener('focusout', this.focusOutListener);
    }

    get containerHeight(): string {
         return 'calc(100vh - ' + (this.topOffset + this.headerHeight) + 'px)';
    }

    ngOnChanges(changes: any) {
        if (changes && changes.open) {
            if (changes.open.currentValue) {
                this.showDrawer();
            } else if (changes.open.previousValue) {
                this.hideDrawer(false);
            }
        }

        if (changes && changes.pinned) {
            this.backdrop = !this.pinned;
        }

        if (changes && changes.focusFirstDrawerElement) {
            this.focusFirstFocusableElement();
        }
    }

    ngOnDestroy() {
        document.removeEventListener('mousedown', this.mouseDownListener, true);
        document.removeEventListener('mouseup', this.mouseUpListener, true);
        this.drawerElement.removeEventListener('focusout', this.focusOutListener);
    }

    togglePinState(emitEvent: boolean = true) {
        if (this.pinned && this.closeOnUnpin) {
            this.hideDrawer();
        }

        this.pinned = !this.pinned;

        if (this.pinned) {
            this.backdrop = false;
        } else {
            this.backdrop = this.showBackdrop;
        }

        if (emitEvent) {
            this.drawerPinStateChanged.emit(this.pinned);
        }

        this.pinnedChange.emit(this.pinned);
    }

    hideDrawer(emit: boolean = true): any {
        this.isHiding = true;
        this.backdrop = false;

        if (this.pinned && !this.isHiding) {
            this.togglePinState(false);
        }

        this.hiding.emit(null);

        setTimeout(() => {
            this.isHiding = false;
            this.visible = false;
            this.hide.emit(null);
            this.open = false;
            if (emit) {
                this.openChange.emit(false);
            }
        }, this.slideDelayMillis);
    }

    private focusFirstFocusableElement() {
        if (!this.rightDrawerRef) {
            return;
        }

        const allElements = this.rightDrawerRef.nativeElement.querySelectorAll(FOCUSABLE_ELEMENTS) as NodeListOf<HTMLElement>;
        for (let index = 0; index < allElements.length; index++) {
            const el = allElements[index];
            const style = window.getComputedStyle(el);
            if (style.display !== 'none' && style.visibility !== 'hidden' && el.offsetParent !== null) {
                el.focus();
                break;
            }
        }
    }

    private showDrawer() {
        this.backdrop = this.showBackdrop && !this.pinned;
        this.visible = true;
        this.showing.emit(null);

        setTimeout(() => {
            this.show.emit(null);
            this.focusFirstFocusableElement();
        }, this.slideDelayMillis);
    }
}
