import {
    Component,
} from "@angular/core";

@Component({
    selector: "vmw-accordion-group",
    templateUrl: "accordion-group.component.html",
    styleUrls: ["./accordion-group.component.scss"]
})
export class VmwAccordionGroup {}
