// Generated at 2018-08-03T19:56:16.995Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXErrorResponse } from '../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ModifiedService {
    public static instance: ModifiedService;


    constructor(public config: TangoServiceConfiguration) {

        if (!ModifiedService.instance) {
            ModifiedService.instance = this;
        }
    }

    getApiResourceLastModified(api_id: string, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_id: api_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/apis/{api_id}/modified', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
