// tslint:disable


export class SampleExchangePoint {
    public rewardId: number;
    public scnroId: number;
    public userId: number;
    public username: string;
    public code: string;
    public sourceObject: string;
    public comment: string;
    public point: number;
    public creationDate: number;
    public modificationDate: number;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
