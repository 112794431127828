import { Pipe, PipeTransform } from '@angular/core';

import { Converter } from 'showdown';

const classMap = {
    table: 'table',
}

const bindings = Object.keys(classMap).map(key => ({
    type: 'output',
    regex: new RegExp(`<${key}(.*)>`, 'g'),
    replace: `<${key} class="${classMap[key]}" $1>`
}));

@Pipe({
    name: 'markdown2html',
})
export class DevCenterMarkdownToHTMLPipe implements PipeTransform {
    transform(value: string): string {
        let converter = new Converter({
            tables: true,
            extensions: [...bindings],
        });
        return converter.makeHtml(value);
    }
}
