import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SampleExchangeSample } from '../api/SampleExchange/types';
import { SampleExchangeService } from '../api/SampleExchange';
import { SearchSamplesQueryParams } from '../api/SampleExchange/Search/Samples';

export const SAMPLE_EXCHANGE_PRODUCTION_BASE_URL = 'https://apigw.vmware.com/sampleExchange/v1';
const SAMPLE_EXCHANGE_SEARCH_PLATFORM = '/search/samples?platform={platform}';
const SAMPLE_EXCHANGE_SEARCH_TAG = '/search/samples?req_tag={tag}';

import {
    TangoServiceConfiguration,
    TangoAngularHttpClientAdapter,
} from '@vmw/tango';

@Injectable()
export class VmwSampleExchangeService {
    private service: SampleExchangeService;
    private clientID: string;
    private clientUUID: string;
    private clientUserID: string;
    private serviceBaseUrl: string;
    private authHeader: string;
    private readonly options: any = {};

    constructor(private http: HttpClient) {
        this.setServiceBaseUrl(SAMPLE_EXCHANGE_PRODUCTION_BASE_URL);
    }

    private setHttpOptions() {
        let headers = new HttpHeaders();

        headers = headers.set('X-VMware-Code-Client', this.clientID);
        headers = headers.set('X-VMware-Code-Client-UUID', this.clientUUID);
        headers = headers.set('X-VMware-Code-User', this.clientUserID);

        if (this.authHeader) {
            headers = headers.set('Authorization',this.authHeader);
        }

        Object.assign(this.options, {
            headers: headers,
        });
    }

    public setServiceBaseUrl(baseUrl: string) {
        if (this.serviceBaseUrl !== baseUrl) {
            this.serviceBaseUrl = baseUrl;
            console.log('Updating SX base URL:',this.serviceBaseUrl);
        }
        let config: TangoServiceConfiguration = {
            transport: new TangoAngularHttpClientAdapter(
                this.http, this.serviceBaseUrl, this.options)
        };
        this.service = new SampleExchangeService(config)
    }

    public setClientID(client: string) {
        this.clientID = client;
        this.setHttpOptions();
    }

    public setClientUUID(uuid: string) {
        this.clientUUID = uuid;
        this.setHttpOptions();
    }

    public setClientUserID(user: string) {
        this.clientUserID = user;
        this.setHttpOptions();
    }

    public setAuthHeader(value: string) {
        this.authHeader = value;
        this.setHttpOptions();
    }

    public getSamplesByPlatform(platform: string, summary: boolean = true) {
        return this.service.Search.Samples.searchSamples({
            platform: platform,
            summary: summary.toString(),
        } as SearchSamplesQueryParams);
    }

    public getSamplesByTagName(tags: string[], summary: boolean = true) {
        return this.service.Search.Samples.searchSamples({
            tags: tags,
            summary: summary.toString(),
        } as SearchSamplesQueryParams);
    }

    public getSamples(platform: string, tags: string[], reqTags: string|string[], summary: boolean = true) {
        let queryParams: SearchSamplesQueryParams = {
            platform: platform,
            summary: summary.toString(),
        };

        if (tags) {
            queryParams.tag = tags.join(',');
        }

        if (reqTags) {
            if (Array.isArray(reqTags)) {
                queryParams.req_tag = reqTags.join(',');
            } else {
                queryParams.req_tag = reqTags;
            }
        }

        return this.service.Search.Samples.searchSamples(queryParams);
    }
}
