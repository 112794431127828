import {Injectable} from "@angular/core";
import {
   HttpHeaders,
   HttpParams
} from "@angular/common/http";
import { CurlData } from "./curl-data";

@Injectable({
   providedIn: "root"
})
export class CurlDataParserService {

   getCurlData(headers: HttpHeaders, params: HttpParams, path: any,
           body: string, response: any): CurlData {
      let result: CurlData = new CurlData();
      result.headers = headers;
      result.method = path.method.toUpperCase();
      result.body = body;
      result.url = path.response.url;
      if (params.keys().length > 0) {
         result.url = result.url + "?" + params.toString();
      }
      return result;
   }
}