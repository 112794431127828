// Generated at 2018-08-03T19:56:16.918Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AccessGroupsService } from './AccessGroups';
import { ApiGroupsService } from './ApiGroups';
import { ArtifactsService } from './Artifacts';
import { CompatibilityLevelsService } from './CompatibilityLevels';
import { DefsService } from './Defs';
import { StatesService } from './States';
import { TechnologiesService } from './Technologies';
import { UidsService } from './Uids';
import { VisibilitiesService } from './Visibilities';
import { MethodsService } from './Methods';
import { ModifiedService } from './Modified';
import { RefdocService } from './Refdoc';
import { ResourcesService } from './Resources';
import { APIXApi } from '../../types/APIXApi';
import { APIXErrorResponse } from '../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class GetApisQueryParams {
    state?: string;
    compatibility_level?: string;
    tag?: string;
    api_uid_substring?: string;
    name_substring?: string;
}
export class CreateApiQueryParams {
    override_api_def?: string;
}

export class ApisService {
    public static instance: ApisService;

    public AccessGroups: AccessGroupsService;
    public ApiGroups: ApiGroupsService;
    public Artifacts: ArtifactsService;
    public CompatibilityLevels: CompatibilityLevelsService;
    public Defs: DefsService;
    public States: StatesService;
    public Technologies: TechnologiesService;
    public Uids: UidsService;
    public Visibilities: VisibilitiesService;
    public Methods: MethodsService;
    public Modified: ModifiedService;
    public Refdoc: RefdocService;
    public Resources: ResourcesService;

    constructor(public config: TangoServiceConfiguration) {
        this.AccessGroups = new AccessGroupsService(this.config);
        this.ApiGroups = new ApiGroupsService(this.config);
        this.Artifacts = new ArtifactsService(this.config);
        this.CompatibilityLevels = new CompatibilityLevelsService(this.config);
        this.Defs = new DefsService(this.config);
        this.States = new StatesService(this.config);
        this.Technologies = new TechnologiesService(this.config);
        this.Uids = new UidsService(this.config);
        this.Visibilities = new VisibilitiesService(this.config);
        this.Methods = new MethodsService(this.config);
        this.Modified = new ModifiedService(this.config);
        this.Refdoc = new RefdocService(this.config);
        this.Resources = new ResourcesService(this.config);

        if (!ApisService.instance) {
            ApisService.instance = this;
        }
    }

    getApis(queryParams: GetApisQueryParams, headers: any = {}): Observable<Array<APIXApi> | APIXErrorResponse> {
        let s = new Subject<Array<APIXApi> | APIXErrorResponse>();

        let internalSuccessHandler = (result: Array<APIXApi>) => {
            s.next(result.map(item => new APIXApi(item)));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };


        let result = this.config.transport.get('/apix/apis', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createApi(queryParams: CreateApiQueryParams, body: APIXApi, headers: any = {}): Observable<APIXApi | APIXErrorResponse> {
        let s = new Subject<APIXApi | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApi) => {
            s.next(new APIXApi(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };


        let result = this.config.transport.post('/apix/apis', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getApi(api_id: string, headers: any = {}): Observable<APIXApi | APIXErrorResponse> {
        let s = new Subject<APIXApi | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApi) => {
            s.next(new APIXApi(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_id: api_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/apis/{api_id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateApi(api_id: string, body: APIXApi, headers: any = {}): Observable<APIXApi | APIXErrorResponse> {
        let s = new Subject<APIXApi | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApi) => {
            s.next(new APIXApi(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_id: api_id,
        };

        let queryParams = {};

        let result = this.config.transport.put('/apix/apis/{api_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteApi(api_id: string, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_id: api_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/apix/apis/{api_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
