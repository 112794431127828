import {
    Component,
    ContentChildren,
    AfterContentInit,
    EventEmitter,
    Input,
    Output,
    QueryList,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import { VmwAccordionWizardStep, VmwAccordionWizardStepState } from "./accordion-wizard-step.component";
import { Subscription } from "rxjs";

@Component({
    selector: "vmw-accordion-wizard",
    templateUrl: "accordion-wizard.component.html",
    styleUrls: ["./accordion-wizard.component.scss"],
})
export class VmwAccordionWizard implements AfterContentInit, OnChanges {
    @ContentChildren(VmwAccordionWizardStep) steps: QueryList<VmwAccordionWizardStep>;

    @Input() finishButtonText: string = "Finish";
    @Input() showFinishButton: boolean = true;
    @Input() disableFinishButton: boolean = false;
    @Input() multipleExpand: boolean = true;

    @Output() onFinish = new EventEmitter();

    private enabledSteps: Array<VmwAccordionWizardStep> = [];
    private nextClickHandlers: Array<Subscription> = [];
    private prevClickHandlers: Array<Subscription> = [];
    private expandChangeHandlers: Array<Subscription> = [];
    private enabledChangeHandlers: Array<Subscription> = [];
    private lastStep: VmwAccordionWizardStep;
    private activeStep: VmwAccordionWizardStep;

    ngAfterContentInit() {
        this.steps.changes.subscribe((changes: any) => {
            this.initialize(false);
        });

        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.finishButtonText && changes.finishButtonText.currentValue && this.lastStep) {
            this.lastStep.internalNextButtonText = this.finishButtonText;
        }

        if (changes.showFinishButton && this.lastStep) {
            this.lastStep.showNextButton = this.showFinishButton;
        }

        if (changes.disableFinishButton && this.lastStep) {
            this.lastStep.disableNextButton = this.disableFinishButton;
        }

        if (changes.multipleExpand && !this.multipleExpand) {
            this.resetAllExpandedSteps();
        }
    }

    setStepCanExpand(step: VmwAccordionWizardStep, value: boolean) {
        step.ngOnChanges({
            canExpand: {
                currentValue: value
            }
        });
    }

    initialize(full: boolean = true) {
        this.lastStep = null;
        this.activeStep = this.steps.first;
        this.enabledSteps = [];
        this.nextClickHandlers.forEach(s => s.unsubscribe());
        this.prevClickHandlers = [];
        this.prevClickHandlers.forEach(s => s.unsubscribe());
        this.nextClickHandlers = [];
        this.expandChangeHandlers.forEach(s => s.unsubscribe());
        this.expandChangeHandlers = [];
        this.enabledChangeHandlers.forEach(s => s.unsubscribe());
        this.enabledChangeHandlers = [];

        this.steps.forEach((step: VmwAccordionWizardStep) => {
            this.enabledChangeHandlers.push(step.enabledChange.subscribe((currentStep: VmwAccordionWizardStep) => {
                this.initialize(false);
            }));

            if (!step.enabled) {
                return;
            }

            this.enabledSteps.push(step);
        });

        //put in microtask to avoid ExpressionChangedAfterItHasBeenCheckedError
        Promise.resolve(null).then(() => {
            this.enabledSteps.forEach((step: VmwAccordionWizardStep, index: number) => {
                step.index = index;

                if (full) {
                    this.setStepCanExpand(step, index === 0);
                    step.isExpanded = index === 0;
                } else {
                    this.setStepCanExpand(step, step.stepState === VmwAccordionWizardStepState.COMPLETE);
                }

                step.internalNextButtonText = step.nextButtonText;
                step.internalPreviousButtonText = step.previousButtonText;

                if (this.isStepLastEnabledStep(step)) {
                    this.lastStep = step;
                    step.internalNextButtonText = this.finishButtonText;
                    step.showNextButton = this.showFinishButton;
                    step.disableNextButton = this.disableFinishButton;
                } else if (this.isStepFirstEnabledStep(step)) {
                    step.showPreviousButton = false;
                }

                this.expandChangeHandlers.push(step.onExpandChange.subscribe((currentStep: VmwAccordionWizardStep) => {
                    if (currentStep.canExpand) {
                        if (!this.multipleExpand) {
                            if (this.activeStep) {
                                this.activeStep.isExpanded = false;
                            }
                            this.activeStep = currentStep;
                        }
                        currentStep.isExpanded = !currentStep.isExpanded;
                    }
                }));

                this.nextClickHandlers.push(step.nextStep.subscribe((currentStep: VmwAccordionWizardStep) => {
                    let nextStep = this.getNextStep(step);
                    this.activeStep = nextStep;

                    step.isExpanded = false;

                    if (this.isStepLastEnabledStep(step)) {
                        this.onFinish.emit();
                    } else if (nextStep !== null) {
                        nextStep.isExpanded = true;
                    }
                }));

                this.prevClickHandlers.push(step.previousStep.subscribe((currentStep: VmwAccordionWizardStep) => {
                    let prevStep = this.getPreviousStep(step);
                    this.activeStep = prevStep;

                    step.isExpanded = false;

                    if (prevStep !== null) {
                        prevStep.isExpanded = true;
                    }
                }));
            });
        })
    }

    isStepFirstEnabledStep(step: VmwAccordionWizardStep): boolean {
        return step.index === 0;
    }

    isStepLastEnabledStep(step: VmwAccordionWizardStep): boolean {
        return step.index === this.enabledSteps.length - 1;
    }

    getNextStep(step: VmwAccordionWizardStep): VmwAccordionWizardStep {
        return this.enabledSteps[step.index + 1] || null;
    }

    getPreviousStep(step: VmwAccordionWizardStep): VmwAccordionWizardStep {
        return this.enabledSteps[step.index - 1] || null;
    }

    private resetAllExpandedSteps(): void {
        if (this.steps) {
            this.steps.forEach((step: VmwAccordionWizardStep) => {
                if (step.isExpanded && step != this.activeStep) {
                    step.isExpanded = false;
                }
            });
        }
    }

}
