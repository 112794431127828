// tslint:disable

import { SampleExchangeAuthor } from './SampleExchangeAuthor';
import { SampleExchangeFlagType } from './SampleExchangeFlagType';

export class SampleExchangeFlag {
    public id: number;
    public author: SampleExchangeAuthor;
    public entityId: number;
    public entityType: string;
    public flagType: SampleExchangeFlagType;
    public details: string;
    public created: string;
    constructor(json: any) {
        Object.assign(this, json);
        this.author = new SampleExchangeAuthor(this.author);
        this.flagType = new SampleExchangeFlagType(this.flagType);
    }
}
