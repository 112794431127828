import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import * as YAML from 'yaml';

import { VmwSwaggerClassNameMode } from "./class-name-mode";

@Injectable()
export class VmwSwaggerUtil {

    constructor(private http: HttpClient) {}

    static additionalPropertyDefinitionName: string = "<*>";

    static additionalPropertyExampleName: string = "additionalProperty1";

    /*
     * getClassName --
     *
     * Remove dashes and capitalize first letter of each word.
     */
    static getClassName(name: string, classNameMode: VmwSwaggerClassNameMode) {
        if (classNameMode === VmwSwaggerClassNameMode.Java) {
            return name;
        }
        let bits = name.split('-');
        let className = '';

        for (let bit of bits) {
            className += bit.charAt(0).toUpperCase() + bit.slice(1);
        }

        // remove all remaining non-alphanumeric characters
        return className.replace(/\W/g, '');
    }

    static getDefinitionName(ref: string, classNameMode: VmwSwaggerClassNameMode) {
        let regexp = new RegExp(' ', 'g');
        return VmwSwaggerUtil.getClassName(
                ref.substring(ref.lastIndexOf('/') + 1).replace(regexp, ''),
                classNameMode);
    }

    static getRemoteReferenceSource(ref: string) {
        return ref.substring(0, ref.indexOf('#'));
    }

    /*
     * Fetch a swagger spec from a URL, handling if it's YAML or JSON
     */
    public getSwagger(url: string): Promise<any> {
        const yaml = url.endsWith('yaml') ||
            url.endsWith('yml');
        const responseType: string = yaml ? 'text' : 'json';

        return this.http.get(url, {responseType: responseType} as any).pipe(
            map((response: any) => {
                if (yaml) {
                    return YAML.parse(response);
                } else {
                    return response;
                }
            })
        ).toPromise();
    }

    public async getParameters(parameters: any, baseUrl: string): Promise<Map<string, any>> {
        let parametersMap = new Map<string, any>();

        for (let name of Object.keys(parameters)) {
            let parameter = parameters[name];

            if (parameter.$ref && !parameter.type) {
                const remoteRef = VmwSwaggerUtil.getRemoteReferenceSource(parameter.$ref);

                if (remoteRef && !parametersMap.get(name)) {
                    const remoteSwaggerUrl = baseUrl + remoteRef;
                    const remoteSwagger = await this.getSwagger(remoteSwaggerUrl);

                    if (!remoteSwagger[name]) {
                        console.error(`$ref ${remoteSwaggerUrl} does not include parameter with name ${name}`);
                        continue;
                    }

                    parameter = remoteSwagger[name];

                    // fill all the parameters from that remote reference to avoid fetching again
                    Object.assign(parameters, remoteSwagger);
                }
            }

            parametersMap.set(name, parameter);
        }

        return Promise.resolve(parametersMap);
    }

    /*
     * Extract the defintions from a swagger spec, resolving any remote references
     */
    public async getDefinitions(swagger: any,
                                classNameMode: VmwSwaggerClassNameMode,
                                flatten: boolean = true,
                                prefix: string = '',
                                baseUrl: string = ''): Promise<Map<string, any>> {
        let definitionsMap = new Map<string, any>();
        let definitionChildren = new Map<string, Array<string>>();

        if (!swagger.definitions && !swagger.components?.schemas) {
            return Promise.resolve(definitionsMap);
        }

        // take a copy to avoid mutating the existing object
        let definitions = Object.assign({}, swagger.definitions || swagger.components?.schemas);

        const definitionsToIterate = Object.keys(definitions);

        for (let name of definitionsToIterate) {
            let definition = definitions[name];

            // If definition.type is set, it has been resolved already
            if (definition.$ref && !definition.type) {
                let refName = VmwSwaggerUtil.getDefinitionName(
                      definition.$ref, classNameMode);

                const remoteRef = VmwSwaggerUtil.getRemoteReferenceSource(definition.$ref);

                if (remoteRef && !definitionsMap.get(refName)) {
                    const remoteSwaggerUrl = baseUrl + remoteRef;
                    const remoteDefinitions = await this.getSwagger(remoteSwaggerUrl);

                    if (!remoteDefinitions[refName]) {
                        console.error(`$ref ${remoteSwaggerUrl} does not include definition with name ${refName}`);
                        continue;
                    }

                    definition = remoteDefinitions[refName];

                    // fill all the definitions from that remote reference to avoid fetching again
                    Object.assign(definitions, remoteDefinitions);

                    for (let newDefinition of Object.keys(remoteDefinitions)) {
                        if (!definitionsToIterate.includes(newDefinition)) {
                            definitionsToIterate.push(newDefinition);
                        }
                    }

                } else {
                    definition = swagger.definitions[refName];
                }
            }

            name = prefix + VmwSwaggerUtil.getClassName(name, classNameMode);

            let props = [];
            let base;
            let properties = [];
            let enumValues = [];

            if (definition.allOf) {
                for (let n of definition.allOf) {
                    if (n.$ref) {
                        base = VmwSwaggerUtil.getDefinitionName(n.$ref, classNameMode);
                        if (definitionChildren.get(base)) {
                            definitionChildren.get(base).push(name);
                        } else {
                            definitionChildren.set(base, [name]);
                        }

                        if (flatten) {
                            properties.push(n.$ref);
                        }
                    } else if (n.properties) {
                        props = n.properties;
                    }
                }

            } else if (definition.properties) {
                props = definition.properties;

            } else if (definition.enum) {
                enumValues = definition.enum;
            }

            for (let propertyName in props) {
                if (!props[propertyName]) {
                    continue;
                }

                let property = props[propertyName];

                let vmwProperty: any = VmwSwaggerUtil.getVmwProperty(
                      definition, property, propertyName, classNameMode);

                properties.push(vmwProperty);
            }

            let additionalProperty: any = null;

            if (definition.additionalProperties) {
                additionalProperty = VmwSwaggerUtil.getVmwProperty(definition,
                      definition.additionalProperties,
                      VmwSwaggerUtil.additionalPropertyDefinitionName, classNameMode);
            }

            definitionsMap.set(name, {
                name: name,
                description: definition.description,
                properties: properties,
                base: base,
                enum: enumValues,
                additionalProperty: additionalProperty
            });
        }

        if (flatten) {
            VmwSwaggerUtil.handleDefinitionAllOf(definitionsMap, classNameMode);
        }

        for (let definition of Array.from(definitionsMap.values())) {
            if (definitionChildren.get(definition.name)) {
                definition.children = definitionChildren.get(definition.name);
            } else {
                definition.children = [];
            }
        }

        return Promise.resolve(definitionsMap);
    }

    static getVmwProperty(definition: any, property: any, propertyName: string,
                          classNameMode: VmwSwaggerClassNameMode) {

        let propertyDefinition = null;
        let propertyType = null;

        if (property.$ref) {
            propertyDefinition = this.getDefinitionName(property.$ref, classNameMode);
            propertyType = 'Object';
        } else if (property.allOf) {
            if (property.allOf.length === 1 && property.allOf[0].$ref) {
                propertyDefinition = this.getDefinitionName(
                      property.allOf[0].$ref, classNameMode);
                propertyType = 'Object';
            } else {
                console.error('not handling nested object in ' +
                      definition.name + ': ' + propertyName);
            }

        } else if (property.items && property.items.$ref) {
            propertyDefinition = this.getDefinitionName(property.items.$ref, classNameMode);
            propertyType = 'Array<' + propertyDefinition + '>';
        } else if (property.items && property.items.type) {
            propertyType = 'Array<' + property.items.type + '>';
        } else if (property.type === 'object') {
            propertyType = 'Object';
        } else {
            propertyType = property.type;
        }

        return {
            name: propertyName,
            type: propertyType,
            description: property.description,
            definition: propertyDefinition,
            required: definition.required ?
                  definition.required.indexOf(propertyName) !== -1 : false,
            readOnly: property.readOnly ? property.readOnly : false
        };
    }

    static handleDefinitionAllOf(definitions: Map<string, any>, classNameMode: VmwSwaggerClassNameMode) {
        let missedOutDefinitions: Array<string> = [];

        let resolveDefinitions = function (inputArr : Array<string>) {
            missedOutDefinitions = [];
            for (let defName of inputArr) {
                let definition = definitions.get(defName);
                let properties: Array<any> = [];

                for (let property of definition.properties) {
                    if (typeof property === "string") {
                        const refName = VmwSwaggerUtil.getDefinitionName(property, classNameMode);
                        let referencedDefinition = definitions.get(refName);

                        if (referencedDefinition) {
                            if (referencedDefinition.properties.find((e: any) => typeof e === "string")) {
                                missedOutDefinitions.push(defName); // saving the names of properties that has unresolved defs
                            }

                            properties = properties.concat(referencedDefinition.properties);

                        } else {
                            console.log(`referenced definition ${refName} not found`);
                        }

                    } else {
                        properties.push(property);
                    }
                }

                properties.sort((a, b) => {
                    if (a.name < b.name) {
                          return -1;
                    } else if (a.name > b.name) {
                          return 1;
                    }
                    return 0;
                });

                definition.properties = properties;
            }
        };

        // running on the complete set initially
        resolveDefinitions(Array.from(definitions.keys()));
        // running on the reduced set that still has unresolved references, till all are resolved
        while (missedOutDefinitions.length > 0) {
            let oldLengthOfMissedOutDefinitions = missedOutDefinitions.length;
            resolveDefinitions(missedOutDefinitions);
            if (missedOutDefinitions.length >= oldLengthOfMissedOutDefinitions) {
                break; // assures forward progress, as the missedOutDefs should be reducing with each pass
            }
        }
    }

    static getResponsesForPathMethod(pathMethod: any, classNameMode: VmwSwaggerClassNameMode, typePrefix: string = '') {
        let responses: any[] = [];

        if (!pathMethod.responses) {
            return responses;
        }

        for (let status of Object.keys(pathMethod.responses)) {
            let response = pathMethod.responses[status] || pathMethod.responses[parseInt(status)];
            let definition;
            let responseType;

            let schema = response.schema;

            // openapi v3 ...
            if (response.content && response.content["application/json"]) {
                schema = response.content["application/json"].schema;
            }

            if (schema?.$ref) {
                definition = typePrefix + VmwSwaggerUtil.getDefinitionName(
                        schema.$ref, classNameMode);
                if (schema.type === 'array') {
                    responseType = 'Array<' + definition + '>';
                } else {
                    responseType = schema.type || definition || "object";
                }
            } else if (schema?.items?.$ref) {
                definition = typePrefix + VmwSwaggerUtil.getDefinitionName(
                        schema.items.$ref, classNameMode);
                if (schema.type === 'array') {
                    responseType = 'Array<' + definition + '>';
                } else {
                    responseType = schema.type || "object";
                }
            } else {
                responseType = "object";
            }

            responses.push({
                status: status,
                description: response.description,
                definition: definition,
                type: responseType,
            });
        }

        return responses;
    }

    static getDefinitionJSON(definition: string, definitions: Map<string, any>, depth: number = 0) {
        let body = "{\n";

        let spaces = (i: number) => {
            return "    ".repeat(i < 0 ? 0 : i + 1);
        };

        let def = definitions.get(definition);

        let defProperties = def.properties.filter((p: any) => { // filter out all properties that are readOnly
            return !p.readOnly;
        });

        for (let i in defProperties) {
            if (!defProperties[i]) {
                continue;
            }

            let property = defProperties[i];

            let value: string | number = VmwSwaggerUtil.getPropertyJsonValue(
                  definitions, depth, property);

            body += spaces(depth) + "\"" + property.name + "\": " + value;
            if (parseInt(i, 10) < defProperties.length - 1 || def.additionalProperty) {
                body += ",";
            }

            body += "\n";
        }

        if (def.additionalProperty) {
            let value: string | number = VmwSwaggerUtil.getPropertyJsonValue(
                  definitions, depth, def.additionalProperty);
            body += spaces(depth) +
                  "\"" + VmwSwaggerUtil.additionalPropertyExampleName + "\": " +
                  value;
            body += "\n";
        }

        body += spaces(depth - 1) + "}";

        return body;
    }

    static getPropertyJsonValue(
          definitions: any, depth: number, property: any): string  | number {
        let value: string | number;

        if (property.definition) {
            let propertyDefinition: any = definitions.get(property.definition);
            if (propertyDefinition.enum && propertyDefinition.enum.length > 0) {
                value = "\"" + propertyDefinition.enum[0] + "\"";
            } else {
                value = VmwSwaggerUtil.getDefinitionJSON(
                      property.definition, definitions, depth + 1);
            }
        } else if (property.type === "integer" || property.type === "Array<integer>") {
            value = 0;
        } else if (property.type === "string" || property.type === "Array<string>") {
            value = "\"\"";
        } else if (property.type === "boolean" || property.type === "Array<boolean>") {
            value = "false";
        } else {
            value = "{ }";
        }

        if (property.type.indexOf("Array") !== -1) {
            value = "[ " + value + " ]";
        }
        return value;
    }
}
