import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nl2br',
})
export class DevCenterNewlineToBrPipe implements PipeTransform {
    transform(value: string): string {
        return value.replace(new RegExp('\n', 'g'), '<br>');
    }
}
