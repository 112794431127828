// tslint:disable


export class SampleExchangeAuthor {
    public id: number;
    public federationId: string;
    public communitiesUser: string;
    public fullName: string;
    public gitHubLogin: string;
    public gitHubToken: string;
    public linked: boolean;
    public score: number;
    public preferences: Object;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
