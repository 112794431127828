import { Injectable } from "@angular/core";
import { SafeResourceUrl } from '@angular/platform-browser';

import { Subject } from 'rxjs';

import { VmwDeveloperCenterAPIExplorerAPIComponent } from "./api-explorer-page.component";

@Injectable()
export class VmwDeveloperCenterAPIExplorerPageService {
    public categoryLoaded$ = new Subject<Array<VmwDeveloperCenterAPIExplorerAPIComponent>>();
}
