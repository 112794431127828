// Generated at 2018-08-03T19:56:20.758Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeEvent } from '../../../../types/SampleExchangeEvent';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class GetEventsByFederationIdQueryParams {
    days?: string;
}

export class FederationIdService {
    public static instance: FederationIdService;


    constructor(public config: TangoServiceConfiguration) {

        if (!FederationIdService.instance) {
            FederationIdService.instance = this;
        }
    }

    getEventsByFederationId(federationId: string, queryParams: GetEventsByFederationIdQueryParams, headers: any = {}): Observable<Array<SampleExchangeEvent> | any> {
        let s = new Subject<Array<SampleExchangeEvent> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeEvent>) => {
            s.next(result.map(item => new SampleExchangeEvent(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            federationId: federationId,
        };


        let result = this.config.transport.get('/search/events/user/federationId/{federationId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
