// Generated at 2018-08-03T19:56:20.761Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { NameService } from './Name';
import { SampleExchangeRequest } from '../../../../types/SampleExchangeRequest';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class TypeService {
    public static instance: TypeService;

    public Name: NameService;

    constructor(public config: TangoServiceConfiguration) {
        this.Name = new NameService(this.config);

        if (!TypeService.instance) {
            TypeService.instance = this;
        }
    }

    getRequestsByCategory(type: string, headers: any = {}): Observable<Array<SampleExchangeRequest> | any> {
        let s = new Subject<Array<SampleExchangeRequest> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeRequest>) => {
            s.next(result.map(item => new SampleExchangeRequest(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            type: type,
        };

        let queryParams = {};

        let result = this.config.transport.get('/search/requests/category/type/{type}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
