import { Input, Component } from "@angular/core";

import { VmwClarityThemeService, VmwClarityTheme } from "./theme.provider";
import { VmwSimpleTranslateService } from "../simple-translate-service/simple-translate.service";
import { TRANSLATIONS } from './theme-switch-button.l10n';

@Component({
    selector: "vmw-theme-switch-button",
    templateUrl: "theme-switch-button.component.html",
    styleUrls: ["./theme-switch-button.component.scss"],
})
export class VmwThemeSwitchButtonComponent {
    public VmwClarityTheme = VmwClarityTheme;

    @Input('classes') classes: string = '';

    constructor(public themeService: VmwClarityThemeService,
                public translateService: VmwSimpleTranslateService) {
        this.translateService.loadTranslationsForComponent('theme-switch-button', TRANSLATIONS);
    }

    themeChanged(newTheme: VmwClarityTheme) {
        this.themeService.theme = newTheme;
    }
}
