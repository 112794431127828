import { Component, Input } from "@angular/core";

@Component({
    selector: "vmw-clock-spinner",
    templateUrl: "./clock-spinner.component.html",
    styleUrls: ["./clock-spinner.component.scss"],
})
export class VmwClockSpinnerComponent {
    @Input() animate: boolean;
}
