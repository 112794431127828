// Generated at 2018-08-03T19:56:17.046Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { ApisService } from './Apis';
import { APIXResource } from '../../types/APIXResource';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SdksService {
    public static instance: SdksService;

    public Apis: ApisService;

    constructor(public config: TangoServiceConfiguration) {
        this.Apis = new ApisService(this.config);

        if (!SdksService.instance) {
            SdksService.instance = this;
        }
    }

    getAllSdks(headers: any = {}): Observable<Array<APIXResource> | any> {
        let s = new Subject<Array<APIXResource> | any>();

        let internalSuccessHandler = (result: Array<APIXResource>) => {
            s.next(result.map(item => new APIXResource(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/sdks', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
