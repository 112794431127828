// tslint:disable

import { APIXState } from './APIXState';
import { APIXRelease } from './APIXRelease';
import { APIXTag } from './APIXTag';

export class APIXApi {
    public id: number;
    public complete: boolean;
    public api_def_id: number;
    public api_uid: string;
    public name: string;
    public description: string;
    public technology: string;
    public version: string;
    public user_groups: Array<string>;
    public visibility: string;
    public compatibility_level: string;
    public state: APIXState;
    public api_ref_doc_type: string;
    public api_ref_doc_artifact_id: number;
    public api_ref_doc_url: string;
    public releases: Array<APIXRelease>;
    public tags: Array<APIXTag>;
    public created_date: string;
    public lastModified: string;
    public updatedBy: string;
    constructor(json: any) {
        Object.assign(this, json);
        this.state = new APIXState(this.state);
        if (this.releases) {
            this.releases = this.releases.map(item => new APIXRelease(item));
        }
        if (this.tags) {
            this.tags = this.tags.map(item => new APIXTag(item));
        }
    }
}
