// Generated at 2018-08-03T19:56:16.967Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { UidsService } from './Uids';
import { APIXApiDef } from '../../../types/APIXApiDef';
import { APIXErrorResponse } from '../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class GetApiDefsQueryParams {
    api_uid_substring?: string;
    name_substring?: string;
}

export class DefsService {
    public static instance: DefsService;

    public Uids: UidsService;

    constructor(public config: TangoServiceConfiguration) {
        this.Uids = new UidsService(this.config);

        if (!DefsService.instance) {
            DefsService.instance = this;
        }
    }

    getApiDefs(queryParams: GetApiDefsQueryParams, headers: any = {}): Observable<Array<APIXApiDef> | APIXErrorResponse> {
        let s = new Subject<Array<APIXApiDef> | APIXErrorResponse>();

        let internalSuccessHandler = (result: Array<APIXApiDef>) => {
            s.next(result.map(item => new APIXApiDef(item)));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };


        let result = this.config.transport.get('/apix/apis/defs', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createApiDef(body: APIXApiDef, headers: any = {}): Observable<APIXApiDef | APIXErrorResponse> {
        let s = new Subject<APIXApiDef | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApiDef) => {
            s.next(new APIXApiDef(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/apix/apis/defs', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getApiDef(api_def_id: string, headers: any = {}): Observable<APIXApiDef | APIXErrorResponse> {
        let s = new Subject<APIXApiDef | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApiDef) => {
            s.next(new APIXApiDef(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_def_id: api_def_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/apis/defs/{api_def_id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateApiDef(api_def_id: string, body: APIXApiDef, headers: any = {}): Observable<APIXApiDef | APIXErrorResponse> {
        let s = new Subject<APIXApiDef | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApiDef) => {
            s.next(new APIXApiDef(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_def_id: api_def_id,
        };

        let queryParams = {};

        let result = this.config.transport.put('/apix/apis/defs/{api_def_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteApiDef(api_def_id: string, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_def_id: api_def_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/apix/apis/defs/{api_def_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
