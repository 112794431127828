import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    selector: "vmw-bulk-email-input-placeholder",
    template: `
        <ng-content></ng-content>
    `,
    styles: [
        `
            :host {
                color: #8c8c8c;
                opacity: 0.54;
                display: block;
                position: absolute;
            }

            :host-context(.dark) {
                color: #acbac3;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VmwBulkEmailInputPlaceholderComponent {}
