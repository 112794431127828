// Generated at 2018-08-03T19:56:20.686Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AuthorsService } from './Authors';
import { CommentsService } from './Comments';
import { ContributionsService } from './Contributions';
import { DownloadsService } from './Downloads';
import { FavoritesService } from './Favorites';
import { FeedsService } from './Feeds';
import { FlagsService } from './Flags';
import { NotificationsService } from './Notifications';
import { RequestsService } from './Requests';
import { SampleFileHandlingService } from './SampleFileHandling';
import { SamplesService } from './Samples';
import { SearchService } from './Search';
import { SolutionsService } from './Solutions';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SampleExchangeService {
    public static instance: SampleExchangeService;

    public Authors: AuthorsService;
    public Comments: CommentsService;
    public Contributions: ContributionsService;
    public Downloads: DownloadsService;
    public Favorites: FavoritesService;
    public Feeds: FeedsService;
    public Flags: FlagsService;
    public Notifications: NotificationsService;
    public Requests: RequestsService;
    public SampleFileHandling: SampleFileHandlingService;
    public Samples: SamplesService;
    public Search: SearchService;
    public Solutions: SolutionsService;

    constructor(public config: TangoServiceConfiguration) {
        this.Authors = new AuthorsService(this.config);
        this.Comments = new CommentsService(this.config);
        this.Contributions = new ContributionsService(this.config);
        this.Downloads = new DownloadsService(this.config);
        this.Favorites = new FavoritesService(this.config);
        this.Feeds = new FeedsService(this.config);
        this.Flags = new FlagsService(this.config);
        this.Notifications = new NotificationsService(this.config);
        this.Requests = new RequestsService(this.config);
        this.SampleFileHandling = new SampleFileHandlingService(this.config);
        this.Samples = new SamplesService(this.config);
        this.Search = new SearchService(this.config);
        this.Solutions = new SolutionsService(this.config);

        if (!SampleExchangeService.instance) {
            SampleExchangeService.instance = this;
        }
    }

}
