// Generated at 2018-08-03T19:56:17.039Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { MethodsService } from './Methods';
import { APIXApi } from '../../../types/APIXApi';
import { APIXErrorResponse } from '../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class UsedApisService {
    public static instance: UsedApisService;

    public Methods: MethodsService;

    constructor(public config: TangoServiceConfiguration) {
        this.Methods = new MethodsService(this.config);

        if (!UsedApisService.instance) {
            UsedApisService.instance = this;
        }
    }

    getReleaseUsedApis(release_id: string, headers: any = {}): Observable<Array<APIXApi> | APIXErrorResponse> {
        let s = new Subject<Array<APIXApi> | APIXErrorResponse>();

        let internalSuccessHandler = (result: Array<APIXApi>) => {
            s.next(result.map(item => new APIXApi(item)));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_id: release_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/releases/{release_id}/used-apis', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getReleaseUsedApi(release_id: string, api_id: string, headers: any = {}): Observable<APIXApi | APIXErrorResponse> {
        let s = new Subject<APIXApi | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApi) => {
            s.next(new APIXApi(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_id: release_id,
            api_id: api_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/releases/{release_id}/used-apis/{api_id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createReleaseUsedApi(release_id: string, api_id: string, headers: any = {}): Observable<APIXApi | APIXErrorResponse> {
        let s = new Subject<APIXApi | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApi) => {
            s.next(new APIXApi(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_id: release_id,
            api_id: api_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.post('/apix/releases/{release_id}/used-apis/{api_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateReleaseUsedApi(release_id: string, api_id: string, body: APIXApi, headers: any = {}): Observable<APIXApi | APIXErrorResponse> {
        let s = new Subject<APIXApi | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApi) => {
            s.next(new APIXApi(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_id: release_id,
            api_id: api_id,
        };

        let queryParams = {};

        let result = this.config.transport.put('/apix/releases/{release_id}/used-apis/{api_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteReleaseUsedApi(release_id: string, api_id: string, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_id: release_id,
            api_id: api_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/apix/releases/{release_id}/used-apis/{api_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
