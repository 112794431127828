import { EventEmitter } from "@angular/core";
import { of, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { VmwSegmentVersionInfo } from './segment-version-info.model';

export class VmwSegmentServiceMock {

    private versionInfo: VmwSegmentVersionInfo;
    private context: string = '';
    private userId: string;
    private key: string;


    setKey(key: string) {
        this.key = key;
    }

    setVersionInfo(versionInfo: VmwSegmentVersionInfo) {
        this.versionInfo = versionInfo;
    }

    setContext(searchContext: string) {
        this.context = searchContext;
    }

    setUserId(userId: string) {
        this.userId = userId;
    }

    trackEvent(eventName: string, props?: any) {
        if (!this.key || !this.userId) {
            return false;
        }
        return  of({success:true});
    }
}
