// Generated at 2018-08-03T19:56:20.749Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { FileExtensionService } from './FileExtension';
import { SampleExchangeSampleFileHandling } from '../types/SampleExchangeSampleFileHandling';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SampleFileHandlingService {
    public static instance: SampleFileHandlingService;

    public FileExtension: FileExtensionService;

    constructor(public config: TangoServiceConfiguration) {
        this.FileExtension = new FileExtensionService(this.config);

        if (!SampleFileHandlingService.instance) {
            SampleFileHandlingService.instance = this;
        }
    }

    getAllSampleFileHandlings(headers: any = {}): Observable<Array<SampleExchangeSampleFileHandling> | any> {
        let s = new Subject<Array<SampleExchangeSampleFileHandling> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeSampleFileHandling>) => {
            s.next(result.map(item => new SampleExchangeSampleFileHandling(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/sampleFileHandling', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createSampleFileHandling(body: SampleExchangeSampleFileHandling, headers: any = {}): Observable<SampleExchangeSampleFileHandling | any> {
        let s = new Subject<SampleExchangeSampleFileHandling | any>();

        let internalSuccessHandler = (result: SampleExchangeSampleFileHandling) => {
            s.next(new SampleExchangeSampleFileHandling(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/sampleFileHandling', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeSampleFileHandling(id: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/sampleFileHandling/{id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
