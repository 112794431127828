import { EventEmitter, Component, Input } from "@angular/core";

import { APIXResource } from "../../api/APIX/types";

import { VmwAPIXService } from "../../providers/apix.service";

@Component({
    templateUrl: "./sdks-page.component.html",
    selector: "vmw-developer-center-sdks-page",
    styleUrls: ["./sdks-page.component.scss"],
})
export class VmwDeveloperCenterSDKsPageComponent {
    @Input() productId: number;
    @Input() releaseId: number;
    @Input() showGettingStarted = false;

    public sdks: Array<APIXResource> = null;
    public error: string = null;

    public empty = new EventEmitter<boolean>();

    constructor(private apixService: VmwAPIXService) {}

    ngOnChanges (changes: any) {
        if (changes.releaseId && changes.releaseId.currentValue) {
            this.apixService.getSDKsForRelease(this.releaseId)
                .subscribe((sdks: Array<APIXResource>) => {
                    this.sdks = sdks;
                    this.empty.emit(this.sdks.length === 0);
                    this.error = null;
                }, (error: any) => {
                    this.sdks = [];
                    this.error = error;
                });

        } else if (changes.productId && changes.productId.currentValue) {
            this.apixService.getSDKsForProduct(this.productId)
                .subscribe((sdks: Array<APIXResource>) => {
                    this.sdks = sdks;
                    this.empty.emit(this.sdks.length === 0);
                    this.error = null;
                }, (error: any) => {
                    this.sdks = [];
                    this.error = error;
                });
        }
    }
}
