/*
 * @copyright Copyright VMware, Inc. All rights reserved. VMware Confidential.
 * @license For licensing, see LICENSE.md.
 */

import { AbstractStorageSubject } from './abstract-storage.subject';

/**
 * This subject behaves much like a BehaviorSubject, however the value is
 * backed by the browser's session storage. It also integrates with the browser's
 * storage events to ensure it remains synchronized across browser windows.
 */
export class SessionStorageSubject<T> extends AbstractStorageSubject<T> {

  /**
   * Create a new behavior subject instance.
   *
   * @param _key The storage key.
   */
  constructor(_key: string) {
    super(_key, window.sessionStorage);
  }
}
