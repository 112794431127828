// Generated at 2018-08-03T19:56:20.752Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AsyncService } from './Async';
import { ResyncService } from './Resync';
import { CategoryService } from './Category';
import { MetadataService } from './Metadata';
import { TagService } from './Tag';
import { SampleExchangeContribution } from '../types/SampleExchangeContribution';
import { SampleExchangeSample } from '../types/SampleExchangeSample';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SamplesService {
    public static instance: SamplesService;

    public Async: AsyncService;
    public Resync: ResyncService;
    public Category: CategoryService;
    public Metadata: MetadataService;
    public Tag: TagService;

    constructor(public config: TangoServiceConfiguration) {
        this.Async = new AsyncService(this.config);
        this.Resync = new ResyncService(this.config);
        this.Category = new CategoryService(this.config);
        this.Metadata = new MetadataService(this.config);
        this.Tag = new TagService(this.config);

        if (!SamplesService.instance) {
            SamplesService.instance = this;
        }
    }

    updateSample(body: SampleExchangeContribution, headers: any = {}): Observable<SampleExchangeSample | object> {
        let s = new Subject<SampleExchangeSample | object>();

        let internalSuccessHandler = (result: SampleExchangeSample) => {
            s.next(new SampleExchangeSample(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.put('/samples', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeSample(id: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/samples/{id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
