import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

class TrackerConfig {
    showProgress: boolean;
}

@Injectable()
export class VmwProgressTrackerConfigService {
    private subject = new BehaviorSubject<TrackerConfig>(new TrackerConfig());

    setConfig(config: TrackerConfig) {
        this.subject.next(config);
    }

    getConfig() {
        return this.subject.asObservable();
    }
}