// Generated at 2018-08-03T19:56:16.918Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class AccessGroupsService {
    public static instance: AccessGroupsService;


    constructor(public config: TangoServiceConfiguration) {

        if (!AccessGroupsService.instance) {
            AccessGroupsService.instance = this;
        }
    }

    getAccessGroups(headers: any = {}): Observable<object | any> {
        let s = new Subject<object | any>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/apis/access-groups', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
