// Generated at 2018-08-03T19:56:17.053Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXSearchResults } from '../../types/APIXSearchResults';
import { APIXErrorResponse } from '../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class SearchQueryParams {
    limit?: string;
    cursorMark?: string;
    include?: string;
    platform?: string;
    tag?: string;
    keyword?: string;
    modified?: string;
    days?: string;
}

export class SearchService {
    public static instance: SearchService;


    constructor(public config: TangoServiceConfiguration) {

        if (!SearchService.instance) {
            SearchService.instance = this;
        }
    }

    search(queryParams: SearchQueryParams, headers: any = {}): Observable<APIXSearchResults | APIXErrorResponse> {
        let s = new Subject<APIXSearchResults | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXSearchResults) => {
            s.next(new APIXSearchResults(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };


        let result = this.config.transport.get('/apix/search', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
