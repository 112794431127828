// tslint:disable


export class APIXRelease {
    public rmProductId: number;
    public id: number;
    public complete: boolean;
    public rm_id: number;
    public name: string;
    public version: string;
    public build_url: string;
    public product_id: number;
    public release_date: string;
    public support_end_date: string;
    public lastModified: string;
    public updatedBy: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
