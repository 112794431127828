// Generated at 2018-08-03T19:56:17.010Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXRelease } from '../../../types/APIXRelease';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ReleasesService {
    public static instance: ReleasesService;


    constructor(public config: TangoServiceConfiguration) {

        if (!ReleasesService.instance) {
            ReleasesService.instance = this;
        }
    }

    getReleasesByProductId(product_id: string, headers: any = {}): Observable<Array<APIXRelease> | APIXRelease> {
        let s = new Subject<Array<APIXRelease> | APIXRelease>();

        let internalSuccessHandler = (result: Array<APIXRelease>) => {
            s.next(result.map(item => new APIXRelease(item)));
            s.complete();
        };

        let failureHandler = (error: APIXRelease) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            product_id: product_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/products/{product_id}/releases', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
