// tslint:disable

import { SampleExchangeSample } from './SampleExchangeSample';

export class SampleExchangeSampleJob {
    public id: number;
    public communitiesUser: string;
    public state: string;
    public created: string;
    public start: string;
    public finished: string;
    public progress: number;
    public error: string;
    public stackTrace: string;
    public sampleId: number;
    public sample: SampleExchangeSample;
    constructor(json: any) {
        Object.assign(this, json);
        this.sample = new SampleExchangeSample(this.sample);
    }
}
