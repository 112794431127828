// Generated at 2018-08-03T19:56:20.747Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { CategoryService } from './Category';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class TagService {
    public static instance: TagService;

    public Category: CategoryService;

    constructor(public config: TangoServiceConfiguration) {
        this.Category = new CategoryService(this.config);

        if (!TagService.instance) {
            TagService.instance = this;
        }
    }

}
