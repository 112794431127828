import { AfterContentInit, ElementRef, ContentChild, Component, Input } from "@angular/core";

@Component({
    templateUrl: "./time-picker.component.html",
    selector: "vmw-time-picker",
    styleUrls: ["./time-picker.component.scss"],
})
export class VmwTimePickerComponent implements AfterContentInit {
    @Input() unavailable: Array<string> = []; // unavailable hours in 24hr notation
    @Input() disabled = false;
    @Input() position = "bottom-left";
    @Input() timezone: string = "UTC";
    @Input() ariaLabel: string = "";

    @ContentChild("inputElement", { static: false }) child: ElementRef;

    inputElement: HTMLInputElement;
    hours: Array<string> = [];

    constructor() {
        for (let i = 0; i <= 23; i++) {
            this.hours.push(i + "");
        }
    }

    ngAfterContentInit() {
        this.inputElement = this.child.nativeElement;
    }

    isHourDisabled(hour: string) {
        return this.unavailable && this.unavailable.indexOf(hour) !== -1;
    }

    isHourActive(hour: string) {
        return this.inputElement.value === hour;
    }

    displayValue(hour: string|number) {
        let val = "Undefined";

        hour = parseInt(hour as string, 10);

        if (hour >= 0) {
            if (hour === 0) {
                val = "12:00";
            } else if (hour > 12) {
                val = (hour - 12) + ":00";
            } else {
                val = hour + ":00";
            }

            if (hour < 12) {
                val += " AM";
            } else {
                val += " PM";
            }
        }

        return val;
    }

    setModel(value: string) {
        if (this.isHourDisabled(value)) {
            return;
        }
        const customEvent = document.createEvent("Event");
        customEvent.initEvent("input", true, true);
        this.inputElement.value = value;
        this.inputElement.dispatchEvent(customEvent);
    }
}
