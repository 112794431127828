// Generated at 2018-08-03T19:56:20.766Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { CommunitiesUserService } from './CommunitiesUser';
import { FederationIdService } from './FederationId';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class UserService {
    public static instance: UserService;

    public CommunitiesUser: CommunitiesUserService;
    public FederationId: FederationIdService;

    constructor(public config: TangoServiceConfiguration) {
        this.CommunitiesUser = new CommunitiesUserService(this.config);
        this.FederationId = new FederationIdService(this.config);

        if (!UserService.instance) {
            UserService.instance = this;
        }
    }

}
