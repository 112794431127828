// tslint:disable


export class SampleExchangeNotificationInstance {
    public id: number;
    public solrQuery: string;
    public email: string;
    public url: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
