// tslint:disable


export class APIXTag {
    public id: number;
    public category: string;
    public name: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
