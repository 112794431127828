export const TRANSLATIONS = {
    'en': {
        'light': 'Light',
        'dark': 'Dark',
        'theme-switcher': 'Theme Switcher'
    },
    'es': {
        'light': 'Claro',
        'dark': 'Oscuro',
        'theme-switcher':'Selector de temas'
    },
    'de': {
        'light': 'Hell',
        'dark': 'Dunkel',
        'theme-switcher':'Design-Umschalter'
    },
    'fr': {
        'light': 'Clair',
        'dark': 'FoncÃ©',
        'theme-switcher':'SÃ©lecteur de thÃ¨me'
    },
    'ja': {
        'light': 'ã©ã¤ã',
        'dark': 'ãã¼ã¯',
        'theme-switcher':'ãã¼ãã®åãæ¿ã'
    },
    'ko': {
        'light': 'ë°ì',
        'dark': 'ì´ëì',
        'theme-switcher':'íë§ ì íê¸°'
    },
    'zh_TW': {
        'light': 'æ·±è²',
        'dark': 'æ·ºè²',
        'theme-switcher':'ä¸»é¡åæå¨'
    },
    'zh_CN': {
        'light': 'æ',
        'dark': 'æ',
        'theme-switcher':'ä¸»é¢åæ¢å¨'
    },
    'it': {
        'light': 'Chiaro',
        'dark': 'Scuro',
        'theme-switcher':'Selettore tema'
    },
    'nl': {
        'light': 'Licht',
        'dark': 'Donker',
        'theme-switcher':'Themaschakelaar'
    },
    'pt': {
        'light': 'Luz',
        'dark': 'Escuro',
        'theme-switcher':'Gerenciador de Temasã'
    },
    'ru': {
        'light': 'Ð¡Ð²ÐµÑÐ»Ð°Ñ',
        'dark': 'Ð¢ÐµÐ¼Ð½Ð°Ñ',
        'theme-switcher':'Ð¡Ð¼ÐµÐ½Ð° ÑÐµÐ¼Ñ'
    },
};