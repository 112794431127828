export const TRANSLATIONS = {
    'en': {
        'dismiss-notification': 'Dismiss notification',
        'success-icon': 'Success Icon',
        'info-icon': 'Information Icon',
        'failure-icon': 'Failure Icon',
    },
    'es': {
        'dismiss-notification': 'Descartar notificaciÃ³n',
        'success-icon': 'Icono de Ã©xito',
        'failure-icon': 'Icono de error',
        'info-icon': 'Icono de informaciÃ³n',
    },
    'de': {
        'dismiss-notification': 'Benachrichtigung verwerfen',
        'success-icon': 'Symbol âErfolgreichâ',
        'failure-icon': 'Fehlersymbol',
        'info-icon': 'Informationssymbol',
    },
    'fr': {
        'dismiss-notification': 'Ignorer la notification',
        'success-icon': 'IcÃ´ne de rÃ©ussite',
        'failure-icon': "IcÃ´ne d'Ã©chec",
        'info-icon': "IcÃ´ne d'informations",
    },
    'ja': {
        'dismiss-notification': 'éç¥ãç ´æ£',
        'success-icon': 'æåã¢ã¤ã³ã³',
        'failure-icon': 'éå®³ã¢ã¤ã³ã³',
        'info-icon': 'æå ±ã¢ã¤ã³ã³',
    },
    'ko': {
        'dismiss-notification': 'ìë¦¼ í´ì ',
        'success-icon': 'ì±ê³µ ìì´ì½',
        'failure-icon': 'ì¤í¨ ìì´ì½',
        'info-icon': 'ì ë³´ ìì´ì½',
    },
    'zh_TW': {
        'dismiss-notification': 'éééç¥',
        'success-icon': 'æåå¾æ ',
        'failure-icon': 'å¤±æåç¤º',
        'info-icon': 'è³è¨åç¤º',
    },
    'zh_CN': {
        'dismiss-notification': 'å³é­éç¥',
        'success-icon': 'æåå¾æ ',
        'info-icon': 'ä¿¡æ¯å¾æ ',
        'failure-icon': 'æéå¾æ ',
    },
    'it': {
        'dismiss-notification': 'Ignora notifica',
        'success-icon': 'Icona Operazione riuscita',
        'info-icon': 'Icona Informazioni',
        'failure-icon': 'Icona Errore',
    },
    'nl': {
        'dismiss-notification': 'Melding negeren',
        'success-icon': 'Pictogram Geslaagd',
        'info-icon': 'Pictogram Informatie',
        'failure-icon': 'Pictogram Fout',
    },
    'pt': {
        'dismiss-notification': 'Descartar notificaÃ§Ã£o',
        'success-icon': 'Ãcone de Ãªxito',
        'info-icon': 'Ãcone de informaÃ§Ã£o',
        'failure-icon': 'Ãcone de falha',
    },
    'ru': {
        'dismiss-notification': 'ÐÐ°ÐºÑÑÑÑ ÑÐ²ÐµÐ´Ð¾Ð¼Ð»ÐµÐ½Ð¸Ðµ',
        'success-icon': 'ÐÐ½Ð°ÑÐ¾Ðº ÑÑÐ¿ÐµÑÐ½Ð¾Ð³Ð¾ Ð²ÑÐ¿Ð¾Ð»Ð½ÐµÐ½Ð¸Ñ',
        'info-icon': 'ÐÐ½Ð°ÑÐ¾Ðº Ð¸Ð½ÑÐ¾ÑÐ¼Ð°ÑÐ¸Ð¸',
        'failure-icon': 'ÐÐ½Ð°ÑÐ¾Ðº Ð¾ÑÐ¸Ð±ÐºÐ¸',
    },
};
