// Generated at 2018-08-03T19:56:20.781Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ServerInfoService {
    public static instance: ServerInfoService;


    constructor(public config: TangoServiceConfiguration) {

        if (!ServerInfoService.instance) {
            ServerInfoService.instance = this;
        }
    }

    getServerInfoObject(headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/search/serverInfo', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
