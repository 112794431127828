// tslint:disable


export class SampleExchangeSampleFile {
    public id: number;
    public sampleId: number;
    public path: string;
    public url: string;
    public basicMimeType: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
