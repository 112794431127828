// tslint:disable


export class APIXApiManifestProductRef {
    public product_name: string;
    public product_version: string;
    public rm_product_id: number;
    public rm_release_id: number;
    public provided_api_uids: Array<string>;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
