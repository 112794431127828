// tslint:disable

import { APIXState } from './APIXState';
import { APIXParameter } from './APIXParameter';

export class APIXMethod {
    public id: number;
    public parent_api_id: number;
    public name: string;
    public description: string;
    public documentation_uri: string;
    public state: APIXState;
    public parameters: Array<APIXParameter>;
    public return_values: Array<APIXParameter>;
    public created_date: string;
    public lastModified: string;
    public updatedBy: string;
    constructor(json: any) {
        Object.assign(this, json);
        this.state = new APIXState(this.state);
        if (this.parameters) {
            this.parameters = this.parameters.map(item => new APIXParameter(item));
        }
        if (this.return_values) {
            this.return_values = this.return_values.map(item => new APIXParameter(item));
        }
    }
}
