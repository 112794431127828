// tslint:disable


export class APIXParameterType {
    public name: string;
    public documentation_uri: string;
    public detail: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
