import {
    Component,
    Output,
    EventEmitter,
    Input
} from "@angular/core";
import {
    style,
    animate,
    transition,
    trigger,
    state
} from "@angular/animations";
import { VmwSimpleTranslateService } from "@vmw/ngx-utils";
import { TRANSLATIONS } from './accordion.l10n';

/*
    To use AccordionComponent, include:
        title property,
        tag with .accordion-header class (will be projected into accordion header),
        tag with .accordion-body class (will be projected into accordion body),
 */

export enum VmwAccordionCaretPosition {
    LEFT,
    RIGHT
}

@Component({
    selector: "vmw-accordion",
    templateUrl: "accordion.component.html",
    styleUrls: ["./accordion.component.scss"],
    animations: [
        trigger(
            "collapse", [
                state("in", style({
                    "height": "*",
                    "overflow": "hidden"
                })),

                transition(":enter", [
                    style({
                        "height": "0",
                        "overflow": "hidden"
                    }),
                    animate("0.2s ease-in-out"),
                ]),

                transition(":leave", [
                    style({
                        "height": "*",
                        "overflow": "hidden"
                    }),
                    animate("0.2s ease-in-out", style({height: 0}))
                ]),
            ]
        )
    ]
})

export class VmwAccordion {
    public readonly VmwAccordionCaretPosition = VmwAccordionCaretPosition;

    @Input("title") title: string = null;
    @Input("is-expanded") isExpanded: boolean = false;
    @Input("expandable") expandable: boolean = true;
    @Input("is-overlayed") isOverlayed: boolean = false;
    @Input("opaque") opaque: boolean = false;
    @Input("caretPosition") caretPosition = VmwAccordionCaretPosition.LEFT;
    @Output("on-expand-change") onExpandChange = new EventEmitter();
    @Output("toggle-support") toggleSupportEmitter = new EventEmitter();

    constructor(public translateService: VmwSimpleTranslateService) {
        this.translateService.loadTranslationsForComponent('accordion', TRANSLATIONS);
    }

    toggleExpand($event: Event) {
        if (!this.expandable) {
            return;
        }
        this.isExpanded = !this.isExpanded;
        this.onExpandChange.emit(this.isExpanded);
        $event.stopPropagation();
    }

   toggleExpandKeyboard($event: Event) {
       // Space will scroll down. If  focus is currently on the chavka, both
       // enter and space will result in double-open / double-close. That's why
       // we prevent the default on key expand/collapse of the component.
       $event.preventDefault();
       this.toggleExpand($event);
   }
}
