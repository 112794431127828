// Generated at 2018-08-03T19:56:20.735Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeFlag } from '../../../types/SampleExchangeFlag';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class IdService {
    public static instance: IdService;


    constructor(public config: TangoServiceConfiguration) {

        if (!IdService.instance) {
            IdService.instance = this;
        }
    }

    getFlagsForEntity(entityType: string, entityId: string, headers: any = {}): Observable<Array<SampleExchangeFlag> | any> {
        let s = new Subject<Array<SampleExchangeFlag> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeFlag>) => {
            s.next(result.map(item => new SampleExchangeFlag(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            entityType: entityType,
            entityId: entityId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/flags/entity/{entityType}/id/{entityId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeAllFlags(entityType: string, entityId: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            entityType: entityType,
            entityId: entityId,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/flags/entity/{entityType}/id/{entityId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
