import { Injectable } from "@angular/core";

export enum VmwRowSelectorMode {
    MULTI,
    SINGLE,
}

export enum VmwRowSelectorButtonPosition {
    MIDDLE,
    TOP,
}

@Injectable()
export class VmwRowSelectorService {
    public name: string;
    public mode: VmwRowSelectorMode;
    public selection: Array<any> | any;
    public buttonPosition: VmwRowSelectorButtonPosition = VmwRowSelectorButtonPosition.MIDDLE;

    private _index: number = 0;

    public get index() {
        return this._index++;
    }
}
