// Generated at 2018-08-03T19:56:20.711Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { TypeService } from './Type';
import { SampleExchangeComment } from '../types/SampleExchangeComment';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class CommentsService {
    public static instance: CommentsService;

    public Type: TypeService;

    constructor(public config: TangoServiceConfiguration) {
        this.Type = new TypeService(this.config);

        if (!CommentsService.instance) {
            CommentsService.instance = this;
        }
    }

    getCommentsForCurrentUser(headers: any = {}): Observable<Array<SampleExchangeComment> | any> {
        let s = new Subject<Array<SampleExchangeComment> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeComment>) => {
            s.next(result.map(item => new SampleExchangeComment(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/comments', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createComment(body: SampleExchangeComment, headers: any = {}): Observable<SampleExchangeComment | any> {
        let s = new Subject<SampleExchangeComment | any>();

        let internalSuccessHandler = (result: SampleExchangeComment) => {
            s.next(new SampleExchangeComment(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/comments', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateComment(body: SampleExchangeComment, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.put('/comments', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeComment(body: SampleExchangeComment, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.delete('/comments', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
