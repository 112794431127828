import { Component, Input, OnChanges, EventEmitter, Output, TemplateRef, ViewChild, ElementRef } from "@angular/core";

import { VmwComboboxItem } from "./combobox-item.model";

import { ClrPopoverPosition } from "@clr/angular";

@Component({
    selector: "vmw-combobox-items",
    templateUrl: "./combobox-items.component.html",
    styleUrls: ["./combobox-items.component.scss"]
})
export class VmwComboboxItemsComponent<T extends {toString(): string} = string> implements OnChanges {
    @Input() items: Array<VmwComboboxItem<T>>;
    @Input() itemTemplate: TemplateRef<any>;
    @Input() noItemsFoundString: string;
    @Input() position: string;
    @Input() multiSelect: boolean = false;
    @Input() filterIncludes: boolean = false;
    @Input() filterWithPrefix: boolean = true;
    @Input() showItems: boolean = false;
    @Input() contentPosition: ClrPopoverPosition;

    /**
     * Emits an array of items when multiSelect === true. Otherwise emits a ComboboxItem.
     */
    @Output() onSelectionChange = new EventEmitter<VmwComboboxItem<T>|Array<VmwComboboxItem<T>>>();

    /**
     * Emits the id of the focused id item
     */
    @Output() onItemFocus = new EventEmitter<string>();
    @Output() onItemClicked = new EventEmitter<VmwComboboxItem<T>>();
    @Output() onLoadMore = new EventEmitter<void>();

    filteredItems: Array<VmwComboboxItem<T>>;
    selectedIndex = -1;
    /**
     * Contains a set of selected items when multiSelect === true.
     */
    selectedItems: Set<VmwComboboxItem<T>> = new Set();

    @ViewChild('comboboxItem') comboboxItem: ElementRef;

    getItemId(index: number): string {
        return `combobox-item-${index}`;
    }

    emitItemIdOnFocus(itemId?: string) {
        this.onItemFocus.emit(itemId);
    }

    trackBy(index: number, item: VmwComboboxItem<T>) {
        return item.value;
    }

    selectNext() {
        if (this.filteredItems == null || this.filteredItems.length === 0) {
            return;
        }
        if (this.selectedIndex === this.filteredItems.length - 1) {
            this.selectedIndex = 0;
        } else {
            this.selectedIndex++;
        }
        if (this.comboboxItem) {
            const { scrollTop } = this.comboboxItem.nativeElement;
            const top = this.comboboxItem.nativeElement.children[this.selectedIndex]?.offsetTop;
            const elementHeight = this.comboboxItem.nativeElement.children[this.selectedIndex]?.scrollHeight;
            const containerHeight = this.comboboxItem.nativeElement.clientHeight;
            if(top && top < scrollTop) {
                this.comboboxItem.nativeElement.scrollTop = top;
            } else if(top && top + elementHeight > scrollTop + containerHeight) {
                this.comboboxItem.nativeElement.scrollTop = top - containerHeight + elementHeight;
            }
        }
        this.onSelectionChange.emit(this.filteredItems[this.selectedIndex]);
        this.emitItemIdOnFocus(this.getItemId(this.selectedIndex));
    }

    selectPrev() {
        if (this.filteredItems == null || this.filteredItems.length === 0) {
            return;
        }
        if (this.selectedIndex === 0 || this.selectedIndex === -1) {
            this.selectedIndex = this.filteredItems.length - 1;
        } else {
            this.selectedIndex--;
        }
        if (this.comboboxItem) {
            const { scrollTop } = this.comboboxItem.nativeElement;
            const containerHeight = this.comboboxItem.nativeElement.clientHeight;
            const top = this.comboboxItem.nativeElement.children[this.selectedIndex]?.offsetTop;
            if(top && top < scrollTop || top > scrollTop + containerHeight) {
                this.comboboxItem.nativeElement.scrollTop = top;
            }
        }
        this.onSelectionChange.emit(this.filteredItems[this.selectedIndex]);
        this.emitItemIdOnFocus(this.getItemId(this.selectedIndex));
    }

    hasItemWithPrefix(prefix: string): boolean {
        if (this.filteredItems == null || this.filteredItems.length === 0) {
            return false;
        }
        return this.filteredItems.some(
            this.displayValueStartsWith.bind(this, prefix));
    }

    private displayValueStartsWith(prefix: string, item: VmwComboboxItem<T>): boolean {
        if (!prefix || item == null) {
            return false;
        }
        return item.displayValue.toString().toLowerCase().startsWith(prefix.toLowerCase());
    }

    private displayValueWith(prefix: string, item: VmwComboboxItem<T>): boolean {
        if (!prefix || item == null) {
            return false;
        }
        return item.displayValue.toString().toLowerCase().includes(prefix.toLowerCase());
    }

    getFirstItemContainingPrefix(prefix: string): VmwComboboxItem<T> {
        if (this.filteredItems == null || this.filteredItems.length === 0) {
            return null;
        }
        return this.filteredItems.find(
            this.displayValueStartsWith.bind(this, prefix));
    }

    filter(prefix: string) {
        if (this.items == null || this.items.length === 0) {
            return;
        }
        if (!prefix) {
            this.filteredItems = this.items;
        } else {
            this.filteredItems = this.items.filter(this.filterIncludes ?
                this.displayValueWith.bind(this, prefix) :
                this.displayValueStartsWith.bind(this, prefix)
            );
        }
    }

    findByDisplayValue(value: string): VmwComboboxItem<T> {
        if (this.filteredItems == null || this.filteredItems.length === 0 || !value) {
            return null;
        }

        let filterMethod: any = this.displayValueStartsWith.bind(this, value);

        if (this.filterIncludes) {
            filterMethod = this.displayValueWith.bind(this, value);
        } else if (!this.filterWithPrefix) {
            filterMethod = (itemValue: VmwComboboxItem<T>) => {
                return value === itemValue.displayValue.toString();
            };
        }

        return this.filteredItems.find(filterMethod);
    }

    onItemClick(item: VmwComboboxItem<T>) {
        if (this.multiSelect) {
            if (this.selectedItems.has(item)) {
                this.selectedItems.delete(item);
            } else {
                this.selectedItems.add(item);
            }
            this.onSelectionChange.emit(Array.from(this.selectedItems));
        } else {
            this.onSelectionChange.emit(item);
            this.selectItem(item);
        }
        this.onItemClicked.emit(item);
        this.emitItemIdOnFocus(this.getItemId(this.filteredItems.indexOf(item)));
    }

    selectItem(itemToSelect: VmwComboboxItem<T>) {
        this.selectedIndex = this.items?.findIndex((item) => {
            return itemToSelect === item;
        });
    }

    selectItems(itemsToSelect: Array<VmwComboboxItem<T>>) {
        this.selectedItems.clear();
        itemsToSelect.forEach((item) => {
            this.selectedItems.add(item);
        });
        this.onSelectionChange.emit(Array.from(this.selectedItems));
    }

    clearSelection() {
        this.selectedIndex = -1;
        this.emitItemIdOnFocus();
    }
    
    /**
     * A passthrough function to re-emit infinite scroll's event for loading more items
     */
    showMoreItems() {
        this.onLoadMore.emit();
    }

    ngOnChanges(changes: any) {
        if (changes && changes.items) {
            this.filteredItems = changes.items.currentValue;
            if (changes.items.firstChange || !changes.items.previousValue) {
				this.selectedIndex = -1;
                this.emitItemIdOnFocus();
            }
        }
    }

    onMouseDown(e: MouseEvent) {
        // Prevent combobox from closing when its scrollbar is clicked.
        if ((e.target as Element).tagName == "UL") {
            e.preventDefault();
        }
    }
}
