// Generated at 2018-08-03T19:56:16.993Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXMethod } from '../../../types/APIXMethod';
import { APIXErrorResponse } from '../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class MethodsService {
    public static instance: MethodsService;


    constructor(public config: TangoServiceConfiguration) {

        if (!MethodsService.instance) {
            MethodsService.instance = this;
        }
    }

    getApiMethods(api_id: string, headers: any = {}): Observable<Array<APIXMethod> | APIXErrorResponse> {
        let s = new Subject<Array<APIXMethod> | APIXErrorResponse>();

        let internalSuccessHandler = (result: Array<APIXMethod>) => {
            s.next(result.map(item => new APIXMethod(item)));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_id: api_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/apis/{api_id}/methods', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createApiMethod(api_id: string, body: APIXMethod, headers: any = {}): Observable<APIXMethod | APIXErrorResponse> {
        let s = new Subject<APIXMethod | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXMethod) => {
            s.next(new APIXMethod(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_id: api_id,
        };

        let queryParams = {};

        let result = this.config.transport.post('/apix/apis/{api_id}/methods', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateApiMethod(api_id: string, method_id: string, body: APIXMethod, headers: any = {}): Observable<APIXMethod | APIXErrorResponse> {
        let s = new Subject<APIXMethod | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXMethod) => {
            s.next(new APIXMethod(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_id: api_id,
            method_id: method_id,
        };

        let queryParams = {};

        let result = this.config.transport.put('/apix/apis/{api_id}/methods/{method_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteApiMethod(api_id: string, method_id: string, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_id: api_id,
            method_id: method_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/apix/apis/{api_id}/methods/{method_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
