import { Component, Input } from "@angular/core";

@Component({
    selector: "vmw-swagger-property",
    templateUrl: "./swagger-property.component.html",
    styleUrls: ["./swagger-property.component.scss"]
})
export class VmwSwaggerPropertyComponent {
    @Input() property: any;
    @Input() definitions: any;
    @Input() value: any;
    @Input() markdown: boolean = false;

    JSON = JSON;
}
