// Generated at 2018-08-03T19:56:20.711Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { IdService } from './Id';
import { ParentIdService } from './ParentId';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class TypeService {
    public static instance: TypeService;

    public Id: IdService;
    public ParentId: ParentIdService;

    constructor(public config: TangoServiceConfiguration) {
        this.Id = new IdService(this.config);
        this.ParentId = new ParentIdService(this.config);

        if (!TypeService.instance) {
            TypeService.instance = this;
        }
    }

}
