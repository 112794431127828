import { EventEmitter, Component, Input, Output } from "@angular/core";

import { saveAs } from "file-saver";

@Component({
    selector: "vmw-swagger-definition",
    templateUrl: "./swagger-definition.component.html",
    styleUrls: ["./swagger-definition.component.scss"],
})
export class VmwSwaggerDefinitionComponent {
    @Input() definition: any;
    @Input() definitions = new Map<string, any>();
    @Input() list: boolean = false;
    @Input() data: any = null;
    @Input() expanded = false;
    @Input() markdown = false;

    @Output() click = new EventEmitter<null>();

    public expandedItem: Array<boolean> = [];
    public dataJSON: string;
    public dataJSONList: Array<string> = [];

    public get clickable() {
        return this.click.observers.length > 0;
    }

    public getDefinition(name: string) {
        return this.definitions.get(name);
    }

    ngOnChanges(changes: any) {
        if (changes.data && changes.data.currentValue) {
            if (changes.data.currentValue  instanceof Array) {
                for (let item in changes.data.currentValue) {
                    this.dataJSONList[item as any] = JSON.stringify(changes.data.currentValue[item as any], null, 4);
                }
            } else {
                this.dataJSON = JSON.stringify(changes.data.currentValue, null, 4);
            }
        }
    }

    downloadJSON(jsonContent: string, item: any) {
        let filename = (item.name || item.id || this.definition.name) + ".json";

        var blob = new Blob([jsonContent], {
            type: "text/plain;charset=utf-8",
        });

        saveAs(blob, filename);
    }
}
