// Generated at 2018-08-03T19:56:20.755Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class NameService {
    public static instance: NameService;


    constructor(public config: TangoServiceConfiguration) {

        if (!NameService.instance) {
            NameService.instance = this;
        }
    }

    addTag(id: string, category: string, name: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
            category: category,
            name: name,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.put('/samples/{id}/tag/category/{category}/name/{name}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeTag(id: string, category: string, name: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
            category: category,
            name: name,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/samples/{id}/tag/category/{category}/name/{name}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
