// Generated at 2018-08-03T19:56:16.967Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXApiDef } from '../../../../types/APIXApiDef';
import { APIXErrorResponse } from '../../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class UidsService {
    public static instance: UidsService;


    constructor(public config: TangoServiceConfiguration) {

        if (!UidsService.instance) {
            UidsService.instance = this;
        }
    }

    getApiDefForUID(api_uid: string, headers: any = {}): Observable<APIXApiDef | APIXErrorResponse> {
        let s = new Subject<APIXApiDef | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApiDef) => {
            s.next(new APIXApiDef(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            api_uid: api_uid,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/apis/defs/uids/{api_uid}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
