// Generated at 2018-08-03T19:56:20.753Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { NameService } from './Name';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class TypeService {
    public static instance: TypeService;

    public Name: NameService;

    constructor(public config: TangoServiceConfiguration) {
        this.Name = new NameService(this.config);

        if (!TypeService.instance) {
            TypeService.instance = this;
        }
    }

}
