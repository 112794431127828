import { Optional, Pipe, PipeTransform } from '@angular/core';

import { L10nService, L10nPipe } from '@vmw/ngx-vip';

/*
 * For apps that have not yet integrated vIP and initialized it, we won't
 * have access to the L10N translate pipe. This pipe will check for the
 * availability of the L10nService and use the existinv vIP pipe if it
 * is available.
 */
@Pipe({
    name: 'translateIfPossible',
})
export class DevCenterTranslateIfPossiblePipe implements PipeTransform {
    constructor(@Optional() private l10nService: L10nService) {}

    transform(key: string, source: string, ...args: string[]): string {
        if (this.l10nService) {
            const pipe = new L10nPipe(this.l10nService);
            return pipe.transform(key, source, ...args);
        } else {
            return this.format(source, ...args);
        }
    }

    private format(format: string, ...args: string[]) {
        return format.replace(/{(\d+)}/g, function(match, number) {
            return typeof args[number] != 'undefined'
               ? args[number]
               : match;
        });
    }
}
