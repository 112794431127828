// Generated at 2018-08-03T19:56:20.758Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { UserService } from './User';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class EventsService {
    public static instance: EventsService;

    public User: UserService;

    constructor(public config: TangoServiceConfiguration) {
        this.User = new UserService(this.config);

        if (!EventsService.instance) {
            EventsService.instance = this;
        }
    }

}
