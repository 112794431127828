// Generated at 2018-08-03T19:56:20.753Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class ResyncSampleQueryParams {
    force?: string;
}

export class ResyncService {
    public static instance: ResyncService;


    constructor(public config: TangoServiceConfiguration) {

        if (!ResyncService.instance) {
            ResyncService.instance = this;
        }
    }

    resyncSample(id: string, queryParams: ResyncSampleQueryParams, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let body = {};

        let result = this.config.transport.post('/samples/resync/{id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
