// Generated at 2018-08-03T19:56:20.744Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { VersionService } from './Version';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class NameService {
    public static instance: NameService;

    public Version: VersionService;

    constructor(public config: TangoServiceConfiguration) {
        this.Version = new VersionService(this.config);

        if (!NameService.instance) {
            NameService.instance = this;
        }
    }

}
