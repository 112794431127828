// Generated at 2018-08-03T19:56:16.913Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { ApixService } from './Apix';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class APIXService {
    public static instance: APIXService;

    public Apix: ApixService;

    constructor(public config: TangoServiceConfiguration) {
        this.Apix = new ApixService(this.config);

        if (!APIXService.instance) {
            APIXService.instance = this;
        }
    }

}
