// tslint:disable

import { APIXSearchResult } from './APIXSearchResult';

export class APIXSearchResults {
    public cursor_mark_next: string;
    public num_found: number;
    public results: Array<APIXSearchResult>;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.results) {
            this.results = this.results.map(item => new APIXSearchResult(item));
        }
    }
}
