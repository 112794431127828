// Generated at 2018-08-03T19:56:17.009Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { RmproductService } from './Rmproduct';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ImportService {
    public static instance: ImportService;

    public Rmproduct: RmproductService;

    constructor(public config: TangoServiceConfiguration) {
        this.Rmproduct = new RmproductService(this.config);

        if (!ImportService.instance) {
            ImportService.instance = this;
        }
    }

}
