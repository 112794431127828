// Generated at 2018-08-03T19:56:20.783Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeSolution } from '../../types/SampleExchangeSolution';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class RequestService {
    public static instance: RequestService;


    constructor(public config: TangoServiceConfiguration) {

        if (!RequestService.instance) {
            RequestService.instance = this;
        }
    }

    createSolution(requestId: string, body: SampleExchangeSolution, headers: any = {}): Observable<SampleExchangeSolution | object> {
        let s = new Subject<SampleExchangeSolution | object>();

        let internalSuccessHandler = (result: SampleExchangeSolution) => {
            s.next(new SampleExchangeSolution(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            requestId: requestId,
        };

        let queryParams = {};

        let result = this.config.transport.post('/solutions/request/{requestId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
