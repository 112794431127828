import {
    Component,
    Output,
    EventEmitter,
    Input
} from "@angular/core";

import {
    style,
    animate,
    transition,
    trigger,
    state
} from "@angular/animations";

export class VmwAccordionWizardStepState {
    static INCOMPLETE = 1;
    static COMPLETE = 2;
}

@Component({
    selector: "vmw-accordion-wizard-step",
    templateUrl: "accordion-wizard-step.component.html",
    styleUrls: ["./accordion-wizard-step.component.scss"],
    animations: [
        trigger(
            "collapse", [
                state("in", style({
                    "height": "*",
                    "overflow": "hidden"
                })),

                transition(":enter", [
                    style({
                        "height": "0",
                        "overflow": "hidden"
                    }),
                    animate("0.2s ease-in-out"),
                ]),

                transition(":leave", [
                    style({
                        "height": "*",
                        "overflow": "hidden"
                    }),
                    animate("0.2s ease-in-out", style({height: 0}))
                ]),

            ]
        )
    ]
})
export class VmwAccordionWizardStep {
    @Input() isExpanded: boolean = false;
    @Input() isOverlayed: boolean = false;
    @Input() canExpand: boolean = false;
    @Input() valid: boolean = null;
    @Input() enabled: boolean = true;
    @Input() disableNextButton: boolean = false;
    @Input() showNextButton: boolean = true;
    @Input() showCaret = false;
    @Input() nextButtonText: string = "Next";
    @Input() disablePreviousButton: boolean = false;
    @Input() showPreviousButton: boolean = true;
    @Input() previousButtonText: string = "Back";
    @Input() nextButtonClass = 'btn-primary';
    @Input() previousButtonClass = 'btn-primary';

    @Output() onExpandChange = new EventEmitter();
    @Output() nextStep = new EventEmitter();
    @Output() previousStep = new EventEmitter();
    @Output() validate = new EventEmitter();
    @Output() validChange = new EventEmitter();

    public stepState = VmwAccordionWizardStepState.INCOMPLETE;
    public VmwAccordionWizardStepState = VmwAccordionWizardStepState;
    public internalNextButtonText: string = null;
    public internalPreviousButtonText: string = null;
    public enabledChange = new EventEmitter();
    public index: number;

    toggleExpand() {
        this.onExpandChange.emit(this);
    }

    ngOnChanges(changes: any) {
        if (changes.valid) {
            if (changes.valid.currentValue) {
                this.triggerNextStep();
            }
        } else if (changes.enabled) {
            this.enabledChange.emit();
        }
    }

    nextStepClick() {
        this.valid = null;
        this.validChange.emit(this.valid);

        if (this.validate.observers.length === 0) {
            this.triggerNextStep();
        } else {
            this.stepState = VmwAccordionWizardStepState.INCOMPLETE;

            // the new validation has to happen after the validity has been nulled by the
            // above emitter.
            setTimeout(() => {
                this.validate.emit();
            });
        }
    }

    previousStepClick() {
        this.previousStep.emit();
    }

    triggerNextStep(): void {
        if (this.valid === null || this.valid) {
            this.stepState = VmwAccordionWizardStepState.COMPLETE;
            this.canExpand = true;
            this.nextStep.emit(this);
        }
    }

}
