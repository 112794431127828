// tslint:disable

import { SampleExchangeComment } from './SampleExchangeComment';

export class SampleExchangeSolution {
    public id: number;
    public requestId: number;
    public federationId: string;
    public communitiesUser: string;
    public description: string;
    public comments: Array<SampleExchangeComment>;
    public created: string;
    public lastUpdated: string;
    public acceptedSolution: boolean;
    public linkedSampleId: number;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.comments) {
            this.comments = this.comments.map(item => new SampleExchangeComment(item));
        }
    }
}
