import { Input, ContentChild, Component, Optional } from "@angular/core";

import {
    Router,
    ActivatedRoute,
    NavigationEnd,
} from "@angular/router";

import { Subscription } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";

import { VmwSwaggerListComponent } from "../swagger-list";
import { VmwDeveloperCenterDownloadsPageComponent } from "./downloads-page.component";
import { VmwDeveloperCenterSDKsPageComponent } from "./sdks-page/sdks-page.component";
import { VmwDeveloperCenterDocsPageComponent } from "./docs-page/docs-page.component";
import { VmwDeveloperCenterAPIExplorerPageComponent } from "./api-explorer-page/api-explorer-page.component";
import { VmwDeveloperCenterCodeSamplePageComponent } from "./code-sample-page/code-sample-page.component";
import { VmwDeveloperCenterOverviewPageComponent } from "./overview-page.component";
import { VmwAPIXService } from "../providers/apix.service";
import { VmwSampleExchangeService } from "../providers/sample-exchange.service";
import { VmwSegmentService } from "@vmw/ngx-utils";

export enum VmwDeveloperCenterTabs {
    Overview = "overview",
    APIExplorer = "api-explorer",
    Samples = "code-samples",
    SDKs = "sdks",
    Documentation = "documentation",
    Downloads = "downloads",
}

@Component({
    selector: "vmw-developer-center",
    templateUrl: "./developer-center.component.html",
    styleUrls: ["./developer-center.component.scss"]
})
export class VmwDeveloperCenterComponent {
    @ContentChild(VmwDeveloperCenterDownloadsPageComponent, { static: false }) downloadsPage: VmwDeveloperCenterDownloadsPageComponent;
    @ContentChild(VmwDeveloperCenterOverviewPageComponent, { static: false }) overviewPage: VmwDeveloperCenterOverviewPageComponent;
    @ContentChild(VmwDeveloperCenterSDKsPageComponent, { static: false }) sdksPage: VmwDeveloperCenterSDKsPageComponent;
    @ContentChild(VmwDeveloperCenterDocsPageComponent, { static: false }) docsPage: VmwDeveloperCenterDocsPageComponent;
    @ContentChild(VmwDeveloperCenterCodeSamplePageComponent, { static: false }) codeSamplesPage: VmwDeveloperCenterCodeSamplePageComponent;
    @ContentChild(VmwDeveloperCenterAPIExplorerPageComponent, { static: false }) apiExplorerPage: VmwDeveloperCenterAPIExplorerPageComponent;

    @Input() clientID: string;
    @Input() clientUUID: string;
    @Input() clientUserID: string;
    @Input() sampleExchangeBaseUrl: string;
    @Input() apixBaseUrl: string;

    public home = true;
    public apiExplorerApi = false;
    public DeveloperCenterTabs = VmwDeveloperCenterTabs;
    public currentTab = VmwDeveloperCenterTabs.Overview;
    public numApiExplorers: number = 0;
    public currentApi: VmwSwaggerListComponent;
    public hideSDKsTab: boolean = false;
    public hideDocsTab: boolean = false;
    public hideCodeSamplesTab: boolean = false;

    private firstTab = VmwDeveloperCenterTabs.Overview;
    private validTabs = [
        VmwDeveloperCenterTabs.Overview,
        VmwDeveloperCenterTabs.APIExplorer,
        VmwDeveloperCenterTabs.Samples,
        VmwDeveloperCenterTabs.SDKs,
        VmwDeveloperCenterTabs.Documentation,
        VmwDeveloperCenterTabs.Downloads,
    ];
    private routerSubscription: Subscription;
    private sdksPageEmptySubscription: Subscription;
    private docsPageEmptySubscription: Subscription;
    private codeSamplesPageEmptySubscription: Subscription;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private sampleExchangeService: VmwSampleExchangeService,
                private apiXService: VmwAPIXService,
                @Optional() private segmentService: VmwSegmentService) {
        let potentialTab;

        // get the current tab on load
        if (route.snapshot.url && route.snapshot.url.length === 2 &&
                (route.snapshot.url[0].path as VmwDeveloperCenterTabs) === VmwDeveloperCenterTabs.APIExplorer) {
            this.home = false;
            this.apiExplorerApi = true;
            potentialTab = VmwDeveloperCenterTabs.APIExplorer;
        } else if (route.snapshot.url[0]) {
            potentialTab = route.snapshot.url[0].path as VmwDeveloperCenterTabs;
        } else {
            this.home = false;
        }

        if (this.validTabs.indexOf(potentialTab as VmwDeveloperCenterTabs) !== -1) {
            this.currentTab = potentialTab as VmwDeveloperCenterTabs;
        }

        // watch for router events and change the mode
        this.routerSubscription = this.router.events
            .pipe(
                filter(re => re instanceof NavigationEnd),
                distinctUntilChanged()
            ).subscribe((re: NavigationEnd) => {
                let url = re.url;
                let trackingEventName: string = '';

                if (url.indexOf("#") !== -1) {
                    url = url.split("#")[0];
                } else {
                    const lastUrlSegment: VmwDeveloperCenterTabs = url.split('/').slice(-1)[0] as VmwDeveloperCenterTabs;

                    if (lastUrlSegment === VmwDeveloperCenterTabs.APIExplorer) {
                        trackingEventName = 'NGX_Developer_Center_PageLoad_AccessAPIExplorerTab';
                    } else if (lastUrlSegment === VmwDeveloperCenterTabs.Overview) {
                        trackingEventName = 'NGX_Developer_Center_PageLoad_AccessOverviewTab';
                    } else if (lastUrlSegment === VmwDeveloperCenterTabs.Samples) {
                        trackingEventName = 'NGX_Developer_Center_PageLoad_AccessCodeSamplesTab';
                    }

                    if (this.validTabs.indexOf(lastUrlSegment) !== -1 && !!this.segmentService) {
                        this.segmentService.trackEvent(trackingEventName);
                    }    
                }

                // Check for existence of opening bracket.
                // This is required to handle the presence of any auxiliary routes in the url.
                // Note: Angular default url encoding adds auxiliary routes inside ()
                if (url.indexOf("(") !== -1) {
                    url = url.split("(")[0];
                }

                let bits = url.split("/");

                let potentialTab = bits[bits.length - 1] as VmwDeveloperCenterTabs;
                let previousSegment = bits[bits.length - 2];

                if (this.validTabs.indexOf(potentialTab) !== -1 ||
                        previousSegment === VmwDeveloperCenterTabs.APIExplorer) {
                    if (previousSegment !== VmwDeveloperCenterTabs.APIExplorer) {
                        this.currentTab = potentialTab;
                        this.home = true;
                        this.apiExplorerApi = false;
                    } else {
                        this.currentTab = VmwDeveloperCenterTabs.APIExplorer;
                        this.apiExplorerApi = true;
                    }
                } else {
                    this.currentTab = this.firstTab;
                }                
            });
    }

    ngOnChanges(changes: any) {
        if (changes.clientID && changes.clientID.currentValue) {
            this.sampleExchangeService.setClientID(changes.clientID.currentValue);
            this.apiXService.setClientID(changes.clientID.currentValue);
        }

        if (changes.clientUUID && changes.clientUUID.currentValue) {
            this.sampleExchangeService.setClientUUID(changes.clientUUID.currentValue);
            this.apiXService.setClientUUID(changes.clientUUID.currentValue);
        }

        if (changes.clientUserID && changes.clientUserID.currentValue) {
            this.sampleExchangeService.setClientUserID(changes.clientUserID.currentValue);
            this.apiXService.setClientUserID(changes.clientUserID.currentValue);
        }

        if (changes.sampleExchangeBaseUrl && changes.sampleExchangeBaseUrl.currentValue) {
            this.sampleExchangeService.setServiceBaseUrl(changes.sampleExchangeBaseUrl.currentValue);
        }

        if (changes.apixBaseUrl && changes.apixBaseUrl.currentValue) {
            this.apiXService.setServiceBaseUrl(changes.apixBaseUrl.currentValue);
        }
    }

    ngAfterContentInit() {
        if (!this.overviewPage && this.apiExplorerPage) {
            this.firstTab = VmwDeveloperCenterTabs.APIExplorer;
            this.currentTab = VmwDeveloperCenterTabs.APIExplorer;
        }

        if (this.sdksPage) {
            this.sdksPageEmptySubscription = this.sdksPage.empty.subscribe((empty: boolean) => {
                this.hideSDKsTab = empty;
            });
        }

        if (this.docsPage) {
            this.docsPageEmptySubscription = this.docsPage.empty.subscribe((empty: boolean) => {
                this.hideDocsTab = empty;
            });
        }

        if (this.codeSamplesPage) {
            this.codeSamplesPageEmptySubscription = this.codeSamplesPage.empty.subscribe((empty: boolean) => {
                this.hideCodeSamplesTab = empty;
            });
        }
    }

    navigate(page: string) {
        if (this.apiExplorerApi) {
            return '../../' + page;
        } else if (this.home) {
            return '../' + page;
        } else {
            return page;
        }
    }

    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }

        if (this.sdksPageEmptySubscription) {
            this.sdksPageEmptySubscription.unsubscribe();
        }

        if (this.docsPageEmptySubscription) {
            this.docsPageEmptySubscription.unsubscribe();
        }

        if (this.codeSamplesPageEmptySubscription) {
            this.codeSamplesPageEmptySubscription.unsubscribe();
        }
    }
}
