// tslint:disable

import { APIXParameterType } from './APIXParameterType';

export class APIXParameter {
    public name: string;
    public type: APIXParameterType;
    constructor(json: any) {
        Object.assign(this, json);
        this.type = new APIXParameterType(this.type);
    }
}
