// Generated at 2018-08-03T19:56:20.746Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SolutionService {
    public static instance: SolutionService;


    constructor(public config: TangoServiceConfiguration) {

        if (!SolutionService.instance) {
            SolutionService.instance = this;
        }
    }

    addSolution(id: string, solutionId: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
            solutionId: solutionId,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.put('/requests/{id}/solution/{solutionId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeSolution(id: string, solutionId: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
            solutionId: solutionId,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/requests/{id}/solution/{solutionId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
