// Generated at 2018-08-03T19:56:17.033Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { RmreleaseService } from './Rmrelease';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ImportService {
    public static instance: ImportService;

    public Rmrelease: RmreleaseService;

    constructor(public config: TangoServiceConfiguration) {
        this.Rmrelease = new RmreleaseService(this.config);

        if (!ImportService.instance) {
            ImportService.instance = this;
        }
    }

}
