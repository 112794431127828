// Generated at 2018-08-03T19:56:20.720Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeFavorite } from '../../types/SampleExchangeFavorite';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SampleService {
    public static instance: SampleService;


    constructor(public config: TangoServiceConfiguration) {

        if (!SampleService.instance) {
            SampleService.instance = this;
        }
    }

    getSampleFavorites(headers: any = {}): Observable<Array<SampleExchangeFavorite> | any> {
        let s = new Subject<Array<SampleExchangeFavorite> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeFavorite>) => {
            s.next(result.map(item => new SampleExchangeFavorite(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/favorites/sample', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createSampleFavorite(body: SampleExchangeFavorite, headers: any = {}): Observable<SampleExchangeFavorite | any> {
        let s = new Subject<SampleExchangeFavorite | any>();

        let internalSuccessHandler = (result: SampleExchangeFavorite) => {
            s.next(new SampleExchangeFavorite(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/favorites/sample', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getSampleFavorite(sampleId: string, headers: any = {}): Observable<SampleExchangeFavorite | any> {
        let s = new Subject<SampleExchangeFavorite | any>();

        let internalSuccessHandler = (result: SampleExchangeFavorite) => {
            s.next(new SampleExchangeFavorite(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sampleId: sampleId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/favorites/sample/{sampleId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeSampleFavorite(sampleId: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sampleId: sampleId,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/favorites/sample/{sampleId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
