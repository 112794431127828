// Generated at 2018-08-03T19:56:20.775Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeSample } from '../../../../../types/SampleExchangeSample';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class VersionService {
    public static instance: VersionService;


    constructor(public config: TangoServiceConfiguration) {

        if (!VersionService.instance) {
            VersionService.instance = this;
        }
    }

    getSamplesByCategoryAndVersion(type: string, version: string, headers: any = {}): Observable<Array<SampleExchangeSample> | any> {
        let s = new Subject<Array<SampleExchangeSample> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeSample>) => {
            s.next(result.map(item => new SampleExchangeSample(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            type: type,
            version: version,
        };

        let queryParams = {};

        let result = this.config.transport.get('/search/samples/category/type/{type}/version/{version}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
