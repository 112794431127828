// Generated at 2018-08-03T19:56:20.749Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeSampleFileHandling } from '../../types/SampleExchangeSampleFileHandling';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class FileExtensionService {
    public static instance: FileExtensionService;


    constructor(public config: TangoServiceConfiguration) {

        if (!FileExtensionService.instance) {
            FileExtensionService.instance = this;
        }
    }

    getSampleFileHandlingForExtension(fileExtension: string, headers: any = {}): Observable<SampleExchangeSampleFileHandling | any> {
        let s = new Subject<SampleExchangeSampleFileHandling | any>();

        let internalSuccessHandler = (result: SampleExchangeSampleFileHandling) => {
            s.next(new SampleExchangeSampleFileHandling(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            fileExtension: fileExtension,
        };

        let queryParams = {};

        let result = this.config.transport.get('/sampleFileHandling/fileExtension/{fileExtension}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
