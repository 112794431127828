// Generated at 2018-08-03T19:56:17.008Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { CategoriesService } from './Categories';
import { ImportService } from './Import';
import { ReleasesService } from './Releases';
import { ResourcesService } from './Resources';
import { APIXProduct } from '../../types/APIXProduct';
import { APIXErrorResponse } from '../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class GetProductsQueryParams {
    page?: string;
    type?: string;
    brand?: string;
    name_substring?: string;
}

export class ProductsService {
    public static instance: ProductsService;

    public Categories: CategoriesService;
    public Import: ImportService;
    public Releases: ReleasesService;
    public Resources: ResourcesService;

    constructor(public config: TangoServiceConfiguration) {
        this.Categories = new CategoriesService(this.config);
        this.Import = new ImportService(this.config);
        this.Releases = new ReleasesService(this.config);
        this.Resources = new ResourcesService(this.config);

        if (!ProductsService.instance) {
            ProductsService.instance = this;
        }
    }

    getProducts(queryParams: GetProductsQueryParams, headers: any = {}): Observable<Array<APIXProduct> | any> {
        let s = new Subject<Array<APIXProduct> | any>();

        let internalSuccessHandler = (result: Array<APIXProduct>) => {
            s.next(result.map(item => new APIXProduct(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };


        let result = this.config.transport.get('/apix/products', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createProduct(body: APIXProduct, headers: any = {}): Observable<APIXProduct | APIXErrorResponse> {
        let s = new Subject<APIXProduct | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXProduct) => {
            s.next(new APIXProduct(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/apix/products', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getProduct(product_id: string, headers: any = {}): Observable<APIXProduct | APIXProduct> {
        let s = new Subject<APIXProduct | APIXProduct>();

        let internalSuccessHandler = (result: APIXProduct) => {
            s.next(new APIXProduct(result));
            s.complete();
        };

        let failureHandler = (error: APIXProduct) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            product_id: product_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/products/{product_id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateProduct(product_id: string, body: APIXProduct, headers: any = {}): Observable<APIXProduct | APIXErrorResponse> {
        let s = new Subject<APIXProduct | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXProduct) => {
            s.next(new APIXProduct(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            product_id: product_id,
        };

        let queryParams = {};

        let result = this.config.transport.put('/apix/products/{product_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteProduct(product_id: string, headers: any = {}): Observable<object | object> {
        let s = new Subject<object | object>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            product_id: product_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/apix/products/{product_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
