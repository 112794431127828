import { Component, Input, OnInit } from "@angular/core";

import { APIXResource } from "../api/APIX/types";

@Component({
    templateUrl: "./sdk-card.component.html",
    selector: "vmw-sdk-card",
    styleUrls: ["./sdk-card.component.scss"],
})
export class VmwSDKCardComponent implements OnInit {
    @Input() sdk: APIXResource;
    @Input() showGettingStarted = false;

    public gettingStartedUrl: string;

    ngOnInit() {
        this.gettingStartedUrl = this.sdk.download_url + '#quick-start-guide';
    }
}
