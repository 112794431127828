// tslint:disable


export class SampleExchangeFavorite {
    public id: number;
    public parentId: number;
    public type: string;
    public federationId: string;
    public communitiesUser: string;
    public created: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
