import { ViewChild, ViewContainerRef, QueryList, ContentChildren, Component } from "@angular/core";

import { VmwCardCatalogCardComponent } from "./card-catalog-card.component";
import { VmwCardCatalogService } from "./card-catalog.service";

@Component({
    selector: "vmw-card-catalog",
    templateUrl: "./card-catalog.component.html",
    styleUrls: ["./card-catalog.component.scss"],
    providers: [VmwCardCatalogService],
})
export class VmwCardCatalogComponent<T = any> {
    @ContentChildren(VmwCardCatalogCardComponent)
    cards: QueryList<VmwCardCatalogCardComponent>;

    @ViewChild('cardContent', { read: ViewContainerRef, static: true })
    displayedCards: ViewContainerRef;

    constructor(private service: VmwCardCatalogService) {}

    ngOnInit() {
        this.service.clear.subscribe(() => {
             this.displayedCards.clear();
        });

        this.service.added.subscribe((card) => {
            this.displayedCards.insert(card);
        });

        this.service.removed.subscribe((card) => {
            this.displayedCards.remove(this.displayedCards.indexOf(card));
        });
    }
}
