// tslint:disable


export class SampleExchangeGitHubRepository {
    public id: number;
    public name: string;
    public description: string;
    public url: string;
    public organization: string;
    public createdTimeAgo: string;
    public updatedTimeAgo: string;
    public watcherCount: number;
    public starCount: number;
    public forkCount: number;
    public openIssueCount: number;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
