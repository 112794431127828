/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Configuration passed to the directive from host component
 */
export interface VmwSplitterConfigOptions {
    sizes?: number[]; //sizes to be provided in '%' unit
    minSizes?: number[]; //sizes to be provided in 'px' unit
    collapseSizes?: number[]; //sizes to be provided in 'px' unit. When collapsing a pane, size can differ from its `minSize`
    gutterSize?: number; //gutter thickness in 'px' unit
    gutterAlign?: VmwSplitterGutterAlign; //Possible values: "center", "start", "end" (Refer enum GutterAlign)
    dragInterval?: number; // Number of pixels dragged
    direction?: "horizontal" | "vertical";
    /**
     *    Direction: Horizontal ->
     *       |                     ||                   |
     *       |     Start           ||       End         |
     *       |     Panel           ||      Panel        |
     *       |                     ||                   |
     *       |                     ||                   |
     *
     *    Direction Vertical ->
     *       _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
     *
     *                         Start
     *                         Panel
     *
     *       ===========================================
     *
     *                          End
     *                         Panel
     *       _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
     */
}

/**
 * Used to store properties related to a panel
 */
export interface VmwSplitterElementProperties {
    element: HTMLElement;
    size: number;
    minSize: number;
    collapseSize: number;
    index: number;
    gutterSize: number;
    collapseRestoreSize: number;
}

/**
 * Split Pair: set of the start and end panels including the gutter splitting the two.
 *
 *       |                     ||                   |
 *       |     Start           ||       End         |
 *       |     Panel           ||      Panel        |
 *       |                     ||                   |
 *       |                     ||                   |
 *        <-----------------Total Size------------->
 *       ^                                          ^
 *       ^                                          ^
 *  Start Position                              End Position
 */
export interface VmwSplitterSplitPairProperties {
    size: number; // Total size pf the set of panels  (See above diagram)
    start: number; // Start position of the set of the panels (See above diagram)
    end: number; // End position of the set of the panels (See above diagram)
    dragOffset: number; // Stores the offset of the drag pointer used to calculate panel sizes while dragging is in progress
    gutterSize: number; // Thickness of the gutter
    snapOffset: number; // Snap to minimum size offset in pixels
    dragInterval: number; // Number of pixels dragged
    gutterAlign: VmwSplitterGutterAlign; //Alignment of gutter : "start" (entirely with start panel),
    // "center" (equally divided between start & end panels), "end" (entirely with end panel)
    dimension: string;   // If horizontal splitter => dimension =  "width", If vertical splitter => dimension = "height
    position: string;    // If horizontal splitter => position =  "left", If vertical splitter => position = "top
    positionEnd: string; // If horizontal splitter => positionEnd =  "right", If vertical splitter => positionEnd = "bottom
    clientAxis: string;  // If horizontal splitter => clientAxis =  "X axis", If vertical splitter => clientAxis = "Y axis
    gutterStartDragging: () => void; // Handler called when dragging started
    move: () => void; // Handler called when dragging is in progress
    stop: () => void; // Handler called when dragging stopped
    dragStartEvents: (() => void)[]; // Stores handlers to unsubscribe events => mousedown, touchdown
    dragEvents: (() => void)[]; // Stores handlers to unsubscribe events => mousemove, touchmove
    dragStopEvents: (() => void)[]; // Stores handlers to unsubscribe events => mouseup, touchend, touchcancel
    elementDragEvents: (() => void)[]; // Stores handlers to unsubscribe events => selectstart, dragstart
}

export enum VmwSplitterGutterAlign {
    center,
    start,
    end
}

export enum VmwSplitterDirection {
    Horizontal = "horizontal",
    Vertical = "vertical",
}

export const DEFAULT_MIN_PANEL_SIZE = 100;
export const DEFAULT_ELEMENT_COLLAPSE_SIZE = 0;
export const DEFAULT_GUTTER_SIZE = 3;
export const MINIMUM_GUTTER_SIZE = 3;
export const DEFAULT_PANEL_SIZE = 50;
export const DEAFULT_SNAP_OFFSET = 0;

export const dragStartEvents = ['mousedown', 'touchstart'];
export const dragEvents = ['mousemove', 'touchmove'];
export const dragStopEvents = ['mouseup', 'touchend', 'touchcancel'];
export const elementDragEvents = ['selectstart', 'dragstart'];
export const NOOP = () => false;
