import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { CommonModule } from "@angular/common";

import { ClrTooltipModule } from '@clr/angular';
import { VmwSimpleTranslateModule } from "@vmw/ngx-utils";

import { VmwRightDrawerComponent } from "./right-drawer.component";

@NgModule({
    declarations: [
        VmwRightDrawerComponent,
    ],
    imports: [
        CommonModule,
        ClrTooltipModule,
        VmwSimpleTranslateModule,
    ],
    exports: [
        VmwRightDrawerComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class VmwRightDrawerModule {}
