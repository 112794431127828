// tslint:disable


export class SampleExchangeTag {
    public id: number;
    public parentId: number;
    public category: string;
    public name: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
