// tslint:disable

import { APIXApi } from './APIXApi';
import { APIXApiManifestProductRef } from './APIXApiManifestProductRef';

export class APIXApiManifest {
    public build_url: string;
    public publish_dir_path: string;
    public override_api_def: boolean;
    public no_remove_apis: boolean;
    public apis: Array<APIXApi>;
    public products: Array<APIXApiManifestProductRef>;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.apis) {
            this.apis = this.apis.map(item => new APIXApi(item));
        }
        if (this.products) {
            this.products = this.products.map(item => new APIXApiManifestProductRef(item));
        }
    }
}
