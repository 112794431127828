// Generated at 2018-08-03T19:56:20.716Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { CacheService } from './Cache';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class AllService {
    public static instance: AllService;

    public Cache: CacheService;

    constructor(public config: TangoServiceConfiguration) {
        this.Cache = new CacheService(this.config);

        if (!AllService.instance) {
            AllService.instance = this;
        }
    }

}
