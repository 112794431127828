// Generated at 2018-08-03T19:56:20.739Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { EmailService } from './Email';
import { RequestService } from './Request';
import { SampleService } from './Sample';
import { SampleExchangeNotificationInstance } from '../types/SampleExchangeNotificationInstance';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class NotificationsService {
    public static instance: NotificationsService;

    public Email: EmailService;
    public Request: RequestService;
    public Sample: SampleService;

    constructor(public config: TangoServiceConfiguration) {
        this.Email = new EmailService(this.config);
        this.Request = new RequestService(this.config);
        this.Sample = new SampleService(this.config);

        if (!NotificationsService.instance) {
            NotificationsService.instance = this;
        }
    }

    getNotificationsForCurrentUser(headers: any = {}): Observable<Array<SampleExchangeNotificationInstance> | any> {
        let s = new Subject<Array<SampleExchangeNotificationInstance> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeNotificationInstance>) => {
            s.next(result.map(item => new SampleExchangeNotificationInstance(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/notifications', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createNotification(body: SampleExchangeNotificationInstance, headers: any = {}): Observable<SampleExchangeNotificationInstance | any> {
        let s = new Subject<SampleExchangeNotificationInstance | any>();

        let internalSuccessHandler = (result: SampleExchangeNotificationInstance) => {
            s.next(new SampleExchangeNotificationInstance(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/notifications', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteNotificationEmail(id: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/notifications/{id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
