import {
    Component,
    ContentChildren,
    OnDestroy,
    QueryList
} from '@angular/core';
import { VmwTaskIdentifier, VmwTaskStatus } from '../util/task.model';
import { VmwProgressTrackerTaskComponent } from '../progress-tracker-task/progress-tracker-task.component';
import { VmwProgressTrackerConfigService } from '../util/tracker-config.service';
import { Subscription } from 'rxjs';
import { VmwTaskGroupService } from '../util/tasks.service';

@Component({
    selector: 'vmw-progress-tracker-task-group',
    templateUrl: './task-group.component.html',
    styleUrls: ['./task-group.component.scss'],
})
export class VmwProgressTrackerTaskGroupComponent implements OnDestroy {
    public config: any;
    public VmwTaskStatus = VmwTaskStatus;
    public subscription: Subscription;
    public lastCompletedTaskSubscription: Subscription;
    public lastCompletedTask: VmwTaskIdentifier;

    @ContentChildren(VmwProgressTrackerTaskComponent) taskComponents: QueryList<VmwProgressTrackerTaskComponent>;

    constructor(private trackerConfigService: VmwProgressTrackerConfigService, private taskGroupService: VmwTaskGroupService) {
        this.subscription = this.trackerConfigService.getConfig().subscribe((value) => {
            this.config = value;
        });
        this.lastCompletedTaskSubscription = this.taskGroupService.getLastCompletedTaskId().subscribe((value: VmwTaskIdentifier) => {
            this.lastCompletedTask = value;
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.lastCompletedTaskSubscription.unsubscribe();
    }

    getFirstTaskStatus(): boolean {
        if (this.lastCompletedTask && this.taskComponents.first.id) {
            const taskId = this.taskComponents.first.id;
            return (taskId.groupIndex <= this.lastCompletedTask.groupIndex);
        }
        return false;
    }
}