// Generated at 2018-08-03T19:56:20.743Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { RequestService } from './Request';
import { SampleExchangeRequestAssignment } from '../../types/SampleExchangeRequestAssignment';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class AssignmentsService {
    public static instance: AssignmentsService;

    public Request: RequestService;

    constructor(public config: TangoServiceConfiguration) {
        this.Request = new RequestService(this.config);

        if (!AssignmentsService.instance) {
            AssignmentsService.instance = this;
        }
    }

    getAssignments(headers: any = {}): Observable<Array<SampleExchangeRequestAssignment> | object> {
        let s = new Subject<Array<SampleExchangeRequestAssignment> | object>();

        let internalSuccessHandler = (result: Array<SampleExchangeRequestAssignment>) => {
            s.next(result.map(item => new SampleExchangeRequestAssignment(item)));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/requests/assignments', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeAssignment(id: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/requests/assignments/{id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
