import { Component, EventEmitter, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

import { VmwSampleExchangeService } from "../../providers/sample-exchange.service";

import { SampleExchangeSample } from "../../api/SampleExchange/types";
import { debounceTime } from "rxjs/operators";

@Component({
    templateUrl: "./code-sample-page.component.html",
    selector: "vmw-developer-center-code-sample-page",
    styleUrls: ["./code-sample-page.component.scss"],
})
export class VmwDeveloperCenterCodeSamplePageComponent {
    @Input() platform: string;
    @Input() tags: string[];
    @Input() reqTags : string[];
    @Input() samples: Array<SampleExchangeSample> = null;

    public empty = new EventEmitter<boolean>();

    public error: string = null;
    public filterControl = new FormControl();
    public samplesFiltered: Array<SampleExchangeSample> = [];

    constructor(private sampleExchangeService: VmwSampleExchangeService) {
        this.filterControl.valueChanges
        .pipe(debounceTime(1000))
        .subscribe(filterValue => {
            this.filterSamples(filterValue);
        });
    }

    ngOnChanges (changes: any) {
        if (changes.platform && changes.platform.currentValue) {
            this.sampleExchangeService.getSamples(this.platform, this.tags, this.reqTags, true)
                .subscribe((samples: Array<SampleExchangeSample>) => {
                    this.samples = samples;
                    this.empty.emit(this.samples.length === 0);
                    this.filterSamples();
                }, (error: string) => {
                    if (!this.samples) {
                        this.samples = [];
                        this.error = error;
                    }
                });
        }

        if (changes.samples && changes.samples.currentValue) {
            this.error = null;
        }

        this.filterSamples();
    }

    private filterSamples(filter?: string) {
        if (!filter) {
            this.samplesFiltered = this.samples;
        } else {
            this.samplesFiltered = this.samples.filter((sample: SampleExchangeSample) => {
                return sample.name.match(new RegExp(filter, 'i')) ||
                    sample.tags.filter(t => t.name.match(new RegExp(filter, 'i'))).length > 0 ||
                    sample.readmeHtml.match(new RegExp(filter, 'i'));
            });
        }
    }
}
