import {
    ChangeDetectionStrategy,
    Component,
    ChangeDetectorRef,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    HostBinding,
    HostListener,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';

import { isObservable, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { VmwSimpleTranslateService } from "@vmw/ngx-utils";

import { TRANSLATIONS } from './bulk-email-input.component.l10n';

const EMAIL_PATTERN_REGEX: RegExp = new RegExp(
    [
        '^([^\\s#])(([^+=/<>\\\\(){}%\'|!#&*?~[\\]\\,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))',
        '@((\\[[0-9]{1,3}\\.[0-9]{1,3}.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ].join('')
);

export enum ClarityLabelsColorClasses {
    INFO = 'label-info',
    SUCCESS = 'label-success',
    WARNING = 'label-warning',
    ERROR = 'label-danger',
    PURPLE = 'label-purple',
    BLUE = 'label-blue',
    ORANGE = 'label-orange',
    LIGHT_BLUE = 'label-light-blue',
    DEFAULT = 'label',
}

export enum ClarityIconShapes {
    SUCCESS = 'check-circle',
    ERROR = 'exclamation-circle',
}

export enum ClarityIconClasses {
    SUCCESS = 'is-success',
    ERROR = 'is-error',
}

export enum KeyboardEventKeyCodes {
    ARROW_LEFT = 37,
    ARROW_RIGHT = 39,
    ENTER = 13,
    COMMA = 188,
    BACKSPACE = 8,
    ESCAPE = 27,
    SPACE = 32,
    ARROW_DOWN = 40,
    ARROW_UP = 38,
}

/**
 * Signature of Validations
 *
 * @property pattern: a Regular Expresion to test
 * @property labelColor [optional]: a css Clarity class for the label color
 */
export interface IValidFormat {
    pattern: RegExp;
    labelColor?: ClarityLabelsColorClasses | string;
}

export interface IErrorState {
    hasError: boolean;
    hasWarning: boolean;
    message: string;
}

export interface IValidatedEmail {
    value: string;
    valid: boolean;
    labelColor?: ClarityLabelsColorClasses | string;
    labelIconShape?: string;
    labelIconClass?: string;
    labelIconTitle?: string;
    isEditing?: boolean;
    isValidating?: boolean;
}



export interface ITypeAheadConfig {
    source: Observable<any[]> | any[];
    minLength?: number;
    displayProp?: string;
    valueProp?: string;
    debounceTime?:number; //in milliseconds
}

// see https://tools.ietf.org/html/rfc3696 for email address
// validation
// Generally, for the local part (before the @) it can be any ascii char except
// starting or ending with a period.
const EMAIL_VALIDATION: IValidFormat = {
    pattern: /^[^.][a-zA-Z0-9!#$%&'*+-/=?^_`.{|}~]*[^.]@[A-Za-z0-9]+([.-][a-zA-Z0-9]+)*\.[A-Za-z]{2,}$/,
    labelColor: ClarityLabelsColorClasses.SUCCESS,
};

const FAILED_EMAIL_VALIDATION: IValidFormat = {
    pattern: /^failed:[^.][a-zA-Z0-9!#$%&'*+-/=?^_`.{|}~]*[^.]@[A-Za-z0-9]+([.-][a-zA-Z0-9]+)*\.[A-Za-z]{2,}$/,
    labelColor: ClarityLabelsColorClasses.ORANGE,
};

export const LONG_EMAIL_REGEX = /^(.* <)?(.*@[^>]*)(>)?$/;

export const FAILED_EMAIL_REGEX = new RegExp('failed:', 'g');

const DEFAULT_PLACEHOLDER = "Enter email addresses";

const VMW_BULK_EMAIL_INPUT_PLACEHOLDER = 'VMW-BULK-EMAIL-INPUT-PLACEHOLDER';

@Component({
    selector: 'vmw-bulk-email-input',
    templateUrl: './bulk-email-input.component.html',
    styleUrls: ['./bulk-email-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VmwBulkEmailInputComponent {
    @ViewChild('input') inputEmailRef: ElementRef;
    @ViewChild('editInput') editEmailInputRef: ElementRef;
    @ViewChild('labelList') labelList: ElementRef;
    @ViewChildren('userLabel') userLabelRefs: QueryList<ElementRef>;

    @Input() ariaLabel: string = "Email";
    @Input() placeholder = DEFAULT_PLACEHOLDER;
    @Input() value: string | IValidatedEmail[];
    @Input() loading: boolean = false;
    @Input() clearBtn: boolean = true;
    @Input() clearBtnTitle: string = "Clear";
    @Input() errorMessage: string;
    @Input() showEmailValidityCounter: boolean = true;
    @Input() additionalValidFormats: IValidFormat[] = [];
    @Input() externalValidateFn: (emails: string[]) => Observable<IValidatedEmail[]>;
    @Input() typeAheadConfig: ITypeAheadConfig;
    @Input() helpText: string;
    @Input() showValidityIndicator: boolean = true;
    @Input() hideLabelIcon: boolean = false;
    @Input() defaultValidFormat: IValidFormat;

    @Output() validEmails: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    @Output() validChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() typeAheadSearchUpdated: EventEmitter<string> = new EventEmitter<string>();

    userLabelsRefElements: ElementRef<any>[];
    userLabelsToShow: IValidatedEmail[] = [];
    inputEditEmailValue: string = '';
    editInputLength: number = 0;
    inputEmailValue :string;
    validCount: number = 0;
    invalidCount: number = 0;
    typeAheadSearchResult = [] as any;
    waitingForTypeAheadResults: boolean;
    selectedTypeAheadOption = '';
    currentSelectedTypeAheadOptionIndex: number;

    public get showNativePlaceholder(): boolean {
        if (!this.labelList) return false;
        if (this.userLabelsToShow?.length) return false;
        const hasCustomPlaceholder = this.labelList.nativeElement.querySelector('vmw-bulk-email-input-placeholder');
        return !hasCustomPlaceholder;
    }

    private validFormats: IValidFormat[];
    private editEmailCachedValue: string = '';
    private isReadyToFocusInput: boolean = true;
    private isReadyToFocusEditInput: boolean = false;
    private subs = new Subscription();
    private canCleanAllLabels: boolean = false;
    private isAsyncTypeAhead: boolean;
    private emailChange = new Subject();

    public constructor(public translateService: VmwSimpleTranslateService,
                       private cdr: ChangeDetectorRef,
                       public el: ElementRef) {
        this.translateService.loadTranslationsForComponent('bulk-email-input', TRANSLATIONS);
    }

    ngOnChanges(changes: any) {
        if (changes.additionalValidFormats &&
            !changes.additionalValidFormats.isFirstChange() &&
            this.additionalValidFormats) {
            this.validFormats = [FAILED_EMAIL_VALIDATION, this.defaultValidFormat ?? EMAIL_VALIDATION, ...this.additionalValidFormats];
        }

        if (changes.value && !changes.value.isFirstChange()) {
            const value = changes.value.currentValue;

            /**
             * used to clear all labels when the input value contains an empty string or empty array
             * and prevent looking for labels when they have been already deleted inside the component
             */
            if ((!value || !value.length) && this.canCleanAllLabels) {
                this.clearAllLabels();

            } else {
                this.canCleanAllLabels = true;

                if (!!this.userLabelsToShow && this.userLabelsToShow.length) {
                    this.userLabelsToShow = this.userLabelsToShow.filter(label => label.valid);
                }

                this.processValueChange(value);
            }
        }

        // if (this.labelList && this.labelList.nativeElement) {
        //     this.hasCustomPlaceholder = this.labelList.nativeElement.querySelector('vmw-bulk-email-input-placeholder');
        // }
    }

    ngOnInit() {
        this.validFormats = [FAILED_EMAIL_VALIDATION, this.defaultValidFormat ?? EMAIL_VALIDATION, ...this.additionalValidFormats];
        this.processValueChange(this.value);
        if (this.typeAheadConfig) {
            this.initTypeAhead();
        }
    }

    initTypeAhead() {
        this.isAsyncTypeAhead = isObservable(this.typeAheadConfig.source);

        //setting typeahead defaults
        this.typeAheadConfig.debounceTime = this.typeAheadConfig.debounceTime || 500;
        this.typeAheadConfig.minLength = this.typeAheadConfig.minLength || 1;

        if (this.isAsyncTypeAhead) {
            // Subscribe to async input data stream
            this.subs.add( (<Observable<any[]>>this.typeAheadConfig.source).subscribe((searchResult: []) => {
                this.waitingForTypeAheadResults = false;
                this.typeAheadSearchResult = searchResult;
            }));
            
            // Add some debounce time to async search
            this.emailChange.pipe(
                debounceTime(this.typeAheadConfig.debounceTime)).subscribe(
                (searchText: string) => {
                    this.waitingForTypeAheadResults = true;
                    this.typeAheadSearchUpdated.emit(searchText);
                });
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    ngAfterViewInit() {
        if ((!this.userLabelsRefElements ||
            !this.userLabelsRefElements.length) && this.inputEmailRef) {
            this.focusNewEmailInput();
        }
    }

    ngAfterViewChecked() {
        this.userLabelsRefElements = this.userLabelRefs.toArray();

        if (this.isReadyToFocusInput && this.inputEmailRef) {
            this.focusNewEmailInput();
            this.isReadyToFocusInput = false;
        }

        if (this.isReadyToFocusEditInput && this.editEmailInputRef) {
            this.focusEditEmailInput();
            this.isReadyToFocusEditInput = false;
        }

        this.cdr.detectChanges();
    }

    public removeTooltip(labelValue: string): string {
        return this.translateService.translate('bulk-email-input.remove', labelValue);
    }

    public editTooltip(labelValue: string): string {
        return labelValue;
    }

    /**
     * listen for left and right arrow or Enter pressing in order to be able to focus the suitable element from the
     * sequence and to be able to navigate in it at pressing arrows. At pressing Enter it's opened editing certain email
     * @param ev - KeyboardEvent
     * @param index - index of the label element
     */
    onLabelKeyup(ev: KeyboardEvent, index: number) {
        if (ev.keyCode === KeyboardEventKeyCodes.ARROW_LEFT && index !== 0) {
            this.userLabelsRefElements[index - 1].nativeElement.focus();
        }

        if (ev.keyCode === KeyboardEventKeyCodes.ARROW_RIGHT && index !== this.userLabelRefs.length - 1) {
            this.userLabelsRefElements[index + 1].nativeElement.focus();
        }

        if (ev.keyCode === KeyboardEventKeyCodes.ARROW_RIGHT && index === this.userLabelRefs.length - 1) {
            this.inputEmailRef.nativeElement.focus();
        }

        if (ev.keyCode === KeyboardEventKeyCodes.ENTER) {
            this.editEmail(this.userLabelsToShow[index].value, index);
        }
    }

    /**
     * listen for pressing Enter or Comma in order to be able to process the input data
     * listen for Left Arrow pressing in order to focus the last label if it is available
     * @param ev - KeyboardEvent
     * @param index  - index of label element which is edited
     */
    onInputKeyup(ev: KeyboardEvent) {
        const code = ev.which || ev.keyCode;

        switch (code) {
            case KeyboardEventKeyCodes.ENTER:
            case KeyboardEventKeyCodes.SPACE:
            case KeyboardEventKeyCodes.COMMA:
                if (this.inputEmailValue.length) {
                    // Choosing option via keyboard UP/DOWN key and hit enter,comma or space
                    if(this.typeAheadSearchResult.length && this.currentSelectedTypeAheadOptionIndex !==-1) {
                        const currentOption = this.typeAheadSearchResult[this.currentSelectedTypeAheadOptionIndex];
                        this.inputEmailValue =  this.typeAheadConfig.valueProp? currentOption[this.typeAheadConfig.valueProp]:currentOption
                    }
                    this.processEmailInputChange(this.inputEmailValue);
                }
                break;

            case KeyboardEventKeyCodes.ARROW_LEFT:
                if (!this.canFocusLabel()) {
                    break;
                }

                this.userLabelRefs.last.nativeElement.focus();
                break;

            case KeyboardEventKeyCodes.ARROW_DOWN:
            case KeyboardEventKeyCodes.ARROW_UP:
                this.nextTypeAheadSelection(code);
                break;

            default:
                break;
        }
    }

    /**
     * listen for pressing Enter in order to be able to process the input data when editing email
     * @param ev - KeyboardEvent
     * @param index  - index of label element which is edited
     */
    onEditEmailInputKeyup(ev: KeyboardEvent, index: number) {
        const code = ev.which || ev.keyCode;

        switch (code) {
            case KeyboardEventKeyCodes.BACKSPACE:
                if (!!this.inputEditEmailValue &&
                    this.inputEditEmailValue.length === 1 &&
                    this.userLabelsToShow.length === 1) {
                    this.inputEmailRef.nativeElement.focus();
                    this.userLabelsToShow.splice(0, 1);
                }
                break;

            case KeyboardEventKeyCodes.ENTER:
            case KeyboardEventKeyCodes.COMMA:
            case KeyboardEventKeyCodes.SPACE:
                // Choosing option via keyboard UP/DOWN key and hit enter,comma or space
                if(this.typeAheadSearchResult.length && this.currentSelectedTypeAheadOptionIndex !==-1) {
                    const currentOption = this.typeAheadSearchResult[this.currentSelectedTypeAheadOptionIndex];
                    this.inputEditEmailValue =  this.typeAheadConfig.valueProp? currentOption[this.typeAheadConfig.valueProp]:currentOption
                }
                this.processEditEmailInputChange(this.inputEditEmailValue, index);
                break;

            case KeyboardEventKeyCodes.ESCAPE:
                this.processEditEmailInputChange(this.editEmailCachedValue, index);
                break;

            case KeyboardEventKeyCodes.ARROW_DOWN:
            case KeyboardEventKeyCodes.ARROW_UP:
                this.nextTypeAheadSelection(code);
                break;
        }
    }

    /**
     * listen for Backspace use when input the email and focus the appropriate element
     * @param ev - KeyboardEvent
     */
    onInputBackspaceKeydown() {
        if (!this.canFocusLabel()) {
            return;
        }

        this.userLabelRefs.last.nativeElement.focus();
    }

    /**
     * listen for Backspace use when the email is been editing and focus the appropriate element
     * @param ev - KeyboardEvent
     * @param index - index of label element which is edited
     */
    onEditEmailInputKeydown(ev: KeyboardEvent, index: number) {
        const code = ev.which || ev.keyCode;

        if (code === KeyboardEventKeyCodes.BACKSPACE &&
            (!this.inputEditEmailValue || !this.inputEditEmailValue.length)) {
            let elementToFocus: ElementRef;

            if (index !== 0) {
                elementToFocus = this.userLabelsRefElements[index - 1];
            } else if (index === 0 && this.userLabelsToShow.length) {
                elementToFocus = this.userLabelsRefElements[0];
            } else {
                elementToFocus = this.inputEmailRef;
            }

            elementToFocus.nativeElement.focus();
        }
    }

    @HostBinding('class.error') get error() {
        return this.errorMessage;
    }

    /**
     * listen for clicking on the container element to be able to focus the email input element
     * @param ev - MouseEvent
     */
    @HostListener('click', ['$event.target'])
    onLabelsContainerClick(target: ElementRef) {
        if (this.isValidating()) {
            return;
        }

        if (target === this.labelList.nativeElement) {
            this.isReadyToFocusInput = true;
        }
    }

    removeLabel(email: string) {
        if (this.isValidating()) {
            return;
        }

        this.userLabelsToShow = this.userLabelsToShow.filter(label => label.value !== email);

        if (!!this.externalValidateFn) {
            this.userLabelsToShow.map(e => e.isValidating = true);
            this.callExternalValidateFn();
        } else {
            this.processComponentStateIsChanged();
            this.inputEmailRef.nativeElement.focus();
        }
    }

    clearAllLabels() {
        if (this.isValidating()) {
            return;
        }

        this.canCleanAllLabels = false;
        this.userLabelsToShow = [];
        this.processComponentStateIsChanged();
        this.inputEmailRef.nativeElement.focus();
    }

    onEmailInputBlur() {
            // When value selected from typeahead dropdown
            if (this.selectedTypeAheadOption) {
                this.inputEmailValue = this.selectedTypeAheadOption;
            }

            if (this.inputEmailValue?.length) {
                this.typeAheadSearchResult = [];
                this.processEmailInputChange(this.inputEmailValue);
            } else {
                // When input field is blank but touched
                this.processComponentStateIsChanged();
            }
    }

    onEditEmailInputBlur(index: number) {
            // When value selected from typeahead dropdown
            if (this.selectedTypeAheadOption) {
                this.inputEditEmailValue = this.selectedTypeAheadOption;
            }
            if (this.inputEditEmailValue?.length) {
                this.processEditEmailInputChange(this.inputEditEmailValue, index);

            } else {
                // user deleted all the text, remove the label
                this.removeLabel(this.editEmailCachedValue);
            }
            this.editEmailCachedValue = '';
    }

    /**
     * handle clicking on label
     * @param email - string
     */
    editEmail(email: string, index: number) {
        this.validChange.emit(false);

        this.editEmailCachedValue = email;

        this.userLabelsToShow.forEach(label => {
            label.isEditing = false;

            if (label.value === email) {
                label.isEditing = true;
            }
        });

        if (!!this.userLabelsToShow && this.userLabelsToShow.length === 1) {
            this.userLabelsToShow = [];
            this.inputEmailValue = email;
            this.inputEmailRef.nativeElement.focus();
        } else {
            this.editInputLength = email.trim().length;
            this.inputEditEmailValue = email;
            this.isReadyToFocusEditInput = true;
        }
    }

    processEditEmailInputChange(inputString: string, indexEditedEmail: number) {
        // Trims leading and trailing comma and space
        const emailToValidate: string = inputString.replace(/(^[,\s]+)|([,\s]+$)/g, '');

        this.userLabelsToShow[indexEditedEmail].isEditing = false;

        const tokens = this.removeDuplicates(this.tokenizeString(emailToValidate));
        if(tokens.length === 0 && this.userLabelsToShow[indexEditedEmail].value !== emailToValidate) {
            // Removing the duplicate element
            this.userLabelsToShow.splice(indexEditedEmail,1);
        } else {
            if (!!this.externalValidateFn && this.validateIsEmail(emailToValidate)) {
                this.userLabelsToShow[indexEditedEmail].isValidating = true;
                this.userLabelsToShow[indexEditedEmail].value = emailToValidate;
                this.callExternalValidateFn();
            } else {
                this.userLabelsToShow[indexEditedEmail] = this.validateToken(emailToValidate, this.validFormats);
                this.processComponentStateIsChanged();
            }
        }
        this.clearInputs();
    }

    processEmailInputChange(inputString: string) {
        const tokens = this.removeDuplicates(this.tokenizeString(inputString));

        const validatedTokens = tokens.map(token => {
            const _token = this.validateToken(token, this.validFormats);

            // Clear token marked as `failed:`
            _token.value = _token.value.replace(FAILED_EMAIL_REGEX, '');

            if (!!this.externalValidateFn && _token.valid && this.validateIsEmail(_token.value)) {
                _token.isValidating = true;
            }

            return _token;
        });

        this.userLabelsToShow = [...this.userLabelsToShow, ...validatedTokens];

        this.inputEmailValue = '';

        if (!!this.externalValidateFn) {
            if (validatedTokens.length) {
                this.callExternalValidateFn();
            } else {
                const invalidEmails = this.userLabelsToShow.filter(label => !label.valid);
                this.invalidCount = invalidEmails.length;
            }
        } else {
            this.processComponentStateIsChanged();
        }

        this.clearInputs();
    }

    isEditing(): boolean {
        return this.userLabelsToShow.some(label => label.isEditing);
    }

    isValidating(): boolean {
        return this.userLabelsToShow.some(label => label.isValidating);
    }

    /**
     * Highlight typeahead option based on UP/DOWN arrow key press
     * @param userAction
     */
    private nextTypeAheadSelection(userAction:KeyboardEventKeyCodes) {
        if(this.typeAheadSearchResult.length) {
            if(this.currentSelectedTypeAheadOptionIndex === -1) {
                this.currentSelectedTypeAheadOptionIndex = 0;
            }else if(userAction === KeyboardEventKeyCodes.ARROW_DOWN) {
                if(this.currentSelectedTypeAheadOptionIndex === this.typeAheadSearchResult.length-1) {
                    this.currentSelectedTypeAheadOptionIndex = 0;
                } else {
                    this.currentSelectedTypeAheadOptionIndex++;
                }
            } else {
                if(this.currentSelectedTypeAheadOptionIndex === 0) {
                    this.currentSelectedTypeAheadOptionIndex = this.typeAheadSearchResult.length-1;
                } else {
                    this.currentSelectedTypeAheadOptionIndex--;
                }
            }
        }
    }

    /**
     * Focus the new email input element. setTimeout is used to avoid
     * an ExpressionChangedAfterChecked error when these methods are called from
     * a context like ngAfterViewInit or ngAfterViewChecked.
     */
    private focusNewEmailInput() {
        setTimeout(() => {
            this.inputEmailRef.nativeElement.focus();
        });
    }

    /**
     * Focus the edit email input element. setTimeout is used to avoid
     * an ExpressionChangedAfterChecked error when these methods are called from
     * a context like ngAfterViewInit or ngAfterViewChecked.
     */
    private focusEditEmailInput() {
        setTimeout(() => {
            this.editEmailInputRef.nativeElement.focus();
        });
    }

    /**
     * in order to process the input which can be two types
     * @param value accept both - string and IValidatedEmail[]
     */
    private processValueChange(value: string | IValidatedEmail[]) {
        this.validChange.emit(false);

        if (!!value) {
            if (typeof value === 'string') {
                this.processEmailInputChange(value);
            } else {
                this.processEmailInputChange(value.map(user => user.value).join(' '));
            }
        }
    }

    private callExternalValidateFn() {
        // make sure we only pass email addresses to the external validation
        // function
        const emailsToValidate = this.userLabelsToShow
            .map(token => token.value)
            .filter(val => this.validateIsEmail(val))

        if (emailsToValidate.length === 0) {
            return;
        }

        this.externalValidateFn(emailsToValidate)
            .pipe(first())
            .subscribe((labels: IValidatedEmail[]) => {
                labels.forEach((validatedLabel: IValidatedEmail) => {
                    const label = this.userLabelsToShow.find(l => {
                        return l.value === validatedLabel.value;
                    });

                    if (label) {
                        Object.assign(label, validatedLabel, {
                            isValidating: false,
                            labelColor: validatedLabel.labelColor || (
                                validatedLabel.valid ?
                                    ClarityLabelsColorClasses.SUCCESS :
                                    ClarityLabelsColorClasses.ERROR),
                            labelIconShape: validatedLabel.labelIconShape || (
                                validatedLabel.valid ?
                                    ClarityIconShapes.SUCCESS :
                                    ClarityIconShapes.ERROR),
                            labelIconClass: validatedLabel.labelIconClass || (
                                validatedLabel.valid ?
                                    ClarityIconClasses.SUCCESS :
                                    ClarityIconClasses.ERROR),
                            labelIconTitle: validatedLabel.labelIconTitle || (
                                validatedLabel.valid ?
                                    this.translateService.translate('bulk-email-input.valid') :
                                    this.translateService.translate('bulk-email-input.invalid')),
                        });
                    }
                });

                this.processComponentStateIsChanged();

                if (this.userLabelsToShow.some(label => label.isEditing)) {
                    this.isReadyToFocusEditInput = true;
                } else {
                    setTimeout(()=>{
                        this.inputEmailRef.nativeElement.focus();
                    });
                }
            });
    }

    private clearInputs() {
        this.inputEmailValue = '';
        this.inputEditEmailValue = '';
        this.selectedTypeAheadOption = '';
        this.typeAheadSearchResult = [];
        this.currentSelectedTypeAheadOptionIndex = -1;

        // Setting focus to email input field
        setTimeout(()=>{
            if(this.inputEmailRef) {
                this.inputEmailRef.nativeElement.focus();
            }
        });
    }

    private processComponentStateIsChanged() {
        const validEmails = this.userLabelsToShow.filter(label => label.valid);
        const invalidEmails = this.userLabelsToShow.filter(label => !label.valid);
        this.invalidCount = invalidEmails.length;
        this.validEmails.emit(validEmails.map(label => label.value));
        this.validChange.emit(this.invalidCount === 0);
    }

    private tokenizeString(inputString: string): Array<string> {
        const splitTokens = inputString.trim().split(';');

        let tokens;

        if (splitTokens.length > 1) {
            tokens = splitTokens;
        } else {
            tokens = inputString.split(/[\s\r\t\n,|;]/);
        }

        return this.removeDuplicatesTokens(
            tokens.reduce((acc, token) => {
                return (acc = [...acc, ...this.normalizeEmails(token.trim()).split(/[\s\r\t\n,|]/)]);
            }, [])
        );
    }

    private removeDuplicatesTokens(tokens: Array<string>): Array<string> {
        const seen = new Set();
        return tokens.filter(item => {
            if (item === '') {
                return false;
            }

            return seen.has(item) ? false : seen.add(item);
        });
    }

    /**
     * based on a string and validation formats returns an object which includes information about if the string is a
     * valid email its value and what the color of should be.
     */
    private validateToken(token: string, validFormats: IValidFormat[]): IValidatedEmail {
        const result: IValidatedEmail = {
            value: token,
            valid: false,
            labelColor: 'label-danger',
            labelIconShape: ClarityIconShapes.ERROR,
            labelIconClass: ClarityIconClasses.ERROR,
            labelIconTitle: this.translateService.translate('bulk-email-input.invalid'),
            isEditing: false,
            isValidating: false,
        };

        for (const validFormat of validFormats) {
            if (!!validFormat && validFormat.pattern.test(token)) {
                result.valid = true;
                result.labelColor = validFormat.labelColor;
                result.labelIconShape = ClarityIconShapes.SUCCESS;
                result.labelIconClass = ClarityIconClasses.SUCCESS;
                result.labelIconTitle = this.translateService.translate('bulk-email-input.valid');
                break;
            }
        }

        return result;
    }

    public handleNewEmailInputChange(value: string) {
        this.handleTypeAheadSearch(value);
        this.validChange.emit(false);
    }

    public handleEditEmailInputChange(value: string) {
        this.editInputLength = value?.length;

        if (!!this.editEmailInputRef) {
            this.editEmailInputRef.nativeElement.focus();
        }

        this.validChange.emit(false);
        this.handleTypeAheadSearch(this.inputEditEmailValue);
    }

    private handleTypeAheadSearch(value: string) {
        this.typeAheadSearchResult = [];
        this.currentSelectedTypeAheadOptionIndex = -1;
        if (this.typeAheadConfig && value.length >= this.typeAheadConfig.minLength) {
            if (this.isAsyncTypeAhead) {
                this.waitingForTypeAheadResults = true;
                this.typeAheadSearchUpdated.emit(value);
            } else {
                this.setTypeAheadSearchResult(value);
            }
        }
    }


    /**
     * For a sync typeahead search, setting the search reswult based on input query
     * @param inputVal
     */
    private setTypeAheadSearchResult(inputVal: any) {
        if(this.typeAheadConfig.source) {
            this.typeAheadSearchResult = (<Array<any>>this.typeAheadConfig.source).filter((item: any) => {
                if (this.typeAheadConfig.displayProp) {
                    return item[this.typeAheadConfig.displayProp].toLocaleLowerCase().startsWith(inputVal.toLocaleLowerCase());
                } else {
                    return item.toLocaleLowerCase().startsWith(inputVal.toLocaleLowerCase());
                }
            });
        }
    }

    private removeDuplicates(inputEmails: string[]) {
        return inputEmails.filter(email => !this.userLabelsToShow.some(label => label.value === email));
    }

    /**
     * just to return if the string is email or not and to be able to use in conditional statements
     * @param email - string
     */
    private validateIsEmail(email: string) {
        return EMAIL_PATTERN_REGEX.test(email);
    }

    /*
     * Normalize a token in case it's an email in the form of "First Last <user@domain.com>"
     */
    private normalizeEmails(token: string): string {
        token = token.replace(/[;,"]/g, '');
        const matches = token.match(LONG_EMAIL_REGEX);

        if (matches !== null && matches[2]) {
            return matches[2];
        } else {
            return token;
        }
    }

    private canFocusLabel() {
        return (
            (!this.inputEmailValue || !this.inputEmailValue.length) &&
            !!this.userLabelRefs && this.userLabelRefs.length
        );
    }

    /**
     * Highlight the substring that matches with the query
     * @param sourceString
     * @param query
     * @return string
     */
    public highlight(sourceString: string, query: string): string {
        return sourceString.replace(new RegExp(query, "gi"), (match: string) => {
            return '<span class="highlightText">' + match + '</span>';
        });
    }
}
