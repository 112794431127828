import { EventEmitter, Output, HostBinding, Component, Input } from "@angular/core";

@Component({
    selector: "vmw-grand-progress-bar-section",
    templateUrl: "./grand-progress-bar-section.component.html",
    styleUrls: ["./grand-progress-bar-section.component.scss"]
})
export class VmwGrandProgressBarSectionComponent {
    @HostBinding("style.width.%") internalWidth = 0;
    @HostBinding("style.transition") transition = "none";

    @Input() width: number;

    @Output() widthChanged = new EventEmitter<null>();

    ngOnChanges(changes: any) {
        this.widthChanged.emit();

        setTimeout(() => {
            this.internalWidth = this.width;
        });
    }
}
