import {
    Input,
    EventEmitter,
    Output,
    Component,
    NgZone,
    HostListener,
    Optional
} from "@angular/core";
import {
    trigger,
    group,
    style,
    animate,
    transition,
    query,
    animateChild,
    keyframes,
} from '@angular/animations';

import { timer, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { VmwSegmentService } from "@vmw/ngx-utils";

import {
    multiply,
    componentPrimaryEnterCurve,
    componentPrimaryEnterTiming,
    componentPrimaryLeaveCurve,
    componentPrimaryLeaveTiming,
    linePrimaryEnterCurve,
    linePrimaryEnterTiming,
    linePrimaryEnterDelay,
    DISMISS_ICON_DELAY,
    DISMISS_ICON_DURATION,
    DISMISS_ICON_CURVE,
    GRADIENT_DURATION,
    GRADIENT_DELAY,
    GRADIENT_LEAVE_CURVE,
} from "../animation-constants";

const TAB_KEYCODE = 9;
const CTRL_MODIFIER_KEYCODE = 17;
const ALT_MODIFIER_KEYCODE = 18;
const META_MODIFIER_KEYCODE = 91;
const ESC_KEYCODE = 27;
const DEFAULT_LINK_KEYCODE = 77;  // m
const AUTODISMISS_TIMEOUT_SECONDS = 6;

@Component({
    selector: "vmw-snack",
    templateUrl: "./snack.component.html",
    styleUrls: ["./snack.component.scss"],
    animations: [
        // launching snackbar
        trigger('launchSnack', [
            // ':enter' is a default state for ngIf and ngFor, doesn't need to be predefined
            transition(':enter', [
                group([
                    // initial state
                    style({
                        // use translate for better performance than position:absolute / left,right, width
                        transform: 'translateX(-48px) scale(0, 1)'
                    }),

                    // visible
                    animate(`${multiply(componentPrimaryEnterTiming)}ms ${componentPrimaryEnterCurve}`, style({
                        transform: 'translateX(0) scale(1, 1)'
                    })),

                    // trigger child animations
                    query('.icon', animateChild()),
                    query('.checkmark', animateChild()),
                    query('.gradient', animateChild()),
                    query('.dismiss', animateChild()),
                ]),
            ]),

            // START LEAVE ANIMATION
            // ':leave' is a default state for ngIf and ngFor, doesn't need to be predefined
            transition(':leave', [
                group([
                    // visible state
                    style({
                        // use translateX for better performance than position:absolute / left,right
                        transform: 'translateX(0px) scale(1, 1)'
                    }),

                    // offscreen
                    animate(`${multiply(componentPrimaryLeaveTiming)}ms ${componentPrimaryLeaveCurve}`, style({
                        transform: 'translateX(-24px) scale(0, 1)'
                    })),

                    query('.message, .icon, .dismiss, .dismiss-bg, .message-link', [
                        animate('0.01s', style({
                            opacity: '0'
                        }))
                    ])
                ]),
            ]),
        ]),

        // checkmark line animation
        trigger('checkmarkLine', [
            transition('* => *', [
                animate(`${multiply(linePrimaryEnterTiming)}ms ${multiply(linePrimaryEnterDelay)}ms ${linePrimaryEnterCurve}`, keyframes([
                    style({ strokeDashoffset: '17', offset: 0 }),
                    style({ strokeDashoffset: '0', offset: 1.0}),
               ]))
            ]),
        ]),

        // moving the gradient offview
        trigger('gradientMove', [
            transition('* => *', [
                style({
                    transform: 'scale(1, 1)'
                }),
                animate(`${multiply(GRADIENT_DURATION)}ms ${multiply(GRADIENT_DELAY)}ms ${GRADIENT_LEAVE_CURVE}`, style({
                    transform: 'scale(0, 1)'
                }))
            ])
        ]),

        // fade in the dismiss icon
        trigger('dismissIconVisible', [
            transition('* => *', [
                style({
                    opacity: '0'
                }),
                animate(`${multiply(DISMISS_ICON_DURATION)}ms ${multiply(DISMISS_ICON_DELAY)}ms ${DISMISS_ICON_CURVE}`, style({
                    opacity: '1'
                }))
            ])
        ]),
    ]
})
export class VmwSnackComponent {
    @Input() timeoutSeconds: number = AUTODISMISS_TIMEOUT_SECONDS;
    @Input() snackLinkText: string;
    @Input() snackLinkModifierKeycode: number = CTRL_MODIFIER_KEYCODE;
    @Input() snackLinkKeyCode: number = DEFAULT_LINK_KEYCODE;
    @Output() dismissed = new EventEmitter<null>();
    @Output() snackLinkClicked = new EventEmitter();

    public loaded = true;

    private timer: Subscription;

    constructor(private ngZone: NgZone, @Optional() private segmentService: VmwSegmentService) {}

    ngOnInit() {
        if (this.timeoutSeconds <= 0) {
            return;
        }

        this.runTimerOutsideNgZone();
    }

    @HostListener('click')
    trackClicks() {
        if (!!this.segmentService) {
            this.segmentService.trackEvent('NGX_Snackbar_Clicked');
        }
    }

    mouseOver(over: boolean) {
        // If the user moves their mouse over the snack, disable auto-dismiss,
        // If they move the mouse out again, re-enable it.
        if (over && this.timer) {
            this.timer.unsubscribe();
        } else if (this.timeoutSeconds > 0) {
            this.runTimerOutsideNgZone();
        }
    }

    keyDown(event: KeyboardEvent) {
        const modifierMatch =
            (this.snackLinkModifierKeycode === CTRL_MODIFIER_KEYCODE && event.ctrlKey) ||
            (this.snackLinkModifierKeycode === ALT_MODIFIER_KEYCODE && event.altKey) ||
            (this.snackLinkModifierKeycode === META_MODIFIER_KEYCODE && event.metaKey) ||
            !this.snackLinkModifierKeycode;

        // Trigger the link click if it matches
        event.keyCode === this.snackLinkKeyCode && modifierMatch ? this.snackLinkClicked.emit() : true;

        // If user is tabbing around, disable the auto-dismiss
        if (event.keyCode === TAB_KEYCODE) {
            this.timer.unsubscribe();

        } else if (event.keyCode === ESC_KEYCODE) {
            this.dismiss();
        }

        return true;
    }

    dismiss() {
        this.loaded = false;

        // before we tell the app to remove the snack, give the leave animation
        // some time to run...
        timer(multiply(componentPrimaryLeaveTiming)).pipe(take(1)).subscribe(() => {
            this.dismissed.emit();
        });
    }

    private runTimerOutsideNgZone() {
        // This is needed so that we can easily test
        // the snackbar in our e2e tests.
        // For more info please check:
        // https://github.com/angular/protractor/blob/master/docs/timeouts.md

        this.ngZone.runOutsideAngular(() => {
            this.timer = timer(this.timeoutSeconds * 1000).pipe(take(1)).subscribe(() => {
                this.ngZone.run(() => {
                    this.dismiss();
                });
            });
        });
    }
}
