// Generated at 2018-08-03T19:56:17.068Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXErrorResponse } from '../../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class StringService {
    public static instance: StringService;


    constructor(public config: TangoServiceConfiguration) {

        if (!StringService.instance) {
            StringService.instance = this;
        }
    }

    convertMarkdownString(headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.post('/apix/utils/convertmarkdown/string', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
