export const TRANSLATIONS = {
    "en": {
        "icon-failure": "Icon shows failed",
        "icon-success": "Icon shows success",
        "number": "At least one number",
        "length": "At least {0} and at most {1} characters ({2})",
        "special-char": "At least one special character ({0})",
        "upper-case": "At least one uppercase",
        "lower-case": "At least one lowercase",
        "high-ascii": "No high ASCII characters",
        "toggle-visibility": "Toggle visibility",
    },
    "de": {
        "icon-failure": "Symbol Fehler",
        "icon-success": "Symbol Erfolg",
        "number": "Mindestens eine Zahl",
        "length": "Mindestens {0} und hÃ¶chstens {1} Zeichen ({2})",
        "special-char": "Mindestens ein Sonderzeichen ({0})",
        "upper-case": "Mindestens ein GroÃbuchstabe",
        "lower-case": "Mindestens ein Kleinbuchstabe",
        "high-ascii": "Keine High-ASCII-Zeichen",
        "toggle-visibility": "Kennwortanzeige ein/aus",
    },
    "es": {
        "icon-failure": "Icono de error",
        "icon-success": "Icono de Ã©xito",
        "high-ascii": "Sin caracteres ASCII altos",
        "lower-case": "Al menos un carÃ¡cter en minÃºscula",
        "number": "Al menos un nÃºmero",
        "length": "{0} caracteres como mÃ­nimo y {1} como mÃ¡ximo",
        "special-char": "Al menos un carÃ¡cter especial ({0})",
        "upper-case": "Al menos un carÃ¡cter en mayÃºscula",
        "toggle-visibility": "Alternar vista de contraseÃ±a",
    },
    "fr": {
        "icon-failure": "Ãchec de l'icÃ´ne",
        "icon-success": "IcÃ´ne rÃ©ussie",
        "high-ascii": "Aucun caractÃ¨re ASCII Ã©tendu",
        "lower-case": "Au moins une minuscule",
        "number": "Au moins un chiffre",
        "length": "{0} caractÃ¨res au minimum et {1} caractÃ¨res au maximum",
        "special-char": "Au moins un caractÃ¨re spÃ©cial ({0})",
        "upper-case": "Au moins une majuscule",
        "toggle-visibility": "Basculer vers la vue de mot de passe",
    },
    "ja": {
        "icon-failure": "ã¢ã¤ã³ã³ã®å¤±æ",
        "icon-success": "ã¢ã¤ã³ã³ã®æå",
        "high-ascii": "æ¡å¼µ ASCII æå­ãå«ã¾ãªã",
        "lower-case": "å°æå­ã 1 ã¤ä»¥ä¸",
        "number": "æ°å­ã 1 ã¤ä»¥ä¸",
        "length": "{0} æå­ä»¥ä¸ã{1} æå­ä»¥å",
        "special-char": "ç¹æ®æå­ã 1 ã¤ä»¥ä¸ ({0})",
        "upper-case": "å¤§æå­ã 1 ã¤ä»¥ä¸",
        "toggle-visibility": "ãã¹ã¯ã¼ã ãã¥ã¼ã®åãæ¿ã",
    },
    "ko": {
        "icon-failure": "ìì´ì½ ì¤í¨",
        "icon-success": "ìì´ì½ ì±ê³µ",
        "high-ascii": "ìì ASCII ë¬¸ì ìì",
        "lower-case": "ìë¬¸ì íë ì´ì",
        "number": "ì«ì íë ì´ì",
        "length": "{0} ~ {1}ì",
        "special-char": "í¹ì ë¬¸ì({0}) íë ì´ì",
        "upper-case": "ëë¬¸ì íë ì´ì",
        "toggle-visibility": "ìí¸ ë³´ê¸° ì í",
    },
    "zh_TW": {
        "icon-failure": "å¤±æåç¤º",
        "icon-success": "æååç¤º",
        "high-ascii": "ç¡é«ä½å ASCII å­å",
        "lower-case": "è³å°ä¸åå°å¯«å­å",
        "number": "è³å°ä¸åæ¸å­",
        "password-length": "å­åæ¸ç®ä¸éçº {0} åï¼ä¸éçº {1} å",
        "special-char": "è³å°ä¸åç¹æ®å­å ({0})",
        "upper-case": "è³å°ä¸åå¤§å¯«å­å",
        "toggle-visibility": "åæå¯ç¢¼è¦å",
    },
    "zh_CN": {
        "icon-failure": "å¤±è´¥å¾æ ",
        "icon-success": "æåå¾æ ",
        "high-ascii": "æ é«ä½ ASCII å­ç¬¦",
        "lower-case": "è³å°ä¸ä¸ªå°åå­ç¬¦",
        "number": "è³å°ä¸ä¸ªæ°å­",
        "length": "æå° {0} ä¸ªå­ç¬¦ï¼æå¤ {1} ä¸ªå­ç¬¦",
        "special-char": "è³å°ä¸ä¸ªç¹æ®å­ç¬¦ ({0})",
        "upper-case": "è³å°ä¸ä¸ªå¤§åå­ç¬¦",
        "toggle-visibility": "åæ¢å¯ç è§å¾",
    },
    "it": {
        "icon-failure": "L'icona indica che l'operazione non Ã¨ riuscita",
        "icon-success": "L'icona indica che l'operazione Ã¨ riuscita",
        "number": "Almeno un numero",
        "length": "Almeno {0} e al massimo {1} caratteri ({2})",
        "special-char": "Almeno un carattere speciale ({0})",
        "upper-case": "Almeno una lettera maiuscola",
        "lower-case": "Almeno una lettera minuscola",
        "high-ascii": "Nessun carattere ASCII esteso",
        "toggle-visibility": "Attiva/Disattiva visibilitÃ ",
    },
    "nl": {
        "icon-failure": "Pictogram weergeven mislukt",
        "icon-success": "Pictogram weergeven geslaagd",
        "number": "Ten minste Ã©Ã©n getal",
        "length": "Ten minste {0} en maximaal {1} tekens ({2})",
        "special-char": "Ten minste Ã©Ã©n speciaal teken ({0})",
        "upper-case": "Ten minste Ã©Ã©n hoofdletter",
        "lower-case": "Ten minste Ã©Ã©n kleine letter",
        "high-ascii": "Geen hoge ASCII-tekens",
        "toggle-visibility": "Zichtbaarheid in-/uitschakelen",
    },
    "pt": {
        "icon-failure": "O Ã­cone mostra falha",
        "icon-success": "O Ã­cone mostra Ãªxito",
        "number": "Pelo menos um nÃºmero",
        "length": "Pelo menos {0} e no mÃ¡ximo {1} caracteres ({2})",
        "special-char": "Pelo menos um caractere especial ({0})",
        "upper-case": "Pelo menos uma letra maiÃºscula",
        "lower-case": "Pelo menos uma letra minÃºscula",
        "high-ascii": "Nenhum caractere ASCII alto",
        "toggle-visibility": "Alternar visibilidade",
    },
    "ru": {
        "icon-failure": "ÐÑÐ¸Ð±ÐºÐ° Ð¾ÑÐ¾Ð±ÑÐ°Ð¶ÐµÐ½Ð¸Ñ Ð·Ð½Ð°ÑÐºÐ°",
        "icon-success": "ÐÐ½Ð°ÑÐ¾Ðº Ð¾ÑÐ¾Ð±ÑÐ°Ð¶Ð°ÐµÑÑÑ",
        "number": "Ð¥Ð¾ÑÑ Ð±Ñ Ð¾Ð´Ð½Ð° ÑÐ¸ÑÑÐ°",
        "length": "ÐÐµ Ð¼ÐµÐ½ÐµÐµ {0} Ð¸ Ð½Ðµ Ð±Ð¾Ð»ÐµÐµ {1} ÑÐ¸Ð¼Ð²Ð¾Ð»Ð¾Ð² ({2})",
        "special-char": "Ð¥Ð¾ÑÑ Ð±Ñ Ð¾Ð´Ð¸Ð½ ÑÐ¿ÐµÑÐ¸Ð°Ð»ÑÐ½ÑÐ¹ ÑÐ¸Ð¼Ð²Ð¾Ð» ({0})",
        "upper-case": "Ð¥Ð¾ÑÑ Ð±Ñ Ð¾Ð´Ð½Ð° Ð·Ð°Ð³Ð»Ð°Ð²Ð½Ð°Ñ Ð±ÑÐºÐ²Ð°",
        "lower-case": "Ð¥Ð¾ÑÑ Ð±Ñ Ð¾Ð´Ð½Ð° ÑÑÑÐ¾ÑÐ½Ð°Ñ Ð±ÑÐºÐ²Ð°",
        "high-ascii": "ÐÐµÐ· ÑÐ¸Ð¼Ð²Ð¾Ð»Ð¾Ð² Ð¸Ð· ÑÐ°ÑÑÐ¸ÑÐµÐ½Ð½Ð¾Ð¹ ASCII",
        "toggle-visibility": "ÐÐµÑÐµÐºÐ»ÑÑÐ¸ÑÑ Ð¾ÑÐ¾Ð±ÑÐ°Ð¶ÐµÐ½Ð¸Ðµ",
    },
};
