// Generated at 2018-08-03T19:56:16.917Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { ApisService } from './Apis';
import { ManifestsService } from './Manifests';
import { ProductsService } from './Products';
import { ReleasesService } from './Releases';
import { SdksService } from './Sdks';
import { SearchService } from './Search';
import { UtilsService } from './Utils';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ApixService {
    public static instance: ApixService;

    public Apis: ApisService;
    public Manifests: ManifestsService;
    public Products: ProductsService;
    public Releases: ReleasesService;
    public Sdks: SdksService;
    public Search: SearchService;
    public Utils: UtilsService;

    constructor(public config: TangoServiceConfiguration) {
        this.Apis = new ApisService(this.config);
        this.Manifests = new ManifestsService(this.config);
        this.Products = new ProductsService(this.config);
        this.Releases = new ReleasesService(this.config);
        this.Sdks = new SdksService(this.config);
        this.Search = new SearchService(this.config);
        this.Utils = new UtilsService(this.config);

        if (!ApixService.instance) {
            ApixService.instance = this;
        }
    }

}
