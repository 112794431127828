import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { CommonModule } from "@angular/common";

import { FormsModule } from "@angular/forms";

import { VmwSimpleTranslateModule } from "@vmw/ngx-utils";

import { VmwPasswordComplexityCheckerComponent } from "./password-complexity-checker.component";

@NgModule({
    declarations: [
        VmwPasswordComplexityCheckerComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        VmwSimpleTranslateModule,
    ],
    exports: [
        VmwPasswordComplexityCheckerComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class VmwPasswordComplexityCheckerModule {}
