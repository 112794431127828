import { QueryList, ViewChild, ContentChildren, Component, Input } from "@angular/core";

import { Subscription } from "rxjs";

import { VmwGrandProgressBarSectionComponent } from "./grand-progress-bar-section.component";

@Component({
    selector: "vmw-grand-progress-bar",
    templateUrl: "./grand-progress-bar.component.html",
    styleUrls: ["./grand-progress-bar.component.scss"]
})
export class VmwGrandProgressBarComponent {
    @ContentChildren(VmwGrandProgressBarSectionComponent) sections: QueryList<VmwGrandProgressBarSectionComponent>;
    @ViewChild('remainingSection', { static: false }) remainingSection: VmwGrandProgressBarSectionComponent;

    @Input() height: number = 40;
    @Input() animate: boolean = false;

    public remainingWidth = 100;

    private sectionChangeSubscriptions: Array<Subscription> = [];

    private calculateRemaining() {
        let width = 0;

        this.sections.forEach((section: VmwGrandProgressBarSectionComponent) => {
            width += section.width;
        });

        this.remainingWidth = 100 - width;
    }

    ngAfterViewInit() {
        if (this.animate) {
            setTimeout(() => {
                this.remainingSection.transition = "width 1s ease";
            });
        }
    }

    ngOnChanges() {
        this.toggleAnimation(this.animate);
    }

    private toggleAnimation(animate: boolean) {
        let transition = "width 1s ease";

        if (!animate) {
            transition = "none";
        }

        setTimeout(() => {
            if (this.remainingSection) {
                this.remainingSection.transition = transition;
            }
        });

        if (this.sections) {
            this.sections.forEach((section: VmwGrandProgressBarSectionComponent) => {
                setTimeout(() => {
                    section.transition = transition;
                });
            });
        }
    }

    ngAfterContentInit() {
        this.calculateRemaining();

        this.sections.changes.subscribe((changes: any) => {
            this.sectionChangeSubscriptions.forEach(s => s.unsubscribe());

            this.sections.forEach((section: VmwGrandProgressBarSectionComponent) => {
                this.sectionChangeSubscriptions.push(section.widthChanged.subscribe(() => {
                    this.calculateRemaining();
                }));
            });

            this.toggleAnimation(this.animate);

            this.calculateRemaining();
        });
    }

    ngOnDestroy() {
        this.sectionChangeSubscriptions.forEach(s => s.unsubscribe());
    }
}
