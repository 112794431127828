// Generated at 2018-08-03T19:56:20.783Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { RequestService } from './Request';
import { SampleExchangeSolution } from '../types/SampleExchangeSolution';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SolutionsService {
    public static instance: SolutionsService;

    public Request: RequestService;

    constructor(public config: TangoServiceConfiguration) {
        this.Request = new RequestService(this.config);

        if (!SolutionsService.instance) {
            SolutionsService.instance = this;
        }
    }

    getSolutions(headers: any = {}): Observable<Array<SampleExchangeSolution> | any> {
        let s = new Subject<Array<SampleExchangeSolution> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeSolution>) => {
            s.next(result.map(item => new SampleExchangeSolution(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/solutions', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateSolution(body: SampleExchangeSolution, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.put('/solutions', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeSolution(id: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/solutions/{id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
