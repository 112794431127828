// tslint:disable


export class APIXErrorResponse {
    public status: number;
    public message: string;
    public stack_trace: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
