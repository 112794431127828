// tslint:disable

import { APIXRelease } from './APIXRelease';

export class APIXProduct {
    public id: number;
    public complete: boolean;
    public name: string;
    public description: string;
    public type: string;
    public brand: string;
    public rm_id: number;
    public releases: Array<APIXRelease>;
    public lastModified: string;
    public updatedBy: string;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.releases) {
            this.releases = this.releases.map(item => new APIXRelease(item));
        }
    }
}
