/*
 * @copyright Copyright VMware, Inc. All rights reserved. VMware Confidential.
 * @license For licensing, see LICENSE.md.
 */

import { fromEvent, ObjectUnsubscribedError, Subject, Subscriber, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/**
 * This subject behaves much like a BehaviorSubject, however the value is
 * backed by a browser storage instance.
 */
export class AbstractStorageSubject<T> extends Subject<T> {

    /**
     * Our hot subscription which keeps our subject alive.
     */
    private eventSubscription: Subscription;

    /**
     * Create a new behavior subject instance.
     *
     * @param _key The storage key.
     * @param storage The storage instance.
     */
    constructor(private _key: string, private storage: Storage) {
        super();

        // Listen to storage events, for changes in other browser windows.
        // This event is then passed to super.next() to bypass our internal storage,
        // as the value will already have been stored by another browser.
        this.eventSubscription = fromEvent(window, 'storage')
            .pipe(
                filter((e: StorageEvent) => e.storageArea === this.storage),
                filter((e: StorageEvent) => e.key === this._key),
                map(() => this.decodedValue())
            )
            .subscribe((value) => super.next(value));
    }

    /**
     * Convenience accessor for the current value.
     *
     * @returns The current value.
     */
    get value(): T | undefined {
        return this.getValue();
    }

    /**
     * Finish usage of this subject.
     */
    public complete() {
        super.complete();

        if (this.eventSubscription && !this.eventSubscription.closed) {
            this.eventSubscription.unsubscribe();
        }
    }

    /**
     * Unsubscribe this subject from its upstream components.
     */
    public unsubscribe() {
        super.unsubscribe();

        if (this.eventSubscription && !this.eventSubscription.closed) {
            this.eventSubscription.unsubscribe();
        }
    }

    /**
     * Return the current value.
     */
    public getValue(): T | undefined {
        if (this.hasError) {
            throw this.thrownError;
        } else if (this.closed) {
            throw new ObjectUnsubscribedError();
        } else {
            return this.decodedValue();
        }
    }

    /**
     * Send the next value, persisting it to storage.
     *
     * @param value The next value.
     */
    public next(value: T): void {
        const v = JSON.stringify(value);
        this.storage.setItem(this._key, v);
        super.next(value);
    }

    /**
     * When subscribed, begin by emitting the current value of the subject.
     *
     * @param subscriber The subscriber.
     * @returns An active subscription.
     */
    public _subscribe(subscriber: Subscriber<T>): Subscription {
        const subscription = super._subscribe(subscriber); // tslint:disable-line
        if (subscription && !(<Subscription> subscription).closed) {
            subscriber.next(this.decodedValue());
        }
        return subscription;
    }

    /**
     * Lifecycle-decoupled value decoder.
     *
     * @returns The type-decoded value from the local storage.
     */
    private decodedValue(): T | undefined {
        const rawValue = this.storage.getItem(this._key);
        if (rawValue === null) {
            return undefined;
        }
        try {
            return JSON.parse(rawValue);
        } catch (e) {
            return undefined;
        }
    }
}
