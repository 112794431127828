import { Injectable } from "@angular/core";

@Injectable()
export class CustomClarityIcons {
    icons: any = {
        "completed-task": '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C5.37258 0 0 5.37258 0 12ZM19.23 7.84C19.7823 8.39228 19.7823 9.28771 19.23 9.84L10.45 18.61L5 13.16C4.44772 12.6077 4.44772 11.7123 5 11.16C5.55228 10.6077 6.44772 10.6077 7 11.16L10.46 14.61L17.23 7.84C17.7823 7.28772 18.6777 7.28772 19.23 7.84Z" fill="#62A420"/>\n' +
            '</svg>',
        "in-progress-task": '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M12 -5.24537e-07C18.6274 -2.34843e-07 24 5.37258 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C5.37258 24 -8.1423e-07 18.6274 -5.24537e-07 12C-2.34843e-07 5.37258 5.37258 -8.1423e-07 12 -5.24537e-07ZM12.3225 16.5C11.9147 16.9168 11.9147 17.5831 12.3225 18C12.5194 18.1969 12.7865 18.3076 13.065 18.3076C13.3435 18.3076 13.6106 18.1969 13.8075 18L19.725 12.06L13.8225 6.1275C13.4083 5.71328 12.7367 5.71328 12.3225 6.1275C11.9083 6.54171 11.9083 7.21328 12.3225 7.6275L15.645 10.95L5.145 10.95C4.5651 10.95 4.095 11.4201 4.095 12C4.095 12.5799 4.5651 13.05 5.145 13.05L15.75 13.05L12.3225 16.5Z" fill="#0079B8"/>\n' +
            '</svg>'

    };

    public load() {
        // Don't import ClarityIcons because it ends up in bundles when we generate
        // builds of Angular Elements. Just assume it's loaded by the app either via
        // an ES6 import or by adding clarity-icons.min.js to the scripts list in angular.json
        const ClarityIcons = (window as any).ClarityIcons;
        ClarityIcons && ClarityIcons.add(this.icons);
    }
}
