export const TRANSLATIONS = {
    'en': {
        'clear': 'Clear all',
        'status': `{0} total, {1} invalid`,
        'tip': 'Addresses can be delimited by comma, space or a new line',
        'remove': 'Remove {0}',
        'valid': 'Valid',
        'invalid': 'Invalid',
    },
    'de': {
        'clear': 'LÃ¶schen',
        'status': '{0} insgesamt, {1} ungÃ¼ltig',
        'tip': 'Adressen kÃ¶nnen durch Komma, Leerzeichen oder Zeilenumbruch voneinander getrennt werden',
    },
    'es': {
        'clear': 'Anula',
        'status': `{0} total, {1} invalido`,
        'tip': 'Las direcciones pueden estar delimitadas por comas, espacios o una nueva lÃ­nea',
    },
    'fr': {
        'clear': 'Effacer',
        'status': 'Total: {0}, non valide: {1}',
        'tip': 'Les adresses peuvent Ãªtre dÃ©limitÃ©es par une virgule, un espace ou une nouvelle ligne',
    },
    'ja': {
        'clear': 'ã¯ãªã¢',
        'status': 'åè¨ï¼ {0}ãç¡å¹ï¼ {1}ã',
        'tip': 'ã¢ãã¬ã¹ã¯ãã«ã³ããã¹ãã¼ã¹ã¾ãã¯æ¹è¡ã§åºåããã¨ãã§ãã¾ãã',
    },
    'ko': {
        'clear': 'ì§ì°ê¸°',
        'status': 'ì´ {0}ê°, {1}ê°ê° ìëª»ë¨.',
        'tip': 'ì£¼ìë ì¼í, ê³µë°± ëë ì ì¤ë¡ êµ¬ë¶í  ì ììµëë¤.',
    },
    'zh_TW': {
        'clear': 'æ¸é¤',
        'status': 'ç¸½è¨ {0}ï¼{1} åç¡æã',
        'tip': 'ä½åå¯ä»¥éé»ãç©ºæ ¼ææ°çä¸è¡åéã',
    },
    'zh_CN': {
        'clear': 'æ¸é¤',
        'status': 'å± {0} ä¸ªï¼{1} ä¸ªæ æã',
        'tip': 'å¯ä»¥ä½¿ç¨éå·ãç©ºæ ¼ææ¢è¡ç¬¦åéå°åã',
    },
    'it': {
        'clear': 'Cancella tutto',
        'status': `{0} totali, {1} non validi`,
        'tip': 'Gli indirizzi possono essere delimitati da virgole o spazi oppure inseriti in una nuova riga',
        'remove': 'Rimuovi {0}',
        'valid': 'Valido',
        'invalid': 'Non valido',
    },
    'nl': {
        'clear': 'Alles wissen',
        'status': `{0} totaal, {1} ongeldig`,
        'tip': 'Adressen kunnen worden gescheiden door een komma, spatie of een nieuwe regel',
        'remove': '{0} verwijderen',
        'valid': 'Geldig',
        'invalid': 'Ongeldig',
    },
    'pt': {
        'clear': 'Limpar tudo',
        'status': `{0} total, {1} invÃ¡lido(s)`,
        'tip': 'Os endereÃ§os podem ser delimitados por vÃ­rgula, espaÃ§o ou uma nova linha',
        'remove': 'Remover {0}',
        'valid': 'VÃ¡lido',
        'invalid': 'InvÃ¡lido',
    },
    'ru': {
        'clear': 'ÐÑÐ¸ÑÑÐ¸ÑÑ Ð²ÑÐµ',
        'status': `ÐÑÐµÐ³Ð¾: {0}, Ð½ÐµÐ´Ð¾Ð¿ÑÑÑÐ¸Ð¼ÑÑ: {1}`,
        'tip': 'ÐÐ´ÑÐµÑÐ° Ð¼Ð¾Ð¶Ð½Ð¾ ÑÐ°Ð·Ð´ÐµÐ»ÑÑÑ Ð·Ð°Ð¿ÑÑÑÐ¼Ð¸, Ð¿ÑÐ¾Ð±ÐµÐ»Ð°Ð¼Ð¸ Ð¸Ð»Ð¸ Ð½Ð¾Ð²Ð¾Ð¹ ÑÑÑÐ¾ÐºÐ¾Ð¹',
        'remove': 'Ð£Ð´Ð°Ð»Ð¸ÑÑ {0}',
        'valid': 'ÐÐ¾Ð¿ÑÑÑÐ¸Ð¼ÑÐ¹',
        'invalid': 'ÐÐµÐ´Ð¾Ð¿ÑÑÑÐ¸Ð¼ÑÐ¹',
    },
};
