// Generated at 2018-08-03T19:56:20.719Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { RequestService } from './Request';
import { SampleService } from './Sample';
import { TypeService } from './Type';
import { SampleExchangeFavorite } from '../types/SampleExchangeFavorite';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class FavoritesService {
    public static instance: FavoritesService;

    public Request: RequestService;
    public Sample: SampleService;
    public Type: TypeService;

    constructor(public config: TangoServiceConfiguration) {
        this.Request = new RequestService(this.config);
        this.Sample = new SampleService(this.config);
        this.Type = new TypeService(this.config);

        if (!FavoritesService.instance) {
            FavoritesService.instance = this;
        }
    }

    getAllFavorites(headers: any = {}): Observable<Array<SampleExchangeFavorite> | any> {
        let s = new Subject<Array<SampleExchangeFavorite> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeFavorite>) => {
            s.next(result.map(item => new SampleExchangeFavorite(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/favorites', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
