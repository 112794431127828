import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";

import { Observable, Observer } from 'rxjs';

import { APIXResource, APIXApiDef, APIXApi, APIXProduct, APIXRelease } from "../api/APIX/types";
import { APIXService } from "../api/APIX";

import 'rxjs'

import {
    TangoServiceConfiguration,
    TangoAngularHttpClientAdapter,
} from '@vmw/tango';

import { GetApiDefsQueryParams } from '../api/APIX/Apix/Apis/Defs';
import { GetProductsQueryParams } from '../api/APIX/Apix/Products';
import { APIXErrorResponse } from '../api/APIX/types';
import { map, flatMap, catchError } from "rxjs/operators";
import {SampleExchangeService} from "../api/SampleExchange";

export const APIX_PRODUCTION_BASE_URL = 'https://apigw.vmware.com/v1/m4/api/dcr/rest';
const APIX_GET_PRODUCT = '/products/{productId}';
const APIX_GET_RELEASE_APIS = '/releases/{releaseId}/provided-apis';

@Injectable()
export class VmwAPIXService {
    private service: APIXService;
    private clientID: string;
    private clientUUID: string;
    private clientUserID: string;
    private serviceBaseUrl: string;
    private authHeader: string;
    private options: any = {};

    constructor(private http: HttpClient) {
        this.setServiceBaseUrl(APIX_PRODUCTION_BASE_URL);
    }

    private setHttpOptions() {
        let headers = new HttpHeaders();

        headers = headers.set('X-VMware-Code-Client', this.clientID);
        headers = headers.set('X-VMware-Code-Client-UUID', this.clientUUID);
        headers = headers.set('X-VMware-Code-User', this.clientUserID);

        if (this.authHeader) {
            headers = headers.set('Authorization',this.authHeader);
        }

        Object.assign(this.options, {
            headers: headers,
        });
    }

    public setServiceBaseUrl(baseUrl: string) {
        if (this.serviceBaseUrl !== baseUrl) {
            this.serviceBaseUrl = baseUrl;
            console.log('Updating APIX base URL:',this.serviceBaseUrl);
        }

        let config: TangoServiceConfiguration = {
            transport: new TangoAngularHttpClientAdapter(
                this.http, baseUrl, this.options)
        };
        this.service = new APIXService(config);
    }

    public setClientID(client: string) {
        this.clientID = client;
        this.setHttpOptions();
    }

    public setClientUUID(uuid: string) {
        this.clientUUID = uuid;
        this.setHttpOptions();
    }

    public setClientUserID(user: string) {
        this.clientUserID = user;
        this.setHttpOptions();
    }

    public setAuthHeader(value: string) {
        this.authHeader = value;
        this.setHttpOptions();
    }

    /* Get product SDKs for the latest release from the code.vmware.com. */
    public getSDKsForProduct(productId: number) {
        return this.service.Apix.Products.Releases.getReleasesByProductId(productId.toString())
        .pipe(
            map((releases: Array<APIXRelease>) => {
                releases.sort(function (a, b) {
                    let dataA = new Date(a.release_date);
                    let dateB = new Date(b.release_date);
                    if (dataA > dateB) return -1;
                    if (dataA < dateB) return 1;
                    return 0;
                });

                let releaseId : number = releases[0].id;

                return this.getSDKsForRelease(releaseId);

            }, (error: any) => {
                return error;
            }),flatMap(r => r));
    }

    /* Get product SDKs from the code.vmware.com for the releaseId */
    public getSDKsForRelease(releaseId: number) {
        return this.service.Apix.Releases.Resources.getReleaseResources(releaseId.toString())
            .pipe(
                map((sdks: Array<APIXResource>) => {
                    return sdks.filter(sdk => sdk.resource_type === "SDK");
                }),catchError((error: any) => {
                    return error;
                })
            );
    }

    /* Get product Docs for the latest release from the code.vmware.com. */
    public getDocsForProduct(productId: number) {
        return this.service.Apix.Products.Releases.getReleasesByProductId(productId.toString())
            .pipe(
                map((releases: Array<APIXRelease>) => {
                    releases.sort(function (a, b) {
                        let dataA = new Date(a.release_date);
                        let dateB = new Date(b.release_date);
                        if (dataA > dateB) return -1;
                        if (dataA < dateB) return 1;
                        return 0;
                    });

                    let releaseId : number = releases[0].id;

                    return this.getDocsForRelease(releaseId);

                }, (error: any) => {
                    return error;
                }),flatMap(r => r));
    }

    /* Get product Docs from the code.vmware.com for the releaseId */
    public getDocsForRelease(releaseId: number) {
        return this.service.Apix.Releases.Resources.getReleaseResources(releaseId.toString())
            .pipe(
                map((docs: Array<APIXResource>) => {
                    return docs.filter(doc => doc.resource_type === "DOC");
                }),catchError((error: any) => {
                    return error;
                }));
    }

    /* Get APIs from the code.vmware.com  */
    public getRemoteApis() {
        return this.service.Apix.Apis.Defs.getApiDefs({} as GetApiDefsQueryParams)
            .pipe(
                map((apis: Array<APIXApiDef>) => {
                    return apis.filter(api => api.api_releases.length > 0);
                }),catchError((error: any) => {
                    return error;
                })
            );
    }

    /* Get VMware products from the code.vmware.com  */
    public getProducts() {
        return this.service.Apix.Products.getProducts({} as GetProductsQueryParams)
            .pipe(
                map((products: Array<APIXProduct>) => {
                    return products.filter(product => product.releases.length > 0);
                }),catchError((error: any) => {
                    return error;
                })
            );
    }

    /* Get VMware APIs included in a given release from the code.vmware.com  */
    public getApisByRelease(releaseId: number) {
        return this.service.Apix.Releases.ProvidedApis.getReleaseProvidedApis(releaseId.toString());
    }

    /* Get VMware product from the code.vmware.com  */
    public getProduct(name: string){
        return this.service.Apix.Products.getProduct(name)
            .pipe(map((error: any) => {
                return error;
            }));
    }
}
