// Generated at 2018-08-03T19:56:20.764Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeRequest } from '../../../../../types/SampleExchangeRequest';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class NameService {
    public static instance: NameService;


    constructor(public config: TangoServiceConfiguration) {

        if (!NameService.instance) {
            NameService.instance = this;
        }
    }

    getRequestsByTag(category: string, name: string, headers: any = {}): Observable<Array<SampleExchangeRequest> | any> {
        let s = new Subject<Array<SampleExchangeRequest> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeRequest>) => {
            s.next(result.map(item => new SampleExchangeRequest(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            category: category,
            name: name,
        };

        let queryParams = {};

        let result = this.config.transport.get('/search/requests/tag/category/{category}/name/{name}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
