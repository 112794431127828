import { Component, Input } from "@angular/core";
import { trigger, state, transition, style, animate } from "@angular/animations";

import { VmwCardCatalogService } from "./card-catalog.service";

@Component({
    selector: "vmw-card-catalog-card",
    templateUrl: "./card-catalog-card.component.html",
    styleUrls: ["./card-catalog-card.component.scss"],
    animations: [
        trigger('animationState', [
            state('true', style({
                transform: 'translateY(0px)',
                opacity: 1
            })),
            transition('void => *', [
                style({transform: 'translateY(24px)', opacity: 0}),
                animate('{{duration}}ms {{delay}}ms ease-out')
            ])
        ])
    ],
})
export class VmwCardCatalogCardComponent<T = any> {
    @Input() index: number = 0;

    constructor(private service: VmwCardCatalogService) {}

    getAnimationState(): any {
        return {
            value: ':enter',
            params: {
                delay: 80 + 50 * this.index,
                duration: 300
            }
        };
    }

}
