// Generated at 2018-08-03T19:56:20.696Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { PointsService } from './Points';
import { SampleExchangeAuthor } from '../../types/SampleExchangeAuthor';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class CommunitiesUserService {
    public static instance: CommunitiesUserService;

    public Points: PointsService;

    constructor(public config: TangoServiceConfiguration) {
        this.Points = new PointsService(this.config);

        if (!CommunitiesUserService.instance) {
            CommunitiesUserService.instance = this;
        }
    }

    getAuthorByCommunitiesId(communitiesUserId: string, headers: any = {}): Observable<SampleExchangeAuthor | object> {
        let s = new Subject<SampleExchangeAuthor | object>();

        let internalSuccessHandler = (result: SampleExchangeAuthor) => {
            s.next(new SampleExchangeAuthor(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            communitiesUserId: communitiesUserId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/authors/communitiesUser/{communitiesUserId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
