import { EventEmitter, HostListener, Output, Component, Input } from '@angular/core';
import { TOOLTIP_POSITIONS } from "./text-clipper.directive";

@Component({
    selector: 'vmw-text-clipper-tooltip',
    template: `
        <a [style.left.px]="x" [style.top.px]="y" role="tooltip"
                aria-haspopup="true" [ngClass]="location" class="tooltip tooltip-md">
            <span class="tooltip-content" [style.width.px]="width">
                {{content}}
            </span>
        </a>
    `,
    styles: [`
        a.tooltip {
            position: absolute;
        }

        .tooltip-content {
            word-break: break-word;
            opacity: 1.0;
            visibility: visible;
        }
    `]
})
export class VmwTextClipperTooltipComponent {
    @Input()
    x: number;

    @Input()
    y: number;

    @Input()
    width: number;

    @Input()
    content: string;

    @Input()
    location = TOOLTIP_POSITIONS.TOP_RIGHT;

    @Output() hoverChange = new EventEmitter<boolean>();

    @HostListener('mouseover')
    mouseOver(event: Event) {
        this.hoverChange.emit(true);
    }

    @HostListener('mouseout')
    mouseOut(event: Event) {
        this.hoverChange.emit(false);
    }

    ngOnDestroy() {
        this.hoverChange.emit(false);
    }

    public triggerHoverEmit(hover: boolean) {
        this.hoverChange.emit(hover);
    }
}
