/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */

import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { VmwEmptyStatePlaceholderComponent } from "./empty-state-placeholder.component";

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        VmwEmptyStatePlaceholderComponent,
    ],
    exports: [
        VmwEmptyStatePlaceholderComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class VmwEmptyStatePlaceholderModule {
}
