// Generated at 2018-08-03T19:56:20.752Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeContribution } from '../../types/SampleExchangeContribution';
import { SampleExchangeSampleJob } from '../../types/SampleExchangeSampleJob';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class AsyncService {
    public static instance: AsyncService;


    constructor(public config: TangoServiceConfiguration) {

        if (!AsyncService.instance) {
            AsyncService.instance = this;
        }
    }

    updateSampleAsync(body: SampleExchangeContribution, headers: any = {}): Observable<SampleExchangeSampleJob | object> {
        let s = new Subject<SampleExchangeSampleJob | object>();

        let internalSuccessHandler = (result: SampleExchangeSampleJob) => {
            s.next(new SampleExchangeSampleJob(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.put('/samples/async', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getContributionAsync(jobId: string, headers: any = {}): Observable<SampleExchangeSampleJob | any> {
        let s = new Subject<SampleExchangeSampleJob | any>();

        let internalSuccessHandler = (result: SampleExchangeSampleJob) => {
            s.next(new SampleExchangeSampleJob(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            jobId: jobId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/samples/async/{jobId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
