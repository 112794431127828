// Generated at 2018-08-03T19:56:20.745Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class VersionService {
    public static instance: VersionService;


    constructor(public config: TangoServiceConfiguration) {

        if (!VersionService.instance) {
            VersionService.instance = this;
        }
    }

    addCategory(id: string, type: string, name: string, version: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
            type: type,
            name: name,
            version: version,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.put('/requests/{id}/category/type/{type}/name/{name}/version/{version}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeCategory(id: string, type: string, name: string, version: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
            type: type,
            name: name,
            version: version,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/requests/{id}/category/type/{type}/name/{name}/version/{version}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
