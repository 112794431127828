// Generated at 2018-08-03T19:56:17.007Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXFormDataMultiPart } from '../../../types/APIXFormDataMultiPart';
import { APIXErrorResponse } from '../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ZipService {
    public static instance: ZipService;


    constructor(public config: TangoServiceConfiguration) {

        if (!ZipService.instance) {
            ZipService.instance = this;
        }
    }

    contributeApiManifestFromZip(body: APIXFormDataMultiPart, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/apix/manifests/zip', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
