import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";

import { VmwRowSelectorService, VmwRowSelectorMode, VmwRowSelectorButtonPosition } from "./row-selector.service";

@Component({
    selector: "vmw-row-selector-row",
    templateUrl: "row-selector-row.component.html",
    styleUrls: ["./row-selector-row.component.scss"]
})
export class VmwRowSelectorRowComponent implements OnInit{
    @Input() value: any;
    @Input() disabled: boolean = false;
    @Input() showAsCard: boolean = false;

    @Output() change: EventEmitter<any> = new EventEmitter<any>();

    public VmwRowSelectorMode = VmwRowSelectorMode;
    public VmwRowSelectorButtonPosition = VmwRowSelectorButtonPosition;

    private _id: number;

    public get id() {
        return this.service.name + '-' + this._id;
    }

    public selected: boolean;

    public get checked() {
        if (this.service.selection instanceof Array) {
            return this.service.selection.indexOf(this.value) !== -1;
        } else {
            return this.service.selection === this.value;
        }
    }

    constructor(public service: VmwRowSelectorService) {
        this._id = this.service.index;
    }

    ngOnInit() {
        this.selected = this.checked;
    }

    selectionChange(event: any) {
        this.selected = event.target.checked;
        this.change.emit(this.value);
    }
}
