import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { VmwSegmentVersionInfo } from './segment-version-info.model';
import { Subscription } from 'rxjs';

const TRACK_URL: string = "https://api.segment.io/v1/track";
const DOMAIN_BLACKLIST: string[] = [
    "vmware-e2e-tests.com",
    "csp.local",
    "vmwareone.com",
    "harakirimail.com",
    "mailsac.com",
];

@Injectable()
export class VmwSegmentService {

    private properties: any = {};
    private versionInfo: VmwSegmentVersionInfo;
    private context: string = '';
    private userId: string;
    private key: string;
    private domainBlackList: string[] = DOMAIN_BLACKLIST;

    constructor(private http: HttpClient) {}

    setKey(key: string) {
        this.key = key;
    }

    blacklistDomain(domain: string) {
        this.domainBlackList.push(domain);
    }

    setProperty(name: string, value: string) {
        this.properties[name] = value;
    }

    setVersionInfo(versionInfo: VmwSegmentVersionInfo) {
        this.versionInfo = versionInfo;
    }

    setContext(searchContext: string) {
        this.context = searchContext;
    }

    setUserId(userId: string) {
        const domain = userId && userId.split('@')[1];

        if (!domain || this.domainBlackList.some(d => d === domain)) {
            console.log('event tracking disabled, domain must be provided and not in blacklist');
            return;
        }

        this.userId = userId;
    }

    trackEvent(eventName: string, props?: any) {
        if (!this.key || !this.userId) {
            return false;
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + this.key
            })
        };

        let properties = props ?
            Object.assign({}, this.properties, props) : this.properties;

        let origin = window && window.location ?
            window.location.origin + window.location.pathname : null;

        if(!properties.origin) {
            properties.origin = origin;
        }

        let body: any = {
            event: eventName,
            properties: properties,
            userId: this.userId,
        };

        if (!properties.context && this.context) {
            properties.context = this.context;
        }

        if(this.versionInfo) {
            body.context = { library: this.versionInfo };
        }

        return this.http.post<any>(TRACK_URL, body, httpOptions)
            .subscribe(response => true);
    }
}
