import {
    Renderer2,
    RendererFactory2,
    ComponentRef,
    ComponentFactoryResolver,
    ViewContainerRef,
    Injectable,
} from '@angular/core';

import { Observable } from 'rxjs';

import { VmwTextClipperTooltipComponent } from './text-clipper-tooltip.component';
import { TOOLTIP_POSITIONS } from "./text-clipper.directive";

@Injectable({
    providedIn: 'root',
})
export class VmwTextClipperDirectiveService {
    private componentRef: ComponentRef<VmwTextClipperTooltipComponent>;
    private renderer: Renderer2;
    private tooltipVisible: boolean = false;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    showTooltip(initialContainer: ViewContainerRef,
                x: number, y: number, width: number, content: string, location?: TOOLTIP_POSITIONS): Observable<boolean> {
        if (this.tooltipVisible) {
            this.hideTooltip();
        }

        const componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(VmwTextClipperTooltipComponent);

        this.componentRef = initialContainer.createComponent(componentFactory);
        let tooltipLocation: TOOLTIP_POSITIONS;

        if (!location) {
            tooltipLocation = TOOLTIP_POSITIONS.TOP_RIGHT;

            // Just do left/right viewport violation checking
            if (x + width >= (window.innerWidth || document.documentElement.clientWidth)) {
                tooltipLocation = TOOLTIP_POSITIONS.TOP_LEFT;
            }
        } else {
            tooltipLocation = location;
        }

        this.componentRef.instance.content = content;
        this.componentRef.instance.width = width;
        this.componentRef.instance.location = tooltipLocation;
        this.componentRef.instance.x = x;
        this.componentRef.instance.y = y;

        // stick the tooltip in the body to avoid clipping
        this.renderer.appendChild(
            document.body,
            this.componentRef.location.nativeElement);

        this.tooltipVisible = true;

        return this.componentRef.instance.hoverChange;
    }

    hideTooltip(force: boolean = false) {
        if (this.componentRef) {
            // force the hover(false) emitter before we remove
            this.componentRef.instance.triggerHoverEmit(false);

            this.renderer.removeChild(
                document.body,
                this.componentRef.location.nativeElement);
        }

        this.tooltipVisible = false;
    }
}
