// Generated at 2018-08-03T19:56:20.739Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeNotificationInstance } from '../../types/SampleExchangeNotificationInstance';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class RequestService {
    public static instance: RequestService;


    constructor(public config: TangoServiceConfiguration) {

        if (!RequestService.instance) {
            RequestService.instance = this;
        }
    }

    getNotificationForRequest(requestId: string, headers: any = {}): Observable<SampleExchangeNotificationInstance | object> {
        let s = new Subject<SampleExchangeNotificationInstance | object>();

        let internalSuccessHandler = (result: SampleExchangeNotificationInstance) => {
            s.next(new SampleExchangeNotificationInstance(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            requestId: requestId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/notifications/request/{requestId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createNotificationForRequest(requestId: string, headers: any = {}): Observable<SampleExchangeNotificationInstance | any> {
        let s = new Subject<SampleExchangeNotificationInstance | any>();

        let internalSuccessHandler = (result: SampleExchangeNotificationInstance) => {
            s.next(new SampleExchangeNotificationInstance(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            requestId: requestId,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.post('/notifications/request/{requestId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteNotificationForRequest(requestId: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            requestId: requestId,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/notifications/request/{requestId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
