import { Injectable } from '@angular/core';
import { VmwTaskGroup, VmwTaskIdentifier, VmwTaskStatus } from './task.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class VmwTaskGroupService {
    private taskGroups: VmwTaskGroup[] = [];
    private lastCompletedTask = new BehaviorSubject<VmwTaskIdentifier>({
        groupIndex: -1,
        taskIndex: -1,
        isLastTask: false
    });

    private taskGroupChangeDetector = new Subject<void>();

    triggerTaskGroupChangeDetector() {
        this.taskGroupChangeDetector.next();
    }

    getTaskGroupChangeDetector(): Observable<void> {
        return this.taskGroupChangeDetector.asObservable();
    }

    set(taskGroups: VmwTaskGroup[]): void {
        this.taskGroups = taskGroups;
        this.setLastCompletedTaskId();
    }

    private setLastCompletedTaskId() {
        let lastCompletedTask = {
            groupIndex: -1,
            taskIndex: -1,
            isLastTask: false
        };
        this.taskGroups.forEach((eachTaskGroups: VmwTaskGroup) => {
            eachTaskGroups.tasks.forEach(eachTasks => {
                if (eachTasks.status !== VmwTaskStatus.NOT_STARTED) {
                    lastCompletedTask = eachTasks.id;
                }
            })
        });
        this.lastCompletedTask.next(lastCompletedTask);
    }

    getLastCompletedTaskId(): Observable<VmwTaskIdentifier> {
        return this.lastCompletedTask.asObservable();
    }
}