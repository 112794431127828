// tslint:disable


export class SampleExchangeDownload {
    public id: number;
    public sampleId: number;
    public created: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
