import {Injectable} from "@angular/core";
import {CurlData} from "./curl-data";

@Injectable()
export class CurlBuilder {

   getCurl(curlData: CurlData): string {
      let parts: string[] = ["curl -X "];
      parts.push(curlData.method);
      parts.push("'" + curlData.url + "'");
      curlData.headers.keys().forEach(function(header) {
         let value: string = curlData.headers.get(header);
         let headerStr: string = `'${header}: ${value}'`;
         parts.push("-H " + headerStr);
      });
      if (curlData.body) {
         parts.push("-d '" + curlData.body + "'");
      }
      return parts.join(" ");
   }
}
