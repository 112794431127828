/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { VmwSplitterLayoutComponent } from "./splitter-layout.component";
import { VmwSplitterDirective } from "./splitter.directive";
import { VmwSplitterService } from "./splitter.service";

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        VmwSplitterLayoutComponent,
        VmwSplitterDirective
    ],
    providers: [
        VmwSplitterService
    ],
    exports: [
        VmwSplitterDirective
    ],
    entryComponents: [
        VmwSplitterLayoutComponent,
    ]
})
export class VmwSplitterModule {
}
