// Generated at 2018-08-03T19:56:20.734Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { FlagTypeService } from './FlagType';
import { FlagTypesService } from './FlagTypes';
import { IdService } from './Id';
import { SampleExchangeFlag } from '../../types/SampleExchangeFlag';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class EntityService {
    public static instance: EntityService;

    public FlagType: FlagTypeService;
    public FlagTypes: FlagTypesService;
    public Id: IdService;

    constructor(public config: TangoServiceConfiguration) {
        this.FlagType = new FlagTypeService(this.config);
        this.FlagTypes = new FlagTypesService(this.config);
        this.Id = new IdService(this.config);

        if (!EntityService.instance) {
            EntityService.instance = this;
        }
    }

    getAllFlagsForEntityType(entityType: string, headers: any = {}): Observable<Array<SampleExchangeFlag> | any> {
        let s = new Subject<Array<SampleExchangeFlag> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeFlag>) => {
            s.next(result.map(item => new SampleExchangeFlag(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            entityType: entityType,
        };

        let queryParams = {};

        let result = this.config.transport.get('/flags/entity/{entityType}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
