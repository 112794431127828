// Generated at 2018-08-03T19:56:20.722Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AtomService } from './Atom';
import { RssService } from './Rss';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class FeedsService {
    public static instance: FeedsService;

    public Atom: AtomService;
    public Rss: RssService;

    constructor(public config: TangoServiceConfiguration) {
        this.Atom = new AtomService(this.config);
        this.Rss = new RssService(this.config);

        if (!FeedsService.instance) {
            FeedsService.instance = this;
        }
    }

}
