// tslint:disable

import { SampleExchangeFileInfo } from './SampleExchangeFileInfo';
import { SampleExchangeCategory } from './SampleExchangeCategory';
import { SampleExchangeTag } from './SampleExchangeTag';

export class SampleExchangeContribution {
    public id: number;
    public active: boolean;
    public name: string;
    public type: string;
    public readmeHtml: string;
    public appendRepositoryReadme: boolean;
    public repositoryReadmeHtml: string;
    public url: string;
    public githubApiUrl: string;
    public branch: string;
    public paths: Array<string>;
    public files: Array<SampleExchangeFileInfo>;
    public categories: Array<SampleExchangeCategory>;
    public tags: Array<SampleExchangeTag>;
    public ossLicense: string;
    public metadata: string;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.files) {
            this.files = this.files.map(item => new SampleExchangeFileInfo(item));
        }
        if (this.categories) {
            this.categories = this.categories.map(item => new SampleExchangeCategory(item));
        }
        if (this.tags) {
            this.tags = this.tags.map(item => new SampleExchangeTag(item));
        }
    }
}
