// Generated at 2018-08-03T19:56:20.760Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeRequest } from '../../../types/SampleExchangeRequest';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class AllService {
    public static instance: AllService;


    constructor(public config: TangoServiceConfiguration) {

        if (!AllService.instance) {
            AllService.instance = this;
        }
    }

    getAllRequests(headers: any = {}): Observable<Array<SampleExchangeRequest> | any> {
        let s = new Subject<Array<SampleExchangeRequest> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeRequest>) => {
            s.next(result.map(item => new SampleExchangeRequest(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/search/requests/all', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
