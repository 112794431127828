import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VmwProgressTrackerComponent } from './progress-tracker.component';
import { VmwProgressTrackerTaskGroupComponent } from './task-group/task-group.component';
import { VmwProgressTrackerTaskComponent } from './progress-tracker-task/progress-tracker-task.component';
import { VmwProgressTrackerTaskGroupDetailsComponent } from './task-group-details/task-group-details.component';
import { VmwProgressTrackerTaskGroupHeaderComponent } from './task-group-header/task-group-header.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        VmwProgressTrackerComponent,
        VmwProgressTrackerTaskGroupComponent,
        VmwProgressTrackerTaskGroupHeaderComponent,
        VmwProgressTrackerTaskGroupDetailsComponent,
        VmwProgressTrackerTaskComponent
    ],
    exports: [
        VmwProgressTrackerComponent,
        VmwProgressTrackerTaskGroupComponent,
        VmwProgressTrackerTaskGroupHeaderComponent,
        VmwProgressTrackerTaskGroupDetailsComponent,
        VmwProgressTrackerTaskComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class VmwNgxProgressTrackerModule {
}