// tslint:disable


export class APIXMediaType {
    public type: string;
    public subtype: string;
    public parameters: Object;
    public wildcardType: boolean;
    public wildcardSubtype: boolean;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
