import { Component, Input } from '@angular/core';

@Component({
    selector: 'vmw-progress-tracker-task-group-details',
    template: `
        <h3 *ngIf="title">{{title}}</h3>
        <ng-content></ng-content>`,
    styles: [':host { display:block;width:100%;}']
})
export class VmwProgressTrackerTaskGroupDetailsComponent {
    @Input() title: string;
}