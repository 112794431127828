import { NgModule } from "@angular/core";

import { VmwMouseMoveTranslateDirective } from './mouse-move-translate.directive';

@NgModule({
    declarations: [
        VmwMouseMoveTranslateDirective,
    ],
    exports: [
        VmwMouseMoveTranslateDirective,
    ],
})
export class VmwMouseMoveTranslateDirectiveModule {
}
