import { Component, Input } from "@angular/core";

import { SampleExchangeSample } from "../api/SampleExchange/types/SampleExchangeSample";

@Component({
    templateUrl: "./code-sample-card.component.html",
    selector: "vmw-code-sample-card",
    styleUrls: ["./code-sample-card.component.scss"],
})
export class VmwCodeSampleCardComponent {
    @Input() sample: SampleExchangeSample;
}
