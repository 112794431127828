import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    Optional,
    ViewChild,
    AfterViewChecked,
    OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';

import { VmwClarityTheme, VmwClarityThemeService } from '@vmw/ngx-utils';
import { VmwSvgIconFormatter, VmwSvgIconColorBrightness } from '@vmw/ngx-utils/icon-formatter';

/**
 * Authors: Rumen Angelov & Ivaylo Zhekov
 *
 * This component is used to visualize svg from string.
 * It dynamically overrides the color of the svg to provided forceColor on demand.
 * It can dynamically switch between original colors and provided color depending on theme
 * if you enable 'forceColorOnDarkThemeOnly'.
 *
 * Note! If 'forceColor' is not explicitly provided, the color won't be overriten!
 * If 'forceColorOnDarkThemeOnly' is true, the icon will have its original colors on Light theme
 * and the provided color on Dark theme.
 * Note! When choose to override the color, it will find each svg constructing element that has 'fill' or
 * 'stroke' applied and will override the color to the provided one. This could lead to some unwanted behaviours. For example
 * If you have black text on colored background, when you override colors you will not see the text since it will have
 * the same color as the background.
 */

export enum VmwSvgIconColorFormat {
    NEVER,
    ON_DARK_THEME,
    ALWAYS
}

@Component({
    selector: 'vmw-svg-icon',
    templateUrl: 'svg-icon.component.html',
    styleUrls: ['svg-icon.component.scss']
})
export class VmwSvgIconComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    private iconFormatter: VmwSvgIconFormatter;
    // string containing svg icon
    @Input()
    set svgIcon(newSvgIcon: string) {
        this.resetFormatter = true;
        this.svgIconData = newSvgIcon;
    }

    // changes the svg size
    @Input()
    set iconSize(value: number) {
        this.applyIconSize = value;
        this.resetFallbackIcon = true;
        this.iconFormatter && this.iconFormatter.setIconSize(value);
    }

    @Input()
    set title(value: string) {
        this.applyTitle = value;
        this.resetFallbackIcon = true;
        this.iconFormatter && this.iconFormatter.setTitle(value);
    }

    @Input('class')
    hostClass?: string;

    // forces color
    @Input()
    set forcedColor(value: string) {
        this.applyForcedColor = value;
        this.changeColorWithTheme();
    }

    @Input()
    set invertColors(value: boolean) {
        this.applyInvert = value;
        this.changeColorWithTheme();
    }

    @Input()
    set grayscale(value: boolean) {
        this.applyGrayscale = value;
        this.changeColorWithTheme();
    }

    @Input()
    set brightness(value: VmwSvgIconColorBrightness) {
        this.applyBrightness = value;
        this.changeColorWithTheme();
    }

    // forces white color when switching to black theme only
    @Input()
    set colorFormat(value: VmwSvgIconColorFormat) {
        this.applyColorFormat = value;
        this.changeColorWithTheme();
    }

    @Input()
    set fallback(value: string) {
        this.applyFallback = value || this.defaultFallback;
        this.resetFallbackIcon = true;
    }

    @ViewChild('vmwSvgIconContainer', { static: false })
    svgContainerElem: ElementRef<HTMLSpanElement>;
    @ViewChild('fallbackElem', { static: false })
    fallbackElem: ElementRef<HTMLSpanElement>;

    public readonly defaultFallback = 'exclamation-triangle';

    svgIconData: string;
    applyIconSize: number;
    resetFormatter: boolean = false;
    applyInvert: boolean = false;
    applyGrayscale: boolean = false;
    applyBrightness: VmwSvgIconColorBrightness = VmwSvgIconColorBrightness.NORMAL;
    applyForcedColor: string;
    applyFallback: string = this.defaultFallback;
    applyColorFormat: VmwSvgIconColorFormat = VmwSvgIconColorFormat.ON_DARK_THEME;
    applyTitle = '';
    resetFallbackIcon: boolean = false;

    subscriptions: Subscription[] = [];
    constructor(@Optional() private themeService: VmwClarityThemeService) { }

    ngOnInit() {
        if (this.themeService) {
            this.subscriptions.push(this.themeService.themeChange.subscribe((test) => {
                if (this.applyColorFormat === VmwSvgIconColorFormat.ON_DARK_THEME) {
                    this.changeColorWithTheme();
                }
            }));
        }
    }

    ngAfterViewInit(): void {
        this.resetFormatter = false;
        this.setIconFormatter();
    }

    ngAfterViewChecked() {
        const hasFallbackIcon = !this.svgIconData && this.applyFallback !== this.defaultFallback
        if (this.resetFallbackIcon && hasFallbackIcon) {
            this.setFallbackFormatter();
            this.resetFallbackIcon = false;
        } else if (this.resetFormatter) {
            this.setIconFormatter();
            this.resetFormatter = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    setFallbackFormatter(){
        const fallbackContainer: HTMLElement = this.fallbackElem && this.fallbackElem.nativeElement;
        if (fallbackContainer && !this.iconFormatter) {
            this.iconFormatter = new VmwSvgIconFormatter(null, this.svgIconData, this.applyTitle, this.applyIconSize);
        }
        this.iconFormatter.setFallbackElement(fallbackContainer);
        this.changeColorWithTheme();
    }

    async setIconFormatter() {
        const svgElem: HTMLElement = this.svgContainerElem && this.svgContainerElem.nativeElement;

        if (svgElem) {
            this.iconFormatter = new VmwSvgIconFormatter(svgElem, this.svgIconData, this.applyTitle, this.applyIconSize);

            try {
                await this.iconFormatter.svgLoaded;
            } catch(error) {
                // fallback to fallback clarity icon on load failure
                this.svgIconData = null;
                this.resetFallbackIcon = true;
            }

            this.changeColorWithTheme();
        }
    }

    /**
     * Changes the 'fill' and 'stroke' to white when theme changed to Dark
     * and reset to original colors when theme is changed to Light
     * If the 'colorFormat' parameter is not set to NEVER and the theme is Dark,
     * host element invert colors and gray-scale filter will be applied
     */
    private changeColorWithTheme(): void {
        if (this.iconFormatter) {
            const isDarkTheme = this.themeService && this.themeService.theme === VmwClarityTheme.Dark;
            const handleDarkTheme = isDarkTheme && this.applyColorFormat === VmwSvgIconColorFormat.ON_DARK_THEME;
            const shouldApplyColorFormatting = handleDarkTheme || this.applyColorFormat === VmwSvgIconColorFormat.ALWAYS;
            const shouldResetColorFormatting = this.applyColorFormat === VmwSvgIconColorFormat.NEVER || !shouldApplyColorFormatting;
            const shouldApplyForcedColor = shouldApplyColorFormatting && !!this.applyForcedColor;

            if(shouldApplyColorFormatting) {
                this.iconFormatter.setColorFilters(this.applyInvert, this.applyGrayscale, this.applyBrightness);
            }

            if (shouldResetColorFormatting) {
                this.iconFormatter.resetColorFormatting();
            } else if (shouldApplyForcedColor) {
                this.iconFormatter.setColor(this.applyForcedColor);
            } else if (!this.forcedColor) {
                this.iconFormatter.resetColors();
            }

        }
    }
}
