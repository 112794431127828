// Generated at 2018-08-03T19:56:17.007Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { ZipService } from './Zip';
import { APIXApiManifest } from '../../types/APIXApiManifest';
import { APIXErrorResponse } from '../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ManifestsService {
    public static instance: ManifestsService;

    public Zip: ZipService;

    constructor(public config: TangoServiceConfiguration) {
        this.Zip = new ZipService(this.config);

        if (!ManifestsService.instance) {
            ManifestsService.instance = this;
        }
    }

    contributeApiManifest(body: APIXApiManifest, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/apix/manifests', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getApiManifestsForBuild(official_build_number: string, headers: any = {}): Observable<Array<APIXApiManifest> | any> {
        let s = new Subject<Array<APIXApiManifest> | any>();

        let internalSuccessHandler = (result: Array<APIXApiManifest>) => {
            s.next(result.map(item => new APIXApiManifest(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            official_build_number: official_build_number,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/manifests/{official_build_number}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
