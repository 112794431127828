// tslint:disable


export class APIXParameterizedHeader {
    public value: string;
    public parameters: Object;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
