import { Directive, ElementRef,  HostListener, HostBinding, Input } from '@angular/core';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';

/* vmwMouseMoveTranslate directive
 *
 * Use this directive to add a translation to an element based on the document's
 * mouse location. This can be used to achieve a paralax effect in SVG images by
 * applying different adjustments to different elements of the image. For example,
 * provide larger adjustment numbers to apply smaller translations to background
 * elements, and smaller adjustment numbers to foreground elements.
 *
 * As an example, take a look at src/csp-ngx-components/error-page/stop.ts
 */

@Directive({
    selector: '[vmwMouseMoveTranslate]'
})
export class VmwMouseMoveTranslateDirective {
    @HostListener('document:mousemove', ['$event'])
    mouseMove(event: MouseEvent) {
        this.transform = this.getTransform(event.pageX, event.pageY);
    }

    @HostBinding('attr.style')
    transform: SafeStyle;

    @Input() adjustX: number = 0;
    @Input() adjustY: number = 0;

    constructor(private elem: ElementRef, private sanitizer: DomSanitizer) {}

    private getTransform(mouseX: number, mouseY: number): SafeStyle {
        const rect = this.elem.nativeElement.getBoundingClientRect();

        const elemCenterX = rect.left + (rect.width / 2);
        const elemCenterY = rect.top + (rect.height / 2);

        let distanceX = (mouseX - elemCenterX) / this.adjustX;
        let distanceY = (mouseY - elemCenterY) / this.adjustY;

        return this.sanitizer.bypassSecurityTrustStyle(
            `transform: translate(${distanceX}px, ${distanceY}px);
             transition: all 1.5s cubic-bezier(0,1.55,.8,1.64);`);
    }
}
