import { TemplateRef, ViewContainerRef, Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";

import { VmwCardCatalogCardComponent } from "./card-catalog-card.component";

@Injectable()
export class VmwCardCatalogService<T = any> {
    public count: number = 0;

    public clearCards() {
        this._clear.next(null);
    }

    private _clear = new Subject<null>();
    public get clear(): Observable<null> {
        return this._clear.asObservable();
    }

    public addCard(card: T) {
        this.count++;
        this._added.next(card);
    }

    private _added = new Subject<T>();
    public get added(): Observable<T> {
        return this._added.asObservable();
    }

    public removeCard(card: T) {
        this.count--;
        this._removed.next(card);
    }

    private _removed = new Subject<T>();
    public get removed(): Observable<T> {
        return this._removed.asObservable();
    }
}
