import { NgForOf, NgForOfContext } from '@angular/common';
import {
    Directive,
    DoCheck,
    Input,
    IterableDiffer,
    IterableDiffers,
    TemplateRef,
    TrackByFunction,
    ViewContainerRef,
    OnDestroy,
    IterableChangeRecord,
    EmbeddedViewRef,
} from '@angular/core';

import { VmwCardCatalogService } from "./card-catalog.service";

@Directive({
    selector: '[vmwCardCatalogItems][vmwCardCatalogItemsOf]',
})
export class VmwCardCatalogIteratorDirective<T> {
    private iterableProxy: NgForOf<T>;
    private _rawItems: T[];
    private differ: IterableDiffer<T> | null = null;
    private currentCount: number = 0;

    @Input('vmwCardCatalogItemsOf')
    public set rawItems(items: T[]) {
        this._rawItems = items ? items : [];
        this.service.clearCards();
        this.iterableProxy.ngForOf = this._rawItems;
        this.iterableProxy.ngDoCheck();
        this.currentCount = 0;
    }

    @Input('vmwCardCatalogItemsTrackBy')
    set trackBy(value: TrackByFunction<T>) {
        this.iterableProxy.ngForTrackBy = value;
    }

  @Input()
  set vmwCardCatalogItemsTemplate(value: TemplateRef<NgForOfContext<T>>) {
    if (value) {
      this.template = value;
    }
  }

    constructor(public template: TemplateRef<NgForOfContext<T>>,
                private differs: IterableDiffers,
                private service: VmwCardCatalogService,
                private vcr: ViewContainerRef) {
        this.iterableProxy = new NgForOf<T>(this.vcr, this.template, this.differs);
    }

    ngDoCheck() {
        if (!this.differ) {
            this.differ = this.differs.find(this._rawItems).create(this.iterableProxy.ngForTrackBy);
        }

        if (this.differ) {
            const changes = this.differ.diff(this._rawItems);
            if (changes) {
                let cardsAdded: any[] = [];
                let cardsRemoved: any[] = [];

                changes.forEachOperation(
                    (item: IterableChangeRecord<T>, adjustedPreviousIndex: number, currentIndex: number) => {
                        if (item.previousIndex === null) {
                            const view = this.vcr.createEmbeddedView(
                                this.template, new NgForOfContext<T>(null !, this._rawItems, -1, -1), currentIndex);
                            view.context.$implicit = item.item;
                            // set the index on the card component
                            let component = (view as any)._lView[(view as any)._lView.length - 1];
                            if (component) {
                                component.index = currentIndex - this.currentCount;
                            }
                            this.service.addCard(view);
                        } else if (currentIndex == null) {
                            let removed = this.vcr.get(adjustedPreviousIndex);
                            this.vcr.remove(adjustedPreviousIndex);
                            this.service.removeCard(removed);
                        } else {
                            const view = this.vcr.get(adjustedPreviousIndex) !;
                            (view as EmbeddedViewRef<NgForOfContext<T>>).context.$implicit = item.item;
                            this.vcr.move(view, currentIndex);
                        }
                    });

                for (let i = 0, ilen = this.vcr.length; i < ilen; i++) {
                    const viewRef = <EmbeddedViewRef<NgForOfContext<T>>>this.vcr.get(i);
                    viewRef.context.index = i;
                    viewRef.context.count = ilen;
                    viewRef.context.ngForOf = this._rawItems;
                }

                changes.forEachIdentityChange((record: any) => {
                    const viewRef =
                        <EmbeddedViewRef<NgForOfContext<T>>>this.vcr.get(record.currentIndex);
                    viewRef.context.$implicit = record.item;
                });

                this.currentCount = this.service.count;
            }
        }
    }
}
