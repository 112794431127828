// tslint:disable

import { APIXContentDisposition } from './APIXContentDisposition';
import { APIXMediaType } from './APIXMediaType';
import { APIXMessageBodyWorkers } from './APIXMessageBodyWorkers';
import { APIXMultiPart } from './APIXMultiPart';
import { APIXProviders } from './APIXProviders';
import { APIXFormDataContentDisposition } from './APIXFormDataContentDisposition';

export class APIXFormDataBodyPart {
    public contentDisposition: APIXContentDisposition;
    public entity: Object;
    public headers: Object;
    public mediaType: APIXMediaType;
    public messageBodyWorkers: APIXMessageBodyWorkers;
    public parent: APIXMultiPart;
    public providers: APIXProviders;
    public formDataContentDisposition: APIXFormDataContentDisposition;
    public simple: boolean;
    public name: string;
    public value: string;
    public parameterizedHeaders: Object;
    constructor(json: any) {
        Object.assign(this, json);
        this.contentDisposition = new APIXContentDisposition(this.contentDisposition);
        this.mediaType = new APIXMediaType(this.mediaType);
        this.messageBodyWorkers = new APIXMessageBodyWorkers(this.messageBodyWorkers);
        this.parent = new APIXMultiPart(this.parent);
        this.providers = new APIXProviders(this.providers);
        this.formDataContentDisposition = new APIXFormDataContentDisposition(this.formDataContentDisposition);
    }
}
