// Generated at 2018-08-03T19:56:20.771Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AllService } from './All';
import { AuthorService } from './Author';
import { CategoryService } from './Category';
import { NameService } from './Name';
import { TagService } from './Tag';
import { SampleExchangeSample } from '../../types/SampleExchangeSample';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class SearchSamplesQueryParams {
    platform?: string;
    tag?: string;
    keyword?: string;
    req_platform?: string;
    req_tag?: string;
    req_keyword?: string;
    modified?: string;
    days?: string;
    summary?: string;
}

export class SamplesService {
    public static instance: SamplesService;

    public All: AllService;
    public Author: AuthorService;
    public Category: CategoryService;
    public Name: NameService;
    public Tag: TagService;

    constructor(public config: TangoServiceConfiguration) {
        this.All = new AllService(this.config);
        this.Author = new AuthorService(this.config);
        this.Category = new CategoryService(this.config);
        this.Name = new NameService(this.config);
        this.Tag = new TagService(this.config);

        if (!SamplesService.instance) {
            SamplesService.instance = this;
        }
    }

    searchSamples(queryParams: SearchSamplesQueryParams, headers: any = {}): Observable<Array<SampleExchangeSample> | any> {
        let s = new Subject<Array<SampleExchangeSample> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeSample>) => {
            s.next(result.map(item => new SampleExchangeSample(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };


        let result = this.config.transport.get('/search/samples', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getSampleById(id: string, headers: any = {}): Observable<SampleExchangeSample | object> {
        let s = new Subject<SampleExchangeSample | object>();

        let internalSuccessHandler = (result: SampleExchangeSample) => {
            s.next(new SampleExchangeSample(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/search/samples/{id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
