import {
    Input,
    ViewEncapsulation,
    Component,
    ElementRef,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { Observable } from "rxjs";
import {
    VmwTasksService,
    VmwTask,
    VmwTaskState
} from "./tasks.service";
import { map } from "rxjs/operators";

import {
    trigger,
    group,
    style,
    state,
    animate,
    transition,
    query,
    animateChild,
} from '@angular/animations';

import {
    multiply,
    componentPrimaryEnterCurve,
    componentPrimaryEnterTiming,
    componentPrimaryLeaveCurve,
    componentPrimaryLeaveTiming,
} from "../animation-constants";

interface IViewModel {
    tasks: VmwTask[];
    runningTasks: number;
}

const AUTODISMISS_TIMEOUT_SECONDS = 6;

@Component({
    selector: "vmw-task-panel",
    templateUrl: "./task-panel.component.html",
    styleUrls: ["./task-panel.component.scss"],
    animations: [
        trigger('launchTask', [
            // ':enter' is a default state for ngIf and ngFor, doesn't need to be predefined
            transition(':enter', [
                group([
                    // initial state
                    style({
                        // use translate for better performance than position:absolute / left,right, width
                        transform: 'translateX(48px) scale(0, 1)'
                    }),

                    // visible
                    animate(`${multiply(componentPrimaryEnterTiming)}ms ${componentPrimaryEnterCurve}`, style({
                        transform: 'translateX(0) scale(1, 1)'
                    })),
                ]),
            ]),

            transition(':leave', [
                group([
                    style({
                        transform: 'translateX(0) scale(1, 1)'
                    }),
                    animate(`${multiply(componentPrimaryLeaveTiming)}ms ${componentPrimaryLeaveCurve}`, style({
                        transform: 'translateX(48px) scale(0, 1)'
                    })),
                ])
            ]),
        ]),

        // after a task auto-dismisses collapse the container
        trigger("minimize", [
            transition("* => *", [
                style({
                    height: '*'
                }),
                animate(`${multiply(componentPrimaryEnterTiming)}ms ${componentPrimaryEnterCurve}`, style({
                    height: '*'
                })),
            ])
        ]),

        // collapsed state animation
        trigger("collapse", [
            transition("open => collapsed", [
                group([
                    style({
                        height: '{{startHeight}}px',
                    }),
                    animate(`${multiply(componentPrimaryEnterTiming)}ms ${componentPrimaryEnterCurve}`), style({
                        height: '48px'
                    }),
                    query('.caret', animateChild(), {optional: true}),
                    query('.progress', animateChild(), {optional: true}),
                ]),
            ]),

            transition("collapsed => open", [
                group([
                    style({
                        height: '48px'
                    }),
                    animate(`${multiply(componentPrimaryEnterTiming)}ms ${componentPrimaryEnterCurve}`, style({
                        height: '*'
                    })),
                    query('.caret', animateChild(), {optional: true}),
                    query('.progress', animateChild(), {optional: true}),
                ])
            ]),
        ]),

        // rotate caret animation
        trigger('rotateCaret', [

            state('open', style({ transform: 'rotate(-180deg)' })),
            state('collapsed', style({ transform: 'rotate(0deg)' })),

            transition('open => collapsed', animate(`${multiply(250)}ms ${multiply(0)}ms`)),
            transition('collapsed => open', animate(`${multiply(250)}ms ${multiply(0)}ms`)),
        ]),
    ]
})
export class VmwTaskPanelComponent {
    @ViewChildren('panel', {read: ElementRef}) panel: ElementRef;

    /**
     * Enable / Disable dismiss feature:
     * Enable: will show dismiss button and filter out dismissed tasks.
     * Disable: will hide dismiss button and won't filter the dismissed tasks.
     */
    @Input()
    dismissable = true;

    @Input()
    dismissTimeout: number = AUTODISMISS_TIMEOUT_SECONDS * 1000;

    private _inCancelConfirm = false;
    get inCancelConfirm() {
        return this._inCancelConfirm;
    }

    set inCancelConfirm(val: boolean) {
        setTimeout(() => {
            this._inCancelConfirm = val;
        })
    }

    public viewModel$: Observable<IViewModel>;
    public state: string = "open";
    public numTasks: number;
    public visible: boolean[] = [];
    public readonly VmwTaskState = VmwTaskState;
    private delaySummary: boolean = true;

    get stateTrigger() {
        return {
            value: this.state,
            params: {
                startHeight: this.element.nativeElement.clientHeight
            }
        };
    }

    // do we need this? We don't need to get the startHeight if we use the increment animation from task.component.ts
    launchTaskTrigger(length: number) {
        // weird behavior using this.element.nativeElement.clientHeight,
        // use this instead.
        let height = document.querySelector('vmw-task-panel').clientHeight;
        return {
            value: length,
            params: {
                startHeight: height
            }
        };
    }

    get showCaret(): boolean {
        return !this.inCancelConfirm && !this.focused;
    }

    get showSummary(): boolean {
        return this.state === 'collapsed' && this.numTasks > 1 && !this.delaySummary;
    }

    get willShowSummary(): boolean {
        return this.state === 'collapsed' && this.numTasks > 1;
    }

    get showTasks(): boolean {
        return this.numTasks === 1 || this.state === 'open';
    }

    get showTasksSummarized(): boolean {
        return this.numTasks > 1 || this.state === 'collapsed';
    }

    private _focused = false;
    public get focused(): boolean {
        return this._focused;
    }
    public set focused(focused: boolean) {
        this._focused = focused;
    }

    constructor(private vmwTasksService: VmwTasksService,
                private element: ElementRef) { }

    ngOnInit() {
        this.viewModel$ = this.vmwTasksService.tasks$
            .pipe(
                map(tasks => {
                    this.numTasks = tasks.length;

                    // When we get task updates from the service, set the visibity
                    // based on whether the task is already visible, or if we are focused
                    // on a task, keep the focus.
                    for (let i = 0; i < this.numTasks; i++) {
                        this.visible[i] = this.visible[i] || !this.focused;

                        if (this.state === 'collapsed' &&
                                tasks[i].state !== VmwTaskState.IN_PROGRESS &&
                                this.dismissTimeout !== -1) {
                            setTimeout(() => {
                                this.clearTask(tasks[i]);
                            }, this.dismissTimeout);
                        }
                    }

                    let _tasks = this.dismissable ?
                        tasks.filter(t => !t.dismissed) : tasks;

                    return {
                        tasks: _tasks,
                        runningTasks: this.getCountOfRunningTasks(_tasks)
                    } as IViewModel;
                })
            );
    }

    toggleState(forcedState?: string) {
        if (forcedState) {
            this.state = forcedState;
        } else {
            this.state = this.state === "open" ? "collapsed" : "open";
        }

        if (this.state === 'collapsed') {
            this.vmwTasksService.triggerRefresh();
        }

        this.delaySummary = this.state === 'collapsed';

        // we need to wait for the animation to finish before
        // collapsing or expanding.
        setTimeout(() => {
            this.delaySummary = false;
        }, multiply(componentPrimaryLeaveTiming));
    }

    toggleOthers(focused: boolean, thisIndex: number) {
        this.focused = focused;

        for (let index = 0; index < this.numTasks; index++) {
            if (focused) {
                this.visible[index] = index === thisIndex;
            } else {
                this.visible[index] = true;
            }
        }
    }

    taskClasses(task: VmwTask) {
        const classes: any = {};

        if (task.state === VmwTaskState.COMPLETED) {
            classes['completed'] = true;
        }

        if (task.state === VmwTaskState.ERROR) {
            classes['failed'] = true;
        }

        if (task.state === VmwTaskState.CANCELLED) {
            classes['cancelled'] = true;
        }

        classes['clickable'] = task.state === VmwTaskState.IN_PROGRESS &&
            !this.focused && this.numTasks > 1;

        return classes;
    }

    cancelTask(task: VmwTask) {
        task.cancel();
    }

    dismissTask(task: VmwTask) {
        task.dismissed = true;
    }

    clearTask(task: VmwTask) {
        task.clear();
        this.focused = false;
        if (this.numTasks === 1) {
            this.toggleState('open');
        }
    }

    getCountOfRunningTasks(tasks: VmwTask[]) {
        return tasks.filter(t => t.state === VmwTaskState.IN_PROGRESS).length;
    }
}
