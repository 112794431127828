// Generated at 2018-08-03T19:56:20.741Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeNotificationInstance } from '../../types/SampleExchangeNotificationInstance';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SampleService {
    public static instance: SampleService;


    constructor(public config: TangoServiceConfiguration) {

        if (!SampleService.instance) {
            SampleService.instance = this;
        }
    }

    getNotificationForSample(sampleId: string, headers: any = {}): Observable<SampleExchangeNotificationInstance | object> {
        let s = new Subject<SampleExchangeNotificationInstance | object>();

        let internalSuccessHandler = (result: SampleExchangeNotificationInstance) => {
            s.next(new SampleExchangeNotificationInstance(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sampleId: sampleId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/notifications/sample/{sampleId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createNotificationForSample(sampleId: string, headers: any = {}): Observable<SampleExchangeNotificationInstance | any> {
        let s = new Subject<SampleExchangeNotificationInstance | any>();

        let internalSuccessHandler = (result: SampleExchangeNotificationInstance) => {
            s.next(new SampleExchangeNotificationInstance(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sampleId: sampleId,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.post('/notifications/sample/{sampleId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    deleteNotificationForSample(sampleId: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sampleId: sampleId,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/notifications/sample/{sampleId}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
