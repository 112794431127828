// Generated at 2018-08-03T19:56:20.708Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeFileInfo } from '../../../types/SampleExchangeFileInfo';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class GetGitHubRepositoryFilesQueryParams {
    branch?: string;
}

export class FilesService {
    public static instance: FilesService;


    constructor(public config: TangoServiceConfiguration) {

        if (!FilesService.instance) {
            FilesService.instance = this;
        }
    }

    getGitHubRepositoryFiles(federationId: string, githubUser: string, repository: string, queryParams: GetGitHubRepositoryFilesQueryParams, headers: any = {}): Observable<Array<SampleExchangeFileInfo> | object> {
        let s = new Subject<Array<SampleExchangeFileInfo> | object>();

        let internalSuccessHandler = (result: Array<SampleExchangeFileInfo>) => {
            s.next(result.map(item => new SampleExchangeFileInfo(item)));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            federationId: federationId,
            githubUser: githubUser,
            repository: repository,
        };


        let result = this.config.transport.get('/authors/{federationId}/gitHubRepositories/{githubUser}/{repository}/files', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
