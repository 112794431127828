// tslint:disable


export class APIXState {
    public status: string;
    public publication_date: string;
    public deprecation_date: string;
    public recommended_replacement_uri: string;
    public support_end_date: string;
    public lastModified: string;
    public updatedBy: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
