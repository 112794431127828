import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vmw-progress-tracker-task-group-header',
    templateUrl: './task-group-header.component.html',
    styleUrls: ['./task-group-header.component.scss'],
    styles: [':host { display:flex;width:100%;}']
})
export class VmwProgressTrackerTaskGroupHeaderComponent {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() buttonText: string;

    @Output() buttonClick = new EventEmitter<null>();

    detailsButtonClicked() {
        this.buttonClick.emit();
    }
}