import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface VmwNgxMediaQueryResult {
    readonly matches: boolean;
    readonly mediaQuery: string;
}

@Injectable({
    providedIn: 'root'
})
export class VmwNgxMatchMediaService {

    public match(mediaQuery: string): Observable<VmwNgxMediaQueryResult> {
        return this.createMediaQueryObservable(mediaQuery);
    }

    public matchQueries(mediaQueries: string[]): Observable<VmwNgxMediaQueryResult[]> {
        return combineLatest(mediaQueries.map(query => this.createMediaQueryObservable(query))).pipe(debounceTime(0));
    }

    private createMediaQueryObservable(mediaQuery: string): Observable<VmwNgxMediaQueryResult> {
        return new Observable<VmwNgxMediaQueryResult>(observer => {
            const mediaQueryList = window.matchMedia(mediaQuery);

            const eventListener = (mql: MediaQueryListEvent) => {
                observer.next(this.convertToMediaQueryResult(mql));
            };

            observer.next(this.convertToMediaQueryResult(mediaQueryList));

            this.addListener(mediaQueryList, eventListener);

            return () => {
                this.removeListener(mediaQueryList, eventListener);
            };
        });
    }

    private convertToMediaQueryResult(mediaQueryList: MediaQueryList | MediaQueryListEvent): VmwNgxMediaQueryResult {
        return {
            matches: mediaQueryList.matches,
            mediaQuery: mediaQueryList.media
        };
    }

    private addListener(mediaQueryList: MediaQueryList, eventListener: (mql: MediaQueryListEvent) => any) {
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener('change', eventListener);
        } else {
            // In Safari addEventListener is not supported. We're using addListener instead.
            mediaQueryList.addListener(eventListener);
        }
    }

    private removeListener(mediaQueryList: MediaQueryList, eventListener: (mql: MediaQueryListEvent) => any) {
        if (mediaQueryList.removeEventListener) {
            mediaQueryList.removeEventListener('change', eventListener);
        } else {
            // In Safari removeEventListener is not supported. We're using removeListener instead.
            mediaQueryList.removeListener(eventListener);
        }
    }
}
