// Generated at 2018-08-03T19:56:20.757Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeDownload } from '../../types/SampleExchangeDownload';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class DownloadsService {
    public static instance: DownloadsService;


    constructor(public config: TangoServiceConfiguration) {

        if (!DownloadsService.instance) {
            DownloadsService.instance = this;
        }
    }

    getSampleDownloads(sampleId: string, headers: any = {}): Observable<Array<SampleExchangeDownload> | any> {
        let s = new Subject<Array<SampleExchangeDownload> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeDownload>) => {
            s.next(result.map(item => new SampleExchangeDownload(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sampleId: sampleId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/search/downloads/{sampleId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
