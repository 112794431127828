// tslint:disable

import { APIXTag } from './APIXTag';

export class APIXResource {
    public id: number;
    public resource_type: string;
    public name: string;
    public description: string;
    public version: string;
    public web_url: string;
    public download_url: string;
    public categories: Array<string>;
    public tags: Array<APIXTag>;
    public lastModified: string;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.tags) {
            this.tags = this.tags.map(item => new APIXTag(item));
        }
    }
}
