import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, ViewContainerRef } from '@angular/core';

import { Subscription, timer } from 'rxjs';

import { VmwTextClipperDirectiveService } from "./text-clipper.service";

export const DEFAULT_TOOLTIP_WIDTH = 240;

export enum TOOLTIP_POSITIONS {
    TOP_RIGHT = 'tooltip-top-right',
    TOP_LEFT = 'tooltip-top-left',
    BOTTOM_RIGHT = 'tooltip-bottom-right',
    BOTTOM_LEFT = 'tooltip-bottom-left',
    RIGHT = 'tooltip-right',
    LEFT = 'tooltip-left',
}

export const SHOW_DELAY_MS = 300;
export const HIDE_DELAY_MS = 300;

@Directive({
    selector: '[vmwTextClipper]',
    host: {
        '[style.text-overflow]': '"ellipsis"',
        '[style.white-space]': '"nowrap"',
        '[style.overflow]': '"hidden"',
    }
})
export class VmwTextClipperDirective {
    @Input('tooltipWidth')
    tooltipWidth: number = DEFAULT_TOOLTIP_WIDTH;

    @Input('tooltipLocation')
    tooltipLocation: TOOLTIP_POSITIONS;

    @HostListener('focus', ["$event"])
    @HostListener('mouseover', ["$event"])
    private showTooltip(event: Event) {
        this.focused = event instanceof FocusEvent;

        if (this.tooltipShown) {
            return;
        }

        this.tooltipShown = true;

        this.timerSub = timer(SHOW_DELAY_MS).subscribe(() => {
            let rect = this.host.nativeElement.getBoundingClientRect();
            let x = rect.left + (rect.width / 2);
            let y = rect.top;

            const hasOverflow = Math.ceil(rect.width)
                < this.host.nativeElement.scrollWidth;

            if (hasOverflow) {
                this.hoverSub = this.service.showTooltip(this.viewContainer, x, y,
                        this.tooltipWidth,
                        this.host.nativeElement.textContent,
                        this.tooltipLocation)
                    .subscribe((hovered: boolean) => {
                        this.hovered = hovered;
                        this.hideTooltip();
                    });
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    @HostListener('blur', ["$event"])
    @HostListener('mouseout', ["$event"])
    blurAndMouseOut(event: Event) {
        const isBlur = event instanceof FocusEvent;
        const isMouseOut = event instanceof MouseEvent;

        // If we were focused and it's a blur event, we're now not focused
        if (this.focused && isBlur) {
            this.focused = false;
        }

        if (isMouseOut && this.hovered || this.focused) {
            return;
        }

        this.timerSub?.unsubscribe();

        timer(HIDE_DELAY_MS).subscribe(() => {
            this.hideTooltip();
        });
    }

    private hideTooltip() {
        if (this.hovered || this.focused) {
            return;
        }

        this.timerSub?.unsubscribe();
        this.hoverSub?.unsubscribe();

        this.hovered = false;

        if (!this.tooltipShown) {
            return;
        }

        this.tooltipShown = false;

        this.service.hideTooltip();
        this.changeDetectorRef.markForCheck();
    }

    private timerSub: Subscription;
    private hoverSub: Subscription;
    private tooltipShown: boolean = false;
    private hovered: boolean = false;
    private focused: boolean = false;

    ngOnDestroy() {
        this.timerSub?.unsubscribe();
        this.hoverSub?.unsubscribe();
        this.service.hideTooltip();
    }

    constructor(private host: ElementRef,
                private viewContainer: ViewContainerRef,
                private service: VmwTextClipperDirectiveService,
                private changeDetectorRef: ChangeDetectorRef) {}
}
