import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Output,
    EventEmitter,
    Optional,
    Directive,
    ElementRef,
} from "@angular/core";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { FormControl, FormGroup } from "@angular/forms";

import { Subscription, Observable, of } from "rxjs";
import { debounceTime, skip, catchError, tap } from 'rxjs/operators';
import { saveAs } from "file-saver";
import * as YAML from 'yaml';

import { VmwSwaggerUtil, VmwSegmentService } from "@vmw/ngx-utils";
import { VmwClarityTheme, VmwClarityThemeService, VmwSwaggerClassNameMode } from "@vmw/ngx-utils";

import { DevCenterTranslateIfPossiblePipe } from "../utils/translate-if-possible.pipe";
import { CurlBuilder } from "./curl/curl-builder.service";
import { CurlData } from "./curl/curl-data";
import { CurlDataParserService } from "./curl/curl-data-parser.service";

const SUPPRESS_WARNING_KEY = "api-explorer-execute-warning-suppressed";
const ACE_DARK = "ace/theme/tomorrow_night";
const ACE_LIGHT = "ace/theme/tomorrow";

/**
 * Keeps information for pairs of deprecated/replacement APIs.
 */
export interface PathPair {
    currentPath: any;
    deprecatedPath: any;
    activePath: any;
    hasDeprecated: boolean;
}

/**
 * Vendor-specific tag in the swagger definition, providing a way for deprecated
 * APIs to point to their replacements.
 */
export interface VmwDeprecated {
    /**
     * JSON reference to a replacement for a deprecated API. Has the format
     * <swagger-doc>#<json-path>.
     */
    replacement: string;
}

@Directive({
    selector: "[vmwScrollIntoView]",
})
export class VmwScrollIntoViewDirective {
    @Input('vmwScrollIntoView') scrollIntoView: boolean;

    constructor(private el: ElementRef) {}

    ngAfterContentInit() {
        if (this.scrollIntoView) {
            setTimeout(() => {
                this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 1000);
        }
    }
}

@Component({
    selector: "vmw-swagger-list",
    templateUrl: "./swagger-list.component.html",
    styleUrls: ["./swagger-list.component.scss"],
    providers: [
        DevCenterTranslateIfPossiblePipe,
        VmwSwaggerUtil,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VmwSwaggerListComponent {
    @Input() id: string = "default";
    @Input() data: any = null;
    @Input() swaggerUrl: string = null;
    @Input() host: string = null;
    @Input() basePath: string = null;
    @Input() parameterMap: Map<string, string> = null;
    @Input() headerMap: Map<string, string> = null;
    @Input() showWarningOnExecute: boolean = false;
    @Input() updateLocationHash: boolean = true;
    @Input() enableTryItOut: boolean = true;
    @Input() classNameMode: VmwSwaggerClassNameMode = VmwSwaggerClassNameMode.JavaScript;
    @Input() buildCurl: boolean = false;
    @Input() enableEditor: boolean = false;
    @Input() markdown: boolean = false;

    @Output() dataChange = new EventEmitter<any>();

    public pathsByTag = new Map<string, Array<any>>();
    public pathPairsByTag = new Map<string, Array<any>>();
    public paths = new Map<string, any>();
    public definitions = new Map<string, any>();
    public parameters = new Map<string, any>();
    public tagsByName = new Map<string, any>();
    public tagsWithReplacedApis = new Set<string>();
    public tags: Array<string> = [];
    public filteredDefinitions = new Map<string, any>();
    public JSON = JSON;
    public filterControl = new FormControl();
    public modelFilterControl = new FormControl();
    public currentTag: string = null;
    public currentPath: string = null;
    public showExecuteWarning = false;
    public doRequest: Function;
    public executing: boolean = false;
    public filterValue: string;
    public filterHasResult: boolean = false;
    public modelFilterValue: string;
    public modelFilterHasResult: boolean = false;
    public loading: boolean = false;
    public isDescription: boolean = true;
    public isTryOut: boolean = true;
    public editorTheme: string = ACE_LIGHT;
    public modelExpanded = new Map<string, boolean>();
    public firstLoad = true;

    private themeSubscription: Subscription;
    private swaggerBaseUrl: string;

    constructor(private http: HttpClient,
                private translateIfPossible: DevCenterTranslateIfPossiblePipe,
                @Optional() private themeService: VmwClarityThemeService,
                private curlBuilder: CurlBuilder,
                private curlDataParser: CurlDataParserService,
                private swaggerUtil: VmwSwaggerUtil,
                private cdr: ChangeDetectorRef,
                @Optional() private segmentService: VmwSegmentService) {
        this.filterControl.valueChanges
            .pipe(skip(1), debounceTime(1000))
            .subscribe(filterValue => {
                this.filterValue = filterValue;
                this.handlePaths(this.data.paths, filterValue);
                this.cdr.detectChanges();
            });

        this.modelFilterControl.valueChanges
            .pipe(skip(1), debounceTime(1000))
            .subscribe(filterValue => {
                this.modelFilterValue = filterValue;
                this.filterDefinitions(filterValue);
                this.cdr.detectChanges();
            });

        if (window.location.hash) {
            let bits = window.location.hash.split("/");
            this.currentTag = bits[1];
            this.currentPath = window.location.hash?.substring(1);
        }

        if (this.themeService) {
            this.editorTheme = this.themeService.theme === VmwClarityTheme.Light ?
                ACE_LIGHT : ACE_DARK;

            this.themeSubscription = this.themeService.themeChange
                .subscribe((theme: VmwClarityTheme) => {
                    this.editorTheme = theme === VmwClarityTheme.Light ?
                        ACE_LIGHT : ACE_DARK;
                });
        }

    }

    get suppressExecuteWarning() {
        return !this.showWarningOnExecute ||
            localStorage.getItem(SUPPRESS_WARNING_KEY) === "true";
    }

    set suppressExecuteWarning(value: any) {
        localStorage.setItem(SUPPRESS_WARNING_KEY, value.toString());
    }

    filterDefinitions(filter: string) {
        if (!filter) {
            this.filteredDefinitions = this.definitions;
            return;
        }

        this.filteredDefinitions = new Map<string, any>();

        const caseInsensitiveFilter = new RegExp(filter, 'i');

        this.definitions.forEach((value, key) => {
            if (key.match(caseInsensitiveFilter)) {
                this.filteredDefinitions.set(key, value);
            }
        });

        this.modelFilterHasResult = this.filteredDefinitions.size > 0;
    }

    async ngOnChanges(changes: any) {
        // Swagger data was given to us synchronously
        if (changes.data && changes.data.currentValue !== null) {
            this.handleSwagger();

        // We were given a URL, which may have remote references, handle it async
        } else if (changes.swaggerUrl && changes.swaggerUrl.currentValue && !this.loading) {
            this.loading = true;

            this.swaggerBaseUrl = this.swaggerUrl.substring(0, this.swaggerUrl.lastIndexOf('/') + 1);

            this.data = await this.swaggerUtil.getSwagger(this.swaggerUrl);

            this.dataChange.emit(this.data);

            await this.handleSwagger();

        } else if (changes.parameterMap && changes.parameterMap.currentValue) {
            this.refreshParameters();
        }
    }

    private async handleSwagger() {
        this.filterControl.reset();
        this.paths = new Map<string, string>();

        if (this.data.parameters) {
            this.parameters = await this.swaggerUtil.getParameters(this.data.parameters, this.swaggerBaseUrl);
        }

        this.definitions = await this.swaggerUtil.getDefinitions(this.data, this.classNameMode, true, "", this.swaggerBaseUrl);

        this.filteredDefinitions = this.definitions;
        this.modelFilterHasResult = this.filteredDefinitions.size > 0;

        if (this.data.tags) {
            this.handleTags(this.data.tags);
        }

        this.handlePaths(this.data.paths);
    }

    private handleTags(tags: any) {
        for (let tag in tags) {
            if (!tags[tag]) {
                continue;
            }
            this.tagsByName.set(tags[tag].name, tags[tag]);
        }
    }

    public getDefinition(name: string) {
        return this.definitions.get(name);
    }

    private refreshParameters() {
        this.paths.forEach((path: any) => {
            for (let controlName of Object.keys(path.form.controls)) {
                let control = path.form.controls[controlName];
                let value = this.parameterMap.get(controlName);
                control.setValue(value);
            }
        });
    }

    private handlePaths(paths: any, filter: string = '') {
        this.pathsByTag = new Map<string, Array<any>>();
        this.tags = [];

        let activeToDeprecatedMap: Map<string, string> = new Map<string, string>();
        let deprecatedWithReplacement: Map<string, string> = new Map<string, string>();

        for (let path of Object.keys(paths)) {
            let value = this.data.paths[path];

            for (let method of Object.keys(value)) {
                let pathMethod = value[method];

                if (!pathMethod.tags) {
                    continue;
                }

                for (let tag of pathMethod.tags) {
                    let pathsForTag = this.pathsByTag.get(tag);

                    if (!pathsForTag) {
                        pathsForTag = [];
                        this.pathsByTag.set(tag, pathsForTag);
                    }

                    let responses = VmwSwaggerUtil.getResponsesForPathMethod(
                       pathMethod, this.classNameMode, "");

                    let params = [];
                    let formParameters: any = {};

                    if (pathMethod.parameters) {
                        for (let parameter of pathMethod.parameters) {
                            let param;
                            let controlName;

                            if (parameter.$ref) {
                                const refName = parameter.$ref.substring(parameter.$ref.lastIndexOf("/") + 1);
                                param = Object.assign({}, this.parameters.get(refName));

                                if (!param) {
                                    console.error(`Can't find parameter named ${refName}`);
                                    continue;
                                }

                                controlName = param.name;

                            } else if (parameter.schema?.items?.$ref) {
                                param = Object.assign({}, parameter);
                                param.definition = VmwSwaggerUtil.getDefinitionName(
                                   parameter.schema.items.$ref, this.classNameMode);
                                param.type = 'array';
                                if (param.in === "body") {
                                    controlName = "body";
                                }

                            }  else if (parameter.schema?.$ref) {
                                param = Object.assign({}, parameter);
                                param.definition = VmwSwaggerUtil.getDefinitionName(
                                    parameter.schema.$ref, this.classNameMode);
                                param.type = "object";
                                if (param.in === "body") {
                                    controlName = "body";
                                }

                            } else {
                                param = Object.assign({}, parameter);
                                // openapi v3 ...
                                if (parameter.schema?.type) {
                                    param.type = parameter.schema.type;
                                }
                                controlName = param.name;
                            }

                            param.required = parameter.required;
                            param.controlName = controlName;

                            if (parameter.description &&
                                  parameter.description.length > 200) {
                                param.showSeeMoreDescription = true;
                                param.truncatedDescription =
                                      parameter.description.slice(0, 200) +
                                      parameter.description.slice(200).split(/\s/)[0];
                            }

                            params.push(param);

                            let paramValue = "";

                            if (this.parameterMap && this.parameterMap.get(param.name)) {
                                paramValue = this.parameterMap.get(param.name);
                            }

                            formParameters[param.controlName] = new FormControl(paramValue);
                        }
                    }

                    // openapi v3
                    if (pathMethod.requestBody) {
                        let param: any = {
                            name: "body",
                            type: "string",
                            in: "body",
                            controlName: "body",
                        };

                        formParameters[param.controlName] = new FormControl("");

                        if (pathMethod.requestBody?.content["application/json"] &&
                                pathMethod.requestBody?.content["application/json"].schema?.$ref) {
                            param.type = 'object';
                            param.definition = VmwSwaggerUtil.getDefinitionName(
                               pathMethod.requestBody.content["application/json"].schema.$ref, this.classNameMode);
                        }

                        params.push(param);
                    }

                    let form = new FormGroup(formParameters);

                    let summary = pathMethod.summary;

                    if (pathMethod.deprecated) {
                        // If there is a x-vmw-deprecated node for a given API, then it
                        // has a clear replacement and this replacement is pointed to
                        // inside the x-vmw-deprecated object. We display the active and
                        // deprecated APIs in the same control, with a UI switch controlling
                        // whether to display the active or deprecated API.
                        // We build a bidirectional map between deprecated APIs and their
                        // replacement keys to avoid a second pass over the swagger.
                        // Later on, this map is validated and flattened into a structure
                        // directly mapping to the presentation.
                        if (pathMethod.hasOwnProperty("x-vmw-deprecated")) {
                            let vmwDeprecated: VmwDeprecated = pathMethod["x-vmw-deprecated"];
                            // Replacement is a generic json reference, but due to the formats
                            // we expect and display here, it has to have the format
                            // /paths/<encoded-path>/<method> -- as this is the API format understood
                            // by this component.
                            // The encoded-path has '/' replaced by '~1'. The following lines
                            // decode the reference to the path-method pair used to represent APIs internally.
                            const replacementSplit: Array<string> = vmwDeprecated.replacement.split("/");
                            const replacementMethod: string = replacementSplit[replacementSplit.length - 1];
                            const replacementPath: string = replacementSplit[replacementSplit.length - 2].split("~1").join("/");

                            const deprecatedKey: string = this.pathKey(method, path);
                            const replacementKey: string = this.pathKey(replacementMethod, replacementPath);

                            activeToDeprecatedMap.set(replacementKey, deprecatedKey);
                            deprecatedWithReplacement.set(deprecatedKey, replacementKey);
                        } else {
                           const deprecatedString = this.translateIfPossible.transform("swagger-list.deprecated", "deprecated");
                           summary = `${summary} (${deprecatedString})`;
                        }
                    }

                    const caseInsensitiveFilter = new RegExp(filter, 'i');

                    if (!filter || path.match(caseInsensitiveFilter) ||
                           (tag && tag.match(caseInsensitiveFilter)) ||
                               (summary && summary.match(caseInsensitiveFilter))) {
                        let pathData: any = {
                            path: path,
                            cleanPath: path.replace(/\{/g, "").replace(/\}/g, ""),
                            method: method,
                            parameters: params,
                            summary: summary,
                            description: pathMethod.description,
                            responses: responses,
                            response: null,
                            form: form,
                            deprecated: pathMethod.deprecated,
                        };

                        pathsForTag.push(pathData);

                        this.paths.set(this.pathKey(method, path), pathData);
                    }

                    pathsForTag.sort((a, b) => {
                        return a.path.length - b.path.length;
                    });

                    if (pathsForTag.length > 0 && this.tags.indexOf(tag) === -1) {
                        this.tags.push(tag);
                    }
                }
            }
        }

        // Now, flatten the bidirectional deprecated-active map to a list of either
        // single APIs, or active/deprecated pairs. If a deprecated API points to an
        // invalid replacement, treat it as if it has no replacement.
        this.pathsByTag.forEach((paths: Array<any>, tag: string) => {
            let pathPairsForTag: Array<any> = [];

            for (let p of paths) {
                const pathKey: string = this.pathKey(p.method, p.path);
                if (deprecatedWithReplacement.has(pathKey) &&
                        this.paths.has(deprecatedWithReplacement.get(pathKey))) {
                   // Ignore - will be taken care of in the replacement in case it's valid.
                    continue;
                }

                let currentPath: any = this.paths.get(pathKey);
                let deprecatedPath: any = undefined;
                if (activeToDeprecatedMap.has(pathKey)) {
                    deprecatedPath = this.paths.get(activeToDeprecatedMap.get(pathKey));
                    this.tagsWithReplacedApis.add(tag);
                }

                const pair: PathPair = {
                    currentPath: currentPath,
                    deprecatedPath: deprecatedPath,
                    activePath: currentPath,
                    hasDeprecated: deprecatedPath !== undefined
                };

               pathPairsForTag.push(pair);
           }

           this.pathPairsByTag.set(tag, pathPairsForTag);
       });

        if (this.tags.length > 0) {
            this.tags.sort();
            this.filterHasResult = false;
        } else {
            this.filterHasResult = true;
        }

        this.loading = false;

        this.cdr.detectChanges();
    }

    public hasDeprecationToggle(tag: string): boolean {
        return this.tagsWithReplacedApis.has(tag);
    }

    private pathKey(method: string, path: string): string {
        return method + '-' + path;
    }

    public tryItOut(path: any) {
        const headersObj: any = {};
        let contentType: string = "application/json";
        let body: string = "";
        let formDataParams: any = {};
        let queryStringParams: any = {};
        let sep: string;
        let url: string;

        let basePath = this.basePath || this.data.basePath || '';
        sep = (basePath && !basePath.endsWith('/') && !path.path.startsWith('/')) ? '/' : '';
        url = basePath + sep + path.path;

        let host = this.host || this.data.host || '';
        sep = (host && !host.endsWith('/') && !url.startsWith('/')) ? '/' : '';

        if (host.endsWith('/') && url.startsWith('/')) {
            url = url.substring(1);
        }

        url = host + sep + url;

        // If hostname was provided (either by [host] input or from swagger spec itself)
        // but the URL is not absolute and does not currently start with http, then prefix it
        // with https.
        if (host && host !== '/' && !url.startsWith('http')) {
            url = url + "https://";
        }

        let response: any = {
            url: url,
            data: null,
        };

        if (path.parameters.length) {
            for (let param of path.parameters) {
                let value = path.form.value[param.name];

                if (!value) {
                    continue;
                }

                if (param.in === "path") {
                    response.url = response.url.replace("{" + param.name + "}", value);
                } else if (param.in === 'query') {
                    if (param.type === "array") {
                        if (param.items.enum) {
                            queryStringParams[param.name] = value;
                        } else {
                            queryStringParams[param.name] = value.trim().split("\n");
                        }
                    } else {
                        queryStringParams[param.name] = value;
                    }
                } else if (param.in === 'formData') {
                    formDataParams[param.name] = value;
                } else if (param.in === 'header') {
                    headersObj[param.name] = value;
                }
            }
        }

        if (Object.keys(formDataParams).length) {
            let params: Array<string> = [];

            Object.keys(formDataParams).forEach(k => {
                params.push(`${k}=${formDataParams[k]}`);
            });

            contentType = 'application/x-www-form-urlencoded';

            body = encodeURI(params.join('&'));

        } else {
            body = path.form.value.body;
        }

        if (this.headerMap) {
            for (let header of Array.from(this.headerMap.keys())) {
                headersObj[header] = this.headerMap.get(header);
            }
        }

        let request: Observable<Object>;
        let headers: HttpHeaders;
        let execute = false;

        let params = new HttpParams({fromObject: queryStringParams});

        switch (path.method.toLowerCase()) {
            case "get":
                headers = new HttpHeaders(headersObj);
                execute = true;
                request = this.http.get(response.url, {
                    headers,
                    params,
                    observe: 'response'
                });
                break;

            case "post":
                headersObj["Content-type"] = contentType;
                headers = new HttpHeaders(headersObj);
                execute = this.suppressExecuteWarning;
                request = this.http.post(response.url, body, {
                    headers,
                    params,
                    observe: 'response'
                });
                break;

            case "patch":
                headersObj["Content-type"] = contentType;
                headers = new HttpHeaders(headersObj);
                execute = this.suppressExecuteWarning;
                request = this.http.patch(response.url, body, {
                    headers,
                    params,
                    observe: 'response'
                });
                break;

            case "put":
                headersObj["Content-type"] = contentType;
                headers = new HttpHeaders(headersObj);
                execute = this.suppressExecuteWarning;
                request = this.http.put(response.url, body, {
                    headers,
                    params,
                    observe: 'response'
                });
                break;

            case "delete":
                headers = new HttpHeaders(headersObj);
                execute = this.suppressExecuteWarning;
                request = this.http.delete(response.url, {
                    headers,
                    params,
                    observe: 'response'
                });
                break;

            default:
                console.log(path);
                break;
        }

        this.doRequest = () => {
            path.response = response;

            this.showExecuteWarning = false;
            this.executing = true;

            const trackingEventBody = {
                url: path.response.url
            }

            if (!!this.segmentService) {
                this.segmentService.trackEvent('NGX_Developer_Center_Click_ExecuteAPICall', trackingEventBody);
            }

            request
                .pipe(catchError((error: any) => {
                    this.executing = false;
                    path.response.data = error;
                    path.response.status = error.status;
                    path.response.statusText = error.statusText;
                    return of(error);
                }))
                .subscribe((response: any) => {
                    this.executing = false;
                    path.response.status = response.status;
                    path.response.statusText = response.statusText;
                    path.response.definition = this.getPathResponseDefinitionNameByCode(path, response.status);

                    if (response.body) {
                        path.response.data = response.body;
                        path.response.isList = response.body instanceof Array;
                        path.response.jsonString = JSON.stringify(response.body, null, 4);
                    } else {
                        path.response.data = response.error;
                        path.response.jsonString = JSON.stringify(response.error, null, 4);
                    }

                    if (this.buildCurl) {
                        let curlData: CurlData = this.curlDataParser.getCurlData(
                              headers, params, path, body, response);
                        path.response.curlString = this.curlBuilder.getCurl(curlData);
                    }

                    this.cdr.detectChanges();
                });
        };

        if (!execute) {
            this.showExecuteWarning = true;
        } else {
            this.doRequest();
        }
    }

    // returns true if the editor should be used for the given parameter.
    private useEditorForParameter( parameter: any ): boolean {
        return this.enableEditor
           && (parameter.type === 'object'
               || (parameter.type === 'array' &&
                   parameter?.schema?.items?.$ref));
    }

    private useTextAreaForParameter( parameter: any ): boolean {
        if (this.enableEditor) {
            // editor is enabled.  use text area anyway if
            // there is no enum (for simple types), it is not an object and it is not an array of objects or an array of enum types
            return (!parameter.enum
                && (parameter.type !== 'object')
                && (parameter.type !== 'string')
                && (parameter.type !== 'integer')
                && (parameter.type !== 'boolean')
                && !((parameter.type === 'array')
                    && ((parameter.items && parameter.items.enum)
                         || (parameter.schema && parameter.schema.items && parameter.schema.items.$ref))));
        } else {
            // editor is disabled.  We use textaread if it is an object or an array with no enum
            return ( (parameter.type === 'object') ||
                     (parameter.type === 'array' && (!parameter.items || !parameter.items.enum))
                   );
        }
    }

    private getPathResponseDefinitionNameByCode(path: any, code: number) {
        for (let response of path.responses) {
            if (parseInt(response.status) === code) {
                return response.definition;
            }
        }
    }

    public pasteBody(path: any, parameter: any) {
        if (path.form.controls[parameter.controlName].value !== '') {
            return;
        }

        let body = VmwSwaggerUtil.getDefinitionJSON(parameter.definition, this.definitions);

        path.form.controls[parameter.controlName].setValue(body);
    }

    downloadJSON(jsonContent: string, filename: string) {
        var blob = new Blob([jsonContent], {
            type: "text/plain;charset=utf-8",
        });

        saveAs(blob, filename);
    }

    getFullPath (tag: string, path: any) {
        return this.id + "/" + tag + "/" + path.method + path.cleanPath;
    }

    togglePathMetaData(flag: boolean) {
        this.isDescription = flag;
        this.isTryOut = flag;
    }

    tagExpandChange(isExpanded: any, tag: string) {
        if (this.updateLocationHash) {
            window.location.hash = this.id + "/" + tag;
        }

        if (isExpanded) {
            if (this.currentTag && tag !== this.currentTag) {
                this.currentPath = null;
                this.togglePathMetaData(false);
            } else {
                this.togglePathMetaData(true);
            }
            this.currentTag = tag;
        } else {
            this.currentTag = null;
            this.currentPath = null;
            this.togglePathMetaData(false);
        }
    }

    isTagExpanded(tag: any) {
        return this.currentTag === tag;
    }

    pathExpandChange(isExpanded: any, tag: string, path: any) {
        // avoid scroll into view when user clicks to expand a path
        this.firstLoad = false;

        let fullPath = this.getFullPath(tag, path);
        if (this.updateLocationHash) {
            window.location.hash = fullPath;
        }

        if (isExpanded) {
            this.togglePathMetaData(true);
            this.currentPath = fullPath;
        } else {
            this.currentPath = null;
            this.togglePathMetaData(false);
        }
    }

    isPathExpanded(tag: string, path: any) {
        let fullPath = this.getFullPath(tag, path);
        if (fullPath === this.currentPath) {
            this.togglePathMetaData(true);
            return true;
        }
        this.togglePathMetaData(false);
        return false;
    }

    isDescriptionExpanded() {
        return this.isDescription;
    }

    toggleActivePath(pathPair: PathPair): void {
        if (pathPair.activePath === pathPair.currentPath) {
            pathPair.activePath = pathPair.deprecatedPath;
        } else {
            pathPair.activePath = pathPair.currentPath;
        }
    }

    toggleActivePathKeyboard(pathPair: PathPair, $event: Event): void {
        // Do not bubble up to accordion which will trigger unexpand.
        $event.preventDefault();
        $event.stopPropagation();
        this.toggleActivePath(pathPair);
    }
}
