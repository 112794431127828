// tslint:disable

import { SampleExchangeFileInfo } from './SampleExchangeFileInfo';

export class SampleExchangeGitHubGist {
    public id: number;
    public description: string;
    public url: string;
    public createdTimeAgo: string;
    public updatedTimeAgo: string;
    public commentCount: number;
    public files: Array<SampleExchangeFileInfo>;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.files) {
            this.files = this.files.map(item => new SampleExchangeFileInfo(item));
        }
    }
}
