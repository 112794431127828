export const TRANSLATIONS = {
    'en': {
        'expand': 'Expand',
        'collapse': 'Collapse',
    },
    'es': {
        'expand': 'Desplegar',
        'collapse': 'Contraer',
    },
    'de': {
        'expand': 'Erweitern',
        'collapse': 'Reduzieren',
    },
    'fr': {
        'expand': 'DÃ©velopper',
        'collapse': 'RÃ©duire',
    },
    'ja': {
        'expand': 'å±é',
        'collapse': 'æãããã¿',
    },
    'ko': {
        'expand': 'í¼ì¹ê¸°',
        'collapse': 'ì ê¸°',
    },
    'zh_TW': {
        'expand': 'å±é',
        'collapse': 'æç',
    },
    'zh_CN': {
        'expand': 'å±å¼',
        'collapse': 'æå ',
    },
    'it': {
        'expand': 'Espandi',
        'collapse': 'Comprimi',
    },
    'nl': {
        'expand': 'Uitvouwen',
        'collapse': 'Samenvouwen',
    },
    'pt': {
        'expand': 'Expandir',
        'collapse': 'Recolher',
    },
    'ru': {
        'expand': 'Ð Ð°Ð·Ð²ÐµÑÐ½ÑÑÑ',
        'collapse': 'Ð¡Ð²ÐµÑÐ½ÑÑÑ',
    },
};
