// Generated at 2018-08-03T19:56:20.774Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { TypeService } from './Type';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class CategoryService {
    public static instance: CategoryService;

    public Type: TypeService;

    constructor(public config: TangoServiceConfiguration) {
        this.Type = new TypeService(this.config);

        if (!CategoryService.instance) {
            CategoryService.instance = this;
        }
    }

}
