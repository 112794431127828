// tslint:disable


export class SampleExchangeFlagType {
    public id: number;
    public entityType: string;
    public flagType: string;
    public explanation: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
