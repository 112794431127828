export enum VmwTaskStatus {
    NOT_STARTED,
    IN_PROGRESS,
    SKIPPED,
    COMPLETED
}

export interface VmwTaskData {
    name: string,
    link?: string,
    status: VmwTaskStatus,
}

export interface VmwTaskGroup {
    tasks: { id: VmwTaskIdentifier, status: VmwTaskStatus }[]
}

export interface VmwTaskIdentifier {
    groupIndex: number,
    taskIndex: number,
    isLastTask: boolean
}