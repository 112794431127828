import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { Subject } from "rxjs";

import { VmwCookieUtil } from "../cookie-util";

export enum VmwClarityTheme {
    Dark = "Dark",
    Light = "Light",
};

const THEME_COOKIE_NAME = "clarity-theme";
const THEME_COOKIE_DOMAIN = "vmware.com";
const CLARITY_CSS_LINK_ID = "clarity-css";
const CLARITY_CSS_DARK_PATH = "assets/css/clr-ui-dark.min.css";
const CLARITY_CSS_LIGHT_PATH = "assets/css/clr-ui.min.css";

export class VmwClarityThemeConfig {
    public clarityDarkPath: string;
    public clarityLightPath: string;

    constructor(
        clarityDarkPath?: string,
        clarityLightPath?: string,
        public cookieName: string = THEME_COOKIE_NAME,
        public darkBodyClasses: string[] = ["fade-to-dark", "dark"],
        public cookieDomain: string = THEME_COOKIE_DOMAIN) {
            let baseUri = (document.querySelector("base") || {} as any).href || "/";
            this.clarityDarkPath = clarityDarkPath ? clarityDarkPath : baseUri + CLARITY_CSS_DARK_PATH;
            this.clarityLightPath = clarityLightPath ? clarityLightPath : baseUri + CLARITY_CSS_LIGHT_PATH;
    }
}

@Injectable()
export class VmwClarityThemeService {
    public themeChange = new Subject<string>();

    private _theme: VmwClarityTheme = VmwClarityTheme.Light;
    private config = new VmwClarityThemeConfig();

    constructor(@Inject(DOCUMENT) private document: any) {}

    initialize(providedConfig?: VmwClarityThemeConfig) {
        return new Promise((resolve, reject) => {
            if (providedConfig) {
                this.config = Object.assign(this.config, providedConfig);
            }

            let clarityCssLink = this.document.getElementById(CLARITY_CSS_LINK_ID) as HTMLLinkElement;
            if (!clarityCssLink) {
                clarityCssLink = this.document.createElement("link");
                clarityCssLink.rel = "stylesheet";
                clarityCssLink.id = CLARITY_CSS_LINK_ID;
                this.document.head.appendChild(clarityCssLink);
            }

            this.theme = (VmwCookieUtil.getCookie(this.config.cookieName) as VmwClarityTheme) ||
                VmwClarityTheme.Light;

            if (this.theme === VmwClarityTheme.Dark) {
                setTimeout(() => {
                    resolve(this.theme);
                }, 2500);
            } else {
                resolve(this.theme);
            }
        });
    }

    public get theme(): VmwClarityTheme {
        return this._theme;
    }

    public set theme(newTheme: VmwClarityTheme) {
        const clarityCssLink = this.document.getElementById(CLARITY_CSS_LINK_ID) as HTMLLinkElement;

        this._theme = newTheme;

        if (newTheme === VmwClarityTheme.Dark) {
            let bodyClasses = "dark";

            if (this.config.darkBodyClasses) {
                bodyClasses = this.config.darkBodyClasses.join(" ");
            }

            this.document.body.className = bodyClasses;

            clarityCssLink.href = this.config.clarityDarkPath;

        } else {
            this.document.body.className = "";

            clarityCssLink.href = this.config.clarityLightPath;
        }

        VmwCookieUtil.setCookie(THEME_COOKIE_NAME, newTheme,
            "/",
            VmwCookieUtil.TEN_YEARS,
            this.config.cookieDomain);

        this.themeChange.next(newTheme);
    }
}
