// tslint:disable


export class SampleExchangeFileInfo {
    public id: number;
    public name: string;
    public mode: string;
    public type: string;
    public sha: string;
    public path: string;
    public size: number;
    public url: string;
    public content: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
