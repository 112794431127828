// Generated at 2018-08-03T19:56:17.063Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { ConvertmarkdownService } from './Convertmarkdown';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class UtilsService {
    public static instance: UtilsService;

    public Convertmarkdown: ConvertmarkdownService;

    constructor(public config: TangoServiceConfiguration) {
        this.Convertmarkdown = new ConvertmarkdownService(this.config);

        if (!UtilsService.instance) {
            UtilsService.instance = this;
        }
    }

}
