import { HttpClient, HttpResponse, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';

import { TangoTransportAdapterInterface } from './TangoTransportAdapterInterface';
import { TangoListenHandle } from '../ListenHandle';
import { TangoHttpError } from './TangoHttpError';

export class TangoAngularHttpClientAdapter implements TangoTransportAdapterInterface {
    private options: any = {};

    constructor(public http: HttpClient, public baseUrl: string, options?: any) {
        if (options) {
            this.options = options;
        }
    }

    private handleErrorResponse(response: HttpErrorResponse): TangoHttpError | any {
        // usually 504 comes in as a huge HTML block from the load balancer,
        // we don't want to show this to the user, so replace it with a short string.
        if (response.status === 504 || response.status === 502) {
            return {
                message: 'Request timed out',
                status: response.status,
                statusText: response.statusText,
            } as TangoHttpError;
        }

        if (!response.error) {
            return {
                status: response.status,
                statusText: response.statusText,
                message: response.message,
            } as TangoHttpError;

        } else {
            return response.error;
        }
    }

    protected replaceParams(path: string, params: any) {
        for (let param of Object.keys(params)) {
            path = path.replace('{' + param + '}', params[param]);
        }
        return path;
    }

    protected getQueryStringParams(params: any): HttpParams {
        const httpParams = new HttpParams({
            fromObject: params
        });
        return httpParams;
    }

    private handleResponse(response: HttpResponse<any>, successHandler: Function, failureHandler: Function) {
        if (response.status === 204) {
            successHandler({});
            return;
        }

        try {
            successHandler(response.body);
        } catch (e) {
            // Handle invalid payloads as errors
            let msg = response.body;

            failureHandler({
                message: e.message,
                status: response.status,
                statusText: e.message,
            } as TangoHttpError);
        }
    }

    private getRequestHeaders(headers: any) {
        let headersForThisRequest = Object.assign(new HttpHeaders(),
            this.options ? this.options.headers : {});

        Object.keys(headers).forEach((header) => {
            headersForThisRequest = headersForThisRequest.set(header, headers[header]);
        });

        if (headersForThisRequest.keys().length === 0) {
            return null;
        }

        return headersForThisRequest;
    }

    get(path: string, params: any, queryStringParams: any, headers: any,
            successHandler: (result: any) => void, failureHandler: (result: any) => void): void {
        path = this.baseUrl + this.replaceParams(path, params);

        this.http.get<any>(path, Object.assign({
            observe: 'response',
            params: this.getQueryStringParams(queryStringParams),
        }, this.options, {
            headers: this.getRequestHeaders(headers),
        })).subscribe((response: HttpResponse<any>) => {
            this.handleResponse(response, successHandler, failureHandler);
        }, (error: HttpErrorResponse) => {
            failureHandler(this.handleErrorResponse(error));
        });
    }

    post(path: string, params: any, queryStringParams: any, body: any, headers: any,
            successHandler: (result: any) => void, failureHandler: (result: any) => void): void {
        path = this.baseUrl + this.replaceParams(path, params);

        this.http.post<any>(path, body, Object.assign({
            observe: 'response',
            params: this.getQueryStringParams(queryStringParams),
        }, this.options, {
            headers: this.getRequestHeaders(headers),
        })).subscribe((response: HttpResponse<any>) => {
            this.handleResponse(response, successHandler, failureHandler);
        }, (error: HttpErrorResponse) => {
            failureHandler(this.handleErrorResponse(error));
        });
    }

    patch(path: string, params: any, queryStringParams: any, body: any, headers: any,
            successHandler: (result: any) => void, failureHandler: (result: any) => void): void {
        path = this.baseUrl + this.replaceParams(path, params);

        this.http.patch<any>(path, body, Object.assign({
            observe: 'response',
            params: this.getQueryStringParams(queryStringParams),
        }, this.options, {
            headers: this.getRequestHeaders(headers),
        })).subscribe((response: HttpResponse<any>) => {
            this.handleResponse(response, successHandler, failureHandler);
        }, (error: HttpErrorResponse) => {
            failureHandler(this.handleErrorResponse(error));
        });
    }

    put(path: string, params: any, queryStringParams: any, body: any, headers: any,
            successHandler: (result: any) => void, failureHandler: (result: any) => void): void {
        path = this.baseUrl + this.replaceParams(path, params);

        this.http.put<any>(path, body, Object.assign({
            observe: 'response',
            params: this.getQueryStringParams(queryStringParams),
        }, this.options, {
            headers: this.getRequestHeaders(headers),
        })).subscribe((response: HttpResponse<any>) => {
            this.handleResponse(response, successHandler, failureHandler);
        }, (error: HttpErrorResponse) => {
            failureHandler(this.handleErrorResponse(error));
        });
    }

    delete(path: string, params: any, queryStringParams: any, body: any, headers: any,
            successHandler: (result: any) => void, failureHandler: (result: any) => void): void {
        path = this.baseUrl + this.replaceParams(path, params);

        this.http.request<any>('DELETE', path, Object.assign({
            observe: 'response',
            params: this.getQueryStringParams(queryStringParams),
            body: body,
        }, this.options, {
            headers: this.getRequestHeaders(headers),
        })).subscribe((response: HttpResponse<any>) => {
            this.handleResponse(response, successHandler, failureHandler);
        }, (error: HttpErrorResponse) => {
            failureHandler(this.handleErrorResponse(error));
        });
    }

    listen(path: string, params: any,
            successHandler: (result: any) => void, failureHandler: (result: any) => void): TangoListenHandle {
        // not implemented
        return;
    }
}
