// tslint:disable


export class SampleExchangeEntity {
    public parent: SampleExchangeEntity;
    public content: Object;
    constructor(json: any) {
        Object.assign(this, json);
        this.parent = new SampleExchangeEntity(this.parent);
    }
}
