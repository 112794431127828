/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */

import {
    Component,
    Renderer2,
    ElementRef,
    HostBinding,
    Output,
    EventEmitter,
    ViewChild
} from '@angular/core';
import { VmwSplitterService } from "./splitter.service";
import {
    VmwSplitterSplitPairProperties,
    dragStartEvents,
    dragEvents,
    dragStopEvents,
    elementDragEvents,
    VmwSplitterElementProperties,
    NOOP,
    MINIMUM_GUTTER_SIZE
} from "./splitter.interface";


const resources = {
    styles: {
        states: {
            dragging: "dragging",
            collapsed: "collapsed"
        }
    }
};

@Component({
    selector: 'split-layout',
    template: `
        <ng-content></ng-content>
        <div #gutter *ngIf="showDefaultGutter"></div>
        <ng-content></ng-content>
        <ng-content></ng-content>`,
    styleUrls: ["./splitter-layout.component.scss"]
})
export class VmwSplitterLayoutComponent {
    @ViewChild('gutter', { static: false }) gutterRef: ElementRef;

    @HostBinding('class.fill-parent') fillParent = true;

    @Output() vmwSplitterDragChange = new EventEmitter();
    @Output() vmwSplitterSizeChange: EventEmitter<number[]> = new EventEmitter();
    @Output() vmwSplitterCollapseChange: EventEmitter<number> = new EventEmitter();

    showDefaultGutter: boolean = true;
    splitterRef: HTMLElement;
    splitPairProperties: VmwSplitterSplitPairProperties;

    constructor(private renderer: Renderer2,
                private elementRef: ElementRef,
                private vmwSplitterService: VmwSplitterService) {
    }

    private _gutter: HTMLElement;
    get gutter(): HTMLElement {
        return this._gutter;
    }

    set gutter(gutter: HTMLElement) {
        this._gutter = gutter;
        this.renderer.addClass(this._gutter, `gutter`);
    }

    private _splitElements: VmwSplitterElementProperties[];
    get splitElements(): VmwSplitterElementProperties[] {
        return this._splitElements;
    }

    set splitElements(elements: VmwSplitterElementProperties[]) {
        this._splitElements = elements;
        this.renderer.addClass(elements[0].element, 'firstPanel');
        this.renderer.addClass(elements[1].element, 'secondPanel');
    }

    private _direction: string;
    get direction(): string {
        return this._direction;
    }

    set direction(direction: string) {
        this._direction = direction;
        this.renderer.addClass(this.elementRef.nativeElement, direction);
        if (this._gutter) {
            this.renderer.addClass(this._gutter, `gutter-${direction}`);
        }
    }

    private _collapsed: boolean = false;
    get collapsed(): boolean {
        return this._collapsed;
    }

    set collapsed(collapsed: boolean) {
        this._collapsed = collapsed;

        if (collapsed) {
            this.renderer.addClass(this.elementRef.nativeElement, resources.styles.states.collapsed);
        } else {
            this.renderer.removeClass(this.elementRef.nativeElement, resources.styles.states.collapsed);
        }
    }

    private _dragging: boolean = false;
    private get dragging(): boolean {
        return this._dragging;
    }

    private set dragging(dragging: boolean) {
        this._dragging = dragging;

        if (dragging) {
            this.renderer.addClass(this.elementRef.nativeElement, resources.styles.states.dragging);
        } else {
            this.renderer.removeClass(this.elementRef.nativeElement, resources.styles.states.dragging);
        }
    }

    updateViewport() {
        this.vmwSplitterService.updateSplitPairSize(this);
        this.vmwSplitterSizeChange.emit(this.vmwSplitterService.getSizes(this));
    }

    /**
     * Called when dragging is started
     */
    startDragging(event: MouseEvent | TouchEvent): void {
        if ('button' in event) {
            // If the mouse event is not left click, return.
            if (event.button !== 0) {
                return;
            }
        }
        if (this.collapsed) {
            return;
        }
        event.preventDefault();
        this.dragging = true;
        this.splitPairProperties.move = this.drag.bind(this);
        this.splitPairProperties.stop = this.stopDragging.bind(this);
        this.subscribeDraggingEvents();
        this.updateViewport();
        this.splitPairProperties.dragOffset = this.getDragPosition(event, this.splitPairProperties.clientAxis) - this.splitPairProperties.end;
    }

    /**
     * Called when dragging is in progress. Calculates the panel sizes using offset of the dragging pointer.
     */
    drag(event: MouseEvent | TouchEvent): void {
        let offset: number;
        const [startSplitElement, endSplitElement] = this.splitElements;
        // If dragging hasn't not started or has been stopped, return.
        if (!this.dragging) {
            return;
        }
        offset = (this.getDragPosition(event, this.splitPairProperties.clientAxis) - this.splitPairProperties.start)
            + (startSplitElement.gutterSize - this.splitPairProperties.dragOffset);
        if (this.splitPairProperties.dragInterval > 1) {
            offset = Math.round(offset / this.splitPairProperties.dragInterval) * this.splitPairProperties.dragInterval;
        }

        const firstTempSize = startSplitElement.minSize + startSplitElement.gutterSize;
        const secondTempSize = endSplitElement.minSize + endSplitElement.gutterSize;
        if (offset <= firstTempSize + this.splitPairProperties.snapOffset) {
            offset = firstTempSize;
        } else if (offset >= this.splitPairProperties.size - (secondTempSize + this.splitPairProperties.snapOffset)) {
            offset = this.splitPairProperties.size - secondTempSize;
        }
        this.vmwSplitterService.adjust(this, offset);
        this.vmwSplitterDragChange.emit();
    }

    /**
     * Called when dragging is stopped
     */
    stopDragging(): void {
        if (this.dragging) {
        }
        this.dragging = false;
        this.splitPairProperties.stop = NOOP;
        this.splitPairProperties.move = NOOP;
        this.unsubscribeDraggingEvents();
    }

    /**
     * Subscribe for events that enable dragging of splitter i.e: mousedown, touchdown
     */
    subscribePreDraggingEvent(): void {
        this.splitPairProperties.gutterStartDragging = this.startDragging.bind(this);
        dragStartEvents.forEach((event: string) => {
            const eventUnsubscribe = this.renderer.listen(this.gutter, event,
                this.splitPairProperties.gutterStartDragging);
            this.splitPairProperties.dragStartEvents.push(eventUnsubscribe);
        });
    }

    /**
     * Unsubscribe for events that enable dragging of splitter i.e: mousedown, touchdown
     */
    unsubscribePreDraggingEvent(): void {
        this.splitPairProperties.dragStartEvents.forEach((eventUnsubscribe: () => void) => {
            eventUnsubscribe();
        });
        this.splitPairProperties.dragStartEvents = [];
    }

    /**
     * Subscribes to the drag events for when dragging in action
     */
    private subscribeDraggingEvents(): void {
        dragStopEvents.forEach((event: string) => {
            const eventUnsubscribe = this.renderer.listen(window, event,
                this.splitPairProperties.stop);
            this.splitPairProperties.dragStopEvents.push(eventUnsubscribe);
        });

        dragEvents.forEach((event: string) => {
            const eventUnsubscribe = this.renderer.listen(window, event,
                this.splitPairProperties.move);
            this.splitPairProperties.dragEvents.push(eventUnsubscribe);
        });

        const [startSplitElement, endSplitElement] = this.splitElements;

        elementDragEvents.forEach((event: string) => {
            const eventUnsubscribe1 = this.renderer.listen(startSplitElement.element, event, NOOP);
            this.splitPairProperties.elementDragEvents.push(eventUnsubscribe1);
            const eventUnsubscribe2 = this.renderer.listen(endSplitElement.element, event, NOOP);
            this.splitPairProperties.elementDragEvents.push(eventUnsubscribe2);
        });
    }

    /**
     * Unsubscribes from all the drag events for when dragging not in action or ended.
     */
    private unsubscribeDraggingEvents(): void {
        this.splitPairProperties.dragStopEvents.forEach((eventUnsubscribe: () => void) => {
            eventUnsubscribe();
        });
        this.splitPairProperties.dragStopEvents = [];
        this.splitPairProperties.dragEvents.forEach((eventUnsubscribe: () => void) => {
            eventUnsubscribe();
        });
        this.splitPairProperties.dragEvents = [];
        this.splitPairProperties.elementDragEvents.forEach((eventUnsubscribe: () => void) => {
            eventUnsubscribe();
        });
        this.splitPairProperties.elementDragEvents = [];
    }

    /**
     * Returns the mouse position on a particular axis. (Horizontal Splitter: X-axis, Vertical Splitter: Y axis)
     */
    private getDragPosition(event: MouseEvent | TouchEvent, clientAxis: string): number {
        //If it is a touch event =>
        if ('touches' in event) {
            return event.touches[0][clientAxis];
        }
        return event[clientAxis];
    }

    /**
     * Uses Renderer to set panel size (height/width)
     */
    resizePanelElement(el: HTMLElement, size: number, gutterSize: number, idx?: number): void {
        let sizeUnit = '%';
        // Calculate panel height in  'vh' for vertical splitter. This is because if heights of all parent elements is not set with %, then
        // the vertical splitter panels' heights don't resize using % values.
        if (this.splitPairProperties.dimension === 'height') {
            sizeUnit = 'vh';
        }
        const newSize = `calc(${size}${sizeUnit} - ${gutterSize}px)`;
        this.renderer.setStyle(el, this.splitPairProperties.dimension, newSize);
    }

    setGutterSize(gutterSize: number): void {
        //gutter border accounts for 3px of the gutter size (which is already set in the css).
        //rest of the gutter size (size - DEFAULT_GUTTER_SIZE) accounts for height/width of the gutter.
        const newGutterSize = gutterSize > MINIMUM_GUTTER_SIZE ? gutterSize : MINIMUM_GUTTER_SIZE;
        this.renderer.setStyle(this.gutter, this.splitPairProperties.dimension, `${newGutterSize}px`);
    }
}
