// tslint:disable

import { APIXContentDisposition } from './APIXContentDisposition';
import { APIXMediaType } from './APIXMediaType';
import { APIXMessageBodyWorkers } from './APIXMessageBodyWorkers';
import { APIXMultiPart } from './APIXMultiPart';
import { APIXProviders } from './APIXProviders';
import { APIXBodyPart } from './APIXBodyPart';

export class APIXFormDataMultiPart {
    public contentDisposition: APIXContentDisposition;
    public entity: Object;
    public headers: Object;
    public mediaType: APIXMediaType;
    public messageBodyWorkers: APIXMessageBodyWorkers;
    public parent: APIXMultiPart;
    public providers: APIXProviders;
    public bodyParts: Array<APIXBodyPart>;
    public fields: Object;
    public parameterizedHeaders: Object;
    constructor(json: any) {
        Object.assign(this, json);
        this.contentDisposition = new APIXContentDisposition(this.contentDisposition);
        this.mediaType = new APIXMediaType(this.mediaType);
        this.messageBodyWorkers = new APIXMessageBodyWorkers(this.messageBodyWorkers);
        this.parent = new APIXMultiPart(this.parent);
        this.providers = new APIXProviders(this.providers);
        if (this.bodyParts) {
            this.bodyParts = this.bodyParts.map(item => new APIXBodyPart(item));
        }
    }
}
