// Generated at 2018-08-03T19:56:17.011Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXResource } from '../../../types/APIXResource';
import { APIXErrorResponse } from '../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ResourcesService {
    public static instance: ResourcesService;


    constructor(public config: TangoServiceConfiguration) {

        if (!ResourcesService.instance) {
            ResourcesService.instance = this;
        }
    }

    getProductResources(product_id: string, headers: any = {}): Observable<Array<APIXResource> | APIXErrorResponse> {
        let s = new Subject<Array<APIXResource> | APIXErrorResponse>();

        let internalSuccessHandler = (result: Array<APIXResource>) => {
            s.next(result.map(item => new APIXResource(item)));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            product_id: product_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/products/{product_id}/resources', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
