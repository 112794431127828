// Generated at 2018-08-03T19:56:20.757Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { DownloadsService } from './Downloads';
import { EventsService } from './Events';
import { FavoritesService } from './Favorites';
import { RequestsService } from './Requests';
import { SamplesService } from './Samples';
import { ServerInfoService } from './ServerInfo';
import { SolutionsService } from './Solutions';
import { TagsService } from './Tags';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class SearchService {
    public static instance: SearchService;

    public Downloads: DownloadsService;
    public Events: EventsService;
    public Favorites: FavoritesService;
    public Requests: RequestsService;
    public Samples: SamplesService;
    public ServerInfo: ServerInfoService;
    public Solutions: SolutionsService;
    public Tags: TagsService;

    constructor(public config: TangoServiceConfiguration) {
        this.Downloads = new DownloadsService(this.config);
        this.Events = new EventsService(this.config);
        this.Favorites = new FavoritesService(this.config);
        this.Requests = new RequestsService(this.config);
        this.Samples = new SamplesService(this.config);
        this.ServerInfo = new ServerInfoService(this.config);
        this.Solutions = new SolutionsService(this.config);
        this.Tags = new TagsService(this.config);

        if (!SearchService.instance) {
            SearchService.instance = this;
        }
    }

}
