// Generated at 2018-08-03T19:56:17.034Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXRelease } from '../../../../types/APIXRelease';
import { APIXErrorResponse } from '../../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class GetReleaseMasterReleaseQueryParams {
    name_override?: string;
}
export class ImportReleaseMasterReleaseQueryParams {
    name_override?: string;
}

export class RmreleaseService {
    public static instance: RmreleaseService;


    constructor(public config: TangoServiceConfiguration) {

        if (!RmreleaseService.instance) {
            RmreleaseService.instance = this;
        }
    }

    getReleaseMasterRelease(release_master_release_id: string, queryParams: GetReleaseMasterReleaseQueryParams, headers: any = {}): Observable<APIXRelease | APIXErrorResponse> {
        let s = new Subject<APIXRelease | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXRelease) => {
            s.next(new APIXRelease(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_master_release_id: release_master_release_id,
        };


        let result = this.config.transport.get('/apix/releases/import/rmrelease/{release_master_release_id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    importReleaseMasterRelease(release_master_release_id: string, queryParams: ImportReleaseMasterReleaseQueryParams, headers: any = {}): Observable<APIXRelease | APIXErrorResponse> {
        let s = new Subject<APIXRelease | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXRelease) => {
            s.next(new APIXRelease(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            release_master_release_id: release_master_release_id,
        };

        let body = {};

        let result = this.config.transport.post('/apix/releases/import/rmrelease/{release_master_release_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
