import { NgModule } from "@angular/core";

import { VmwCardCatalogComponent } from "./card-catalog.component";
import { VmwCardCatalogCardComponent } from "./card-catalog-card.component";
import { VmwCardCatalogIteratorDirective } from "./card-catalog-iterator.directive";

@NgModule({
    declarations: [
        VmwCardCatalogComponent,
        VmwCardCatalogCardComponent,
        VmwCardCatalogIteratorDirective,
    ],
    exports: [
        VmwCardCatalogComponent,
        VmwCardCatalogCardComponent,
        VmwCardCatalogIteratorDirective,
    ],
})
export class VmwCardCatalogModule {}
