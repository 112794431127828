import {
    Component,
    EventEmitter,
    Input,
    Output,
    ContentChildren,
    QueryList,
    OnDestroy,
    AfterViewChecked,
    OnChanges,
    forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Subscription } from "rxjs";

import { VmwRowSelectorService, VmwRowSelectorMode, VmwRowSelectorButtonPosition } from "./row-selector.service";
import { VmwRowSelectorRowComponent } from "./row-selector-row.component";

@Component({
    selector: "vmw-row-selector",
    templateUrl: "row-selector.component.html",
    styleUrls: ["./row-selector.component.scss"],
    providers: [
        VmwRowSelectorService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VmwRowSelectorComponent),    // tslint:disable-line:no-forward-ref
            multi: true
        }
    ]
})
export class VmwRowSelectorComponent implements AfterViewChecked, OnDestroy, OnChanges, ControlValueAccessor {
    @ContentChildren(VmwRowSelectorRowComponent) rows: QueryList<VmwRowSelectorRowComponent>;

    @Input() mode: VmwRowSelectorMode = VmwRowSelectorMode.SINGLE;
    @Input() name: string;
    @Input() hasZebraStripes: boolean;
    @Input() hasSeparators: boolean;
    @Input() hasRowHover: boolean;
    @Input() buttonPosition: VmwRowSelectorButtonPosition;

    private childSubscriptions: Array<Subscription> = [];
    private changeSubscription: Subscription;
    private onChangeCallback: (_: any) => {};
    private onTouchedCallback: () => {};

    constructor(private service: VmwRowSelectorService) {}

    private updateRows(): void {
        this.childSubscriptions.map(s => s.unsubscribe());

        for (const row of this.rows.toArray()) {
            this.childSubscriptions.push(row.change.subscribe((e: any) => {
                let value: Array<any> | any;

                if (this.mode === VmwRowSelectorMode.MULTI) {
                    value = this.rows.toArray().filter(r => r.selected).map(r => r.value);
                } else {
                    value = e;
                }

                if (this.onChangeCallback) {
                    this.onChangeCallback(value);
                }

                this.service.selection = value;
            }));
        }
    }

    ngOnChanges() {
        this.service.name = this.name;
        this.service.mode = this.mode;
        this.service.buttonPosition = this.buttonPosition || VmwRowSelectorButtonPosition.MIDDLE;
    }

    ngAfterViewChecked() {
        this.updateRows();

        if (this.changeSubscription) {
            this.changeSubscription.unsubscribe();
        }

        this.changeSubscription = this.rows.changes.subscribe((changes: any) => {
            this.updateRows();
        });
    }

    ngOnDestroy() {
        if (this.changeSubscription) {
            this.changeSubscription.unsubscribe();
        }
        this.childSubscriptions.map(s => s.unsubscribe());
    }

    writeValue(value: any | Array<any>) {
        this.service.selection = value;
    }

    /**
     * Set the function to be called when the control receives a change event.
     * This function is called by Angular when the NgModel binding is used.
     */
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    /**
     * Set the function to be called when the control receives a touch event.
     * This function is called by Angular when the NgModel binding is used.
     */
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}
