import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";


@Component({
    selector: "vmw-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
})
export class VmwSearchComponent {

    @Input('disabled')
    private _disabled: boolean = false;
    public get disabled(): boolean {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = value;

        if (value) {
            this.searchQuery.disable({emitEvent: false});
        } else {
            this.searchQuery.enable({emitEvent: false});
        }
    }

    @Input() searchQueryValue: string = '';
    @Input() clearSearchTitle: string = 'Clear Search';
    @Input('placeholder')
    set placeholder(pass: string) {
        this.finalPlaceholder = pass ? pass : "Search";
    }

    @Input() helperText: string = '';
    @Input() debounceTime: number = 100;
    @Input() searchTermMinimalLength: number = 2;

    @Output() search: EventEmitter<string> = new EventEmitter();

    searchQuery: FormControl = new FormControl();
    searchQuerySub: Subscription;

    finalPlaceholder: string = "Search";

    constructor() { }

    ngOnInit() {
        this.searchQuerySub = this.searchQuery.valueChanges
            .pipe(
                debounceTime(this.debounceTime)
            )
            .subscribe((query: string) => {
                const queryLength = query.length;
                query = query.trim();
                this.searchQueryValue = query;

                // not emit search event if it hasn't been inputted something different from whitespace
                if (this.searchQueryValue.length === 0 &&
                    queryLength !== 0) {
                    return;
                }

                // emit search event only when the input length is bigger than search term minimal length
                // or the input has been cleaned
                if (this.searchQueryValue.length >= this.searchTermMinimalLength ||
                    this.searchQueryValue.length === 0) {
                    this.search.emit(query);
                }
            });
    }

    ngOnDestroy() {
        if (this.searchQuerySub) {
            this.searchQuerySub.unsubscribe();
        }
    }

    clearSearch() {
        this.searchQuery.setValue('');
    }
}
