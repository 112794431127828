export class VmwComboboxItem<T extends {toString(): string} = string> {

    /**
     * When passing in an object as displayValue, the object should have a toString()
     * method that will be used when displaying the selected option in the text field.
     */
    public displayValue: T;
    public value: any;
    public iconShape?: string;
    public img?: string;


    constructor(displayValue: T, value: any, iconShape?: string) {
        this.displayValue = displayValue;
        this.value = value;
        this.iconShape = iconShape;

        if (hasImg(displayValue)) {
            this.img = displayValue.img;
        }
    }
}

function hasImg(obj: any): obj is imgObject {
    return typeof obj.img === 'string';
}

interface imgObject {
    img: string;
}