// Generated at 2018-08-03T19:56:20.708Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { FilesService } from './Files';
import { SampleExchangeGitHubRepository } from '../../types/SampleExchangeGitHubRepository';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class GitHubRepositoriesService {
    public static instance: GitHubRepositoriesService;

    public Files: FilesService;

    constructor(public config: TangoServiceConfiguration) {
        this.Files = new FilesService(this.config);

        if (!GitHubRepositoriesService.instance) {
            GitHubRepositoriesService.instance = this;
        }
    }

    getGitHubRepositories(federationId: string, headers: any = {}): Observable<Array<SampleExchangeGitHubRepository> | object> {
        let s = new Subject<Array<SampleExchangeGitHubRepository> | object>();

        let internalSuccessHandler = (result: Array<SampleExchangeGitHubRepository>) => {
            s.next(result.map(item => new SampleExchangeGitHubRepository(item)));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            federationId: federationId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/authors/{federationId}/gitHubRepositories', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
