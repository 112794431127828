/*
 * Mixin class decorator
 *
 * Based on
 * https://medium.com/@dmyl/mixins-as-class-decorators-in-typescript-angular2-8e09f1bc1f02
 * 
 * with minor modifications to support an opaque data object
 * stored in the object prototype as `mixinData`
 */
export function Mixin(baseCtors: Function[], data?: any) {
    return function (derivedCtor: Function) {
        baseCtors.forEach(baseCtor => {
            Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
                const descriptor = Object.getOwnPropertyDescriptor(baseCtor.prototype, name);

                if (name === 'constructor') {
                    return;
                }

                if (descriptor && (!descriptor.writable || !descriptor.configurable
                        || descriptor.get || descriptor.set)) {
                    Object.defineProperty(derivedCtor.prototype, name, descriptor);
                } else {
                    derivedCtor.prototype[name] = baseCtor.prototype[name];
                    derivedCtor.prototype.mixinData = data;
                }

            });
        });
    };
}
