// Generated at 2018-08-03T19:56:17.064Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { FileService } from './File';
import { StringService } from './String';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ConvertmarkdownService {
    public static instance: ConvertmarkdownService;

    public File: FileService;
    public String: StringService;

    constructor(public config: TangoServiceConfiguration) {
        this.File = new FileService(this.config);
        this.String = new StringService(this.config);

        if (!ConvertmarkdownService.instance) {
            ConvertmarkdownService.instance = this;
        }
    }

}
