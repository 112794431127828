import {
    Component,
    Input,
    ContentChildren,
    QueryList,
    Output,
    EventEmitter,
    Optional
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NavigationEnd, ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Converter } from 'showdown';
import { saveAs } from "file-saver";
import { forkJoin, Subscription, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { VmwDeveloperCenterTabs} from "../developer-center.component";
import { VmwSegmentService, VmwSwaggerUtil } from "@vmw/ngx-utils";

import { VmwDeveloperCenterAPIExplorerPageService } from "./api-explorer.service";

export enum APIType {
    SWAGGER,
    HTML,
}

export interface APIItem {
    url: string;
    safeUrl?: SafeResourceUrl;
    title: string;
    type: APIType;
    swaggerData?: any;
    icon?: string;
}

export enum VmwDevCenterDescriptionFormat {
    HIDDEN,  // Don't show the description
    HTML,  // Description is written using HTML
    MARKDOWN,  // Description is written using Markdown
}

@Component({
    template: "",
    selector: "vmw-developer-center-api",
})
export class VmwDeveloperCenterAPIExplorerAPIComponent {
    @Input() title: string = null;
    @Input() swaggerUrl: string;
    @Input() htmlUrl: string = null;
    @Input() basePath: string;
    @Input() host: string;
    @Input() headerMap: Map<string, string>;
    @Input() parameterMap: Map<string, string>;
    @Input() descriptionFormat: VmwDevCenterDescriptionFormat = VmwDevCenterDescriptionFormat.HIDDEN;
    @Input() enableEditor: boolean = false;
    @Input() enableTryItOut: boolean = true;
    @Input() icon: string;

    @Output() mapChanged = new EventEmitter<null>();

    public category: VmwDeveloperCenterAPIExplorerAPICategoryComponent;

    constructor(private service: VmwDeveloperCenterAPIExplorerPageService) {}

    ngOnChanges(changes: any) {
        if (changes && changes.parameterMap && changes.parameterMap.currentValue) {
            this.mapChanged.emit();
        }
    }
}

@Component({
    template: "<ng-content></ng-content>",
    selector: "vmw-developer-center-api-category",
})
export class VmwDeveloperCenterAPIExplorerAPICategoryComponent {
    @ContentChildren(VmwDeveloperCenterAPIExplorerAPIComponent)
    apis: QueryList<VmwDeveloperCenterAPIExplorerAPIComponent>;

    @Input() icon: string;
    @Input() title: string;
    @Input() expanded: boolean;

    constructor(private service: VmwDeveloperCenterAPIExplorerPageService) {}

    ngAfterContentInit() {
        this.service.categoryLoaded$
            .next(this.apis.toArray()
                .map((api: VmwDeveloperCenterAPIExplorerAPIComponent) => {
                    api.category = this;
                    return api;
                })
            );
    }
}

@Component({
    templateUrl: "./api-explorer-page.component.html",
    selector: "vmw-developer-center-api-explorer-page",
    styleUrls: ["./api-explorer-page.component.scss"],
    providers: [
        VmwDeveloperCenterAPIExplorerPageService,
        VmwSwaggerUtil,
    ],
})
export class VmwDeveloperCenterAPIExplorerPageComponent {
    @ContentChildren(VmwDeveloperCenterAPIExplorerAPIComponent)
    apis: QueryList<VmwDeveloperCenterAPIExplorerAPIComponent>;
    @ContentChildren(VmwDeveloperCenterAPIExplorerAPICategoryComponent)
    apiCategories: QueryList<VmwDeveloperCenterAPIExplorerAPICategoryComponent>;

    @Input() apiComponentMap = new Map<string, any>();
    @Input() apiData: Array<APIItem> = [];
    @Input() updateLocationHash: boolean = true;
    @Input() buildCurl: boolean = false;

    readonly VmwDevCenterDescriptionFormat = VmwDevCenterDescriptionFormat;

    public currentApi: APIItem = null;
    public currentParameterMap: Map<string, string>;
    public currentHeaderMap: Map<string, string>;
    public currentBasePath: string;
    public currentHost: string;
    public enableTryItOut: boolean;
    public apiItemsByName = new Map<string, APIItem>();
    public currentApiName: string = null;
    public error: string = null;
    public nonFatalError: string = null;
    public APIType = APIType;
    public displayHost: string;
    public authData: any[] = [];
    public baseURL: string = null;
    public description: string;
    public descriptionFormat: VmwDevCenterDescriptionFormat;
    public enableEditor: boolean = false;
    public apisByCategory: any = {};
    public apisWithNoCategory: Array<APIItem> = [];
    public downloading = false;

    private haveRoute = false;
    private mapChangedSubscriptions: Array<Subscription> = [];
    private routerSubscription: Subscription;

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private router: Router,
                private swaggerUtil: VmwSwaggerUtil,
                public service: VmwDeveloperCenterAPIExplorerPageService,
                @Optional() private segmentService: VmwSegmentService) {
        // get the current api on load
        if (route.snapshot.url && route.snapshot.url.length === 2) {
            this.currentApiName = route.snapshot.url[1].path;
            this.haveRoute = true;
        }
    }

    setApi(apiItem: APIItem, routeAdded = false) {
        if (!apiItem) {
            this.error = "Attempted to set API with undefined API item";
            return;
        }

        this.haveRoute = routeAdded;

        this.currentApi = apiItem;

        let component = this.apiComponentMap.get(apiItem.url);

        if (!this.currentApi ||
                !this.apiComponentMap ||
                !component) {
            this.error = "Requested API not found";
            return;
        }

        this.currentParameterMap = component.parameterMap;
        this.currentHeaderMap = component.headerMap;
        this.currentBasePath = component.basePath;
        this.currentHost = component.host;
        this.enableEditor = component.enableEditor;
        this.enableTryItOut = component.enableTryItOut;
        this.descriptionFormat = component.descriptionFormat;
    }

    swaggerDataLoaded(data: any, apiItem: APIItem) {
        apiItem.swaggerData = data;

        let component = this.apiComponentMap.get(apiItem.url);

        let displayHost = component.host;

        if (displayHost === "/") {
            displayHost = window.location.host;
        } else if (!displayHost &&
                apiItem.swaggerData &&
                apiItem.swaggerData.host) {
            displayHost = apiItem.swaggerData.host;
        } else if (!displayHost) {
            displayHost = '';
        }

        if (displayHost !== '' && this.currentBasePath) {
            this.baseURL = displayHost;
            if (this.baseURL.substr(-1) !== '/' && this.currentBasePath.charAt(0) !== '/') {
                this.baseURL += '/';
            }
            this.baseURL += this.currentBasePath;

        } else if (displayHost) {
            this.baseURL = displayHost;

        } else {
            this.baseURL = null;
        }

        this.authData = [];

        if (apiItem.swaggerData && apiItem.swaggerData.securityDefinitions) {
            for (let def of Object.keys(apiItem.swaggerData.securityDefinitions)) {
                const authType = apiItem.swaggerData.securityDefinitions[def].in;
                const authName = apiItem.swaggerData.securityDefinitions[def].name;
                if (authType && authName) {
                    this.authData.push({
                        type: authType,
                        name: authName,
                        value: this.currentHeaderMap ? this.currentHeaderMap.get(authName) : null,
                    });
                }
            }
        }

        this.description = null;

        if (apiItem.swaggerData && apiItem.swaggerData.info && apiItem.swaggerData.info.description) {
            if (this.descriptionFormat === VmwDevCenterDescriptionFormat.MARKDOWN) {
                let converter = new Converter();
                this.description = converter.makeHtml(apiItem.swaggerData.info.description);
            } else {
                this.description = apiItem.swaggerData.info.description;
            }
        }

        if (!!this.segmentService) {
            this.segmentService.trackEvent('NGX_Developer_Center_PageLoad_AccessAPIExplorerModule');
        }
    }

    apiRouteName(api: APIItem) {
        let route = this.apiName(api);
        // handle the case where the API Explorer tab is the first tab
        // and sometimes, we can end up with a route that does not include
        // api-explorer. In that case, add it on before the API name.
        if (this.route.snapshot.url.length === 0 ||
                (this.route.snapshot.url &&
                 this.route.snapshot.url[0] &&
                 this.route.snapshot.url[0].path !== 'api-explorer')) {
            route = 'api-explorer/' + route;
        } else if (this.haveRoute) {
            route = '../' + route;
        }
        return route;
    }

    private apiName(api: APIItem) {
        return api.title.toLowerCase()
            .replace(new RegExp(' ', 'g'), '-')
            .replace(new RegExp('/', 'g'), '-');
    }

    ngOnInit() {
        // If APIs were loaded with categories, we need to listen
        // for the category components to tell us when they're loaded.
        this.service.categoryLoaded$
            .subscribe((apis: Array<VmwDeveloperCenterAPIExplorerAPIComponent>) => {
                this.initAPIs(apis);
            });

        // watch for router events and change the mode
        this.routerSubscription = this.router.events.subscribe((re: any) => {
            if (re instanceof NavigationEnd) {
                let url = re.url;

                if (url.indexOf("#") !== -1) {
                    url = url.split("#")[0];
                }

                let bits = url.split("/");

                this.haveRoute =
                    ((bits[bits.length - 1] as VmwDeveloperCenterTabs) !== VmwDeveloperCenterTabs.APIExplorer);
            }
        });
    }

    ngAfterContentInit() {
        // APIs were added as direct children
        if (this.apis.length) {
            this.initAPIs(this.apis.toArray());
        }
    }

    initAPIs(apisArray: Array<VmwDeveloperCenterAPIExplorerAPIComponent>) {
        let swaggerAPIs = apisArray.filter(api => api.swaggerUrl);

        let htmlAPIs = apisArray.filter(api => api.htmlUrl);

        htmlAPIs.map((api: VmwDeveloperCenterAPIExplorerAPIComponent) => {
            const apiItem: APIItem = {
                url: api.htmlUrl,
                safeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(api.htmlUrl),
                type: APIType.HTML,
                title: api.title,
                icon: api.icon,
            };

            this.apiData.push(apiItem);

            this.apiComponentMap.set(api.htmlUrl, api);

            const category = api.category;

            if (category) {
                if (!this.apisByCategory[category.title]) {
                    this.apisByCategory[category.title] = [];
                }

                this.apisByCategory[category.title].push(apiItem);

            } else {
                this.apisWithNoCategory.push(apiItem);
            }
        });

        swaggerAPIs.map((api: any, index: number) => {
            // swagger-list will only do the loading if the URL is absolute
            if (!api.swaggerUrl.startsWith('http')) {
                const sep = api.swaggerUrl.startsWith('/') ? '' : '/';
                api.swaggerUrl = window.location.origin + sep + api.swaggerUrl;
            }

            let swaggerUrl = swaggerAPIs[index].swaggerUrl;

            let apiItem: APIItem = {
                title: apisArray[index].title || "Provide a title",
                type: APIType.SWAGGER,
                url: swaggerUrl,
                icon: apisArray[index].icon,
            };

            const name = this.apiName(apiItem);

            this.apiItemsByName.set(name, apiItem);

            this.apiComponentMap.set(swaggerUrl, apisArray[index]);

            this.apiData.push(apiItem);

            const category = apisArray[index].category;

            if (category) {
                if (name === this.currentApiName) {
                    category.expanded = true;
                }

                if (!this.apisByCategory[category.title]) {
                    this.apisByCategory[category.title] = [];
                }

                this.apisByCategory[category.title].push(apiItem);

            } else {
                this.apisWithNoCategory.push(apiItem);
            }

            this.mapChangedSubscriptions.push(apisArray[index].mapChanged.subscribe(() => {
                if (this.currentApi.url === swaggerUrl) {
                    this.currentParameterMap = this.apiComponentMap.get(swaggerUrl).parameterMap;
                    this.currentHeaderMap = this.apiComponentMap.get(swaggerUrl).headerMap;
                }
            }));
        });

        const api = this.apiItemsByName.get(this.currentApiName);

        if (api) {
            this.setApi(api, this.haveRoute);
        }
    }

    downloadSwagger(apiItem: APIItem) {
        this.downloading = true;

        this.swaggerUtil.getSwagger(apiItem.url).then((data: any) => {
            var blob = new Blob([JSON.stringify(data, null, 4)], {
                type: "text/plain;charset=utf-8",
            });

            saveAs(blob, this.apiName(apiItem) + ".json");

            this.downloading = false;
        });
    }

    ngOnDestroy() {
        this.mapChangedSubscriptions.map(s => s.unsubscribe());
        this.routerSubscription?.unsubscribe();
    }
}
