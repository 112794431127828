// tslint:disable

import { SampleExchangePoint } from './SampleExchangePoint';

export class SampleExchangePoints {
    public communitiesUser: string;
    public total: number;
    public points: Array<SampleExchangePoint>;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.points) {
            this.points = this.points.map(item => new SampleExchangePoint(item));
        }
    }
}
