import { HostBinding, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms'

import { VmwSimpleTranslateService } from "@vmw/ngx-utils";

import { TRANSLATIONS } from "./password-complexity-checker.l10n";

@Component({
    selector: "vmw-password-complexity-checker",
    templateUrl: "./password-complexity-checker.component.html",
    styleUrls: ["./password-complexity-checker.component.scss"],
    providers: [
        {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => VmwPasswordComplexityCheckerComponent),
          multi: true
        },
        {
          provide: NG_VALIDATORS,
          useExisting: forwardRef(() => VmwPasswordComplexityCheckerComponent),
          multi: true
        }
    ]
})
export class VmwPasswordComplexityCheckerComponent implements ControlValueAccessor {
    @Input() maxLength: number;
    @Input() minLength: number;
    @Input() needsUppercase: boolean = false;
    @Input() needsLowercase: boolean = false;
    @Input() needsNumber: boolean = false;
    @Input() needsSpecial: boolean = false;
    @Input() noHighASCII: boolean = false;
    @Input() tooltipPosition: string = "";
    @Input() allowVisibilityToggle: boolean = true;

    @Output() focus = new EventEmitter<Event>();
    @Output() blur = new EventEmitter<Event>();

    public model: string = "";
    public dirty: boolean = false;
    public visible: boolean = false;
    public invalid: boolean = false;
    public lengthValid: boolean = true;
    public hasUppercase: boolean = false;
    public hasLowercase: boolean = false;
    public hasSpecial: boolean = false;
    public hasNumber: boolean = false;
    public hasHighASCII: boolean = false;
    public specialChars = "!@#$%^&*()_+=\\[\\]\\-{|}\',./:;<>?`~";
    public length: number;

    private propagateChange = (_: any) => {};

    public constructor(public translateService: VmwSimpleTranslateService) {
         this.translateService.loadTranslationsForComponent('password-complexity', TRANSLATIONS);
    }

    modelChanged(value: string) {
        this.doValidate(value);
        this.propagateChange(value);
    }

    ngOnChanges(changes: any) {
        // force validation cycle if criteria change
        this.modelChanged(this.model);
    }

    writeValue(value: any) {
        this.model = value;
        this.doValidate(this.model);
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    doValidate(value: string) {
        this.length = value.length;
        this.lengthValid = value.length >= this.minLength && value.length <= this.maxLength;
        this.hasUppercase = value.search(/[A-Z]/) > -1;
        this.hasLowercase = value.search(/[a-z]/) > -1;
        this.hasNumber = value.search(/[0-9]/) > -1;
        this.hasSpecial = value.search(new RegExp(`[${this.specialChars}]`)) > -1;
        this.hasHighASCII = value.search(new RegExp(/[^\x00-\x7F]/)) > -1;

        this.invalid = (this.maxLength && this.minLength && !this.lengthValid) ||
            (this.needsSpecial && !this.hasSpecial) ||
            (this.needsNumber && !this.hasNumber) ||
            (this.noHighASCII && this.hasHighASCII) ||
            (this.needsUppercase && !this.hasUppercase) ||
            (this.needsLowercase && !this.hasLowercase);
    }

    validate(c: AbstractControl) {
        return this.invalid && {
            invalid: true
        };
    }

    onInput() {
        this.dirty = true;
    }
}
