import {
    ElementRef,
    Component,
    Input,
    Output,
    EventEmitter,
    HostListener,
    HostBinding,
} from "@angular/core";

import {
    VmwTasksService,
    VmwTask,
    VmwTaskState
} from "./tasks.service";

import {
    trigger,
    style,
    animate,
    transition,
    keyframes,
    group,
    query,
    animateChild
} from '@angular/animations';

import {
    multiply,
    componentPrimaryEnterCurve,
    componentPrimaryEnterTiming,
    componentPrimaryLeaveCurve,
    componentPrimaryLeaveTiming,

    linePrimaryEnterCurve,
    linePrimaryEnterTiming,
    linePrimaryEnterDelay,

    lineSecondaryEnterCurve,
    lineSecondaryEnterTiming,
    lineSecondaryEnterDelay,

    atomicPrimaryEnterTiming,
    atomicPrimaryEnterCurve,
} from "../animation-constants";

@Component({
    selector: "vmw-task",
    templateUrl: "./task.component.html",
    styleUrls: ["./task.component.scss"],
    animations: [
        // animate options in on cancel
        trigger('cancelTask', [
            transition(":enter", [
                 style({
                     opacity: 0,
                 }),
                 animate(`${multiply(atomicPrimaryEnterTiming)}ms ${atomicPrimaryEnterCurve}`, style({
                     opacity: 1
                 })),
            ]),
        ]),

        // animate new task on increment
        trigger('addTask', [
            transition(":enter", [
                style({
                    opacity: 0,
                    height: 0,
                }),
                animate(`${multiply(componentPrimaryEnterTiming)}ms ${componentPrimaryEnterCurve}`, style({
                    opacity: 1,
                    height: '*'
                })),
            ]),
        ]),

        // animate task after disappearing
        trigger('removeTask', [
            transition(":leave", [
                style({
                    opacity: 1,
                    height: "*",
                }),
                animate(`${multiply(componentPrimaryLeaveTiming)}ms ${componentPrimaryLeaveCurve}`, style({
                    opacity: 0,
                    height: 0,
                })),
            ]),
        ]),

        trigger('taskCompletedOrFailed', [
            transition("IN_PROGRESS => *", [
                group([
                    style({
                        height: '{{startHeight}}px'
                    }),
                    animate(`${multiply(atomicPrimaryEnterTiming)}ms ${atomicPrimaryEnterCurve}`, style({
                        height: '*'
                    })),
                    
                    // animate the icons
                    query('#success-icon-outline', animateChild(), {optional: true}),
                    query('#success-icon-check', animateChild(), {optional: true}),
                    query('#error-icon-outline', animateChild(), {optional: true}),
                    query('#error-icon-line', animateChild(), {optional: true}),
                    query('#error-icon-dot', animateChild(), {optional: true}),
                ])
            ]),
        ]),

        // line animation
        // success
        trigger('successIconLine', [
            transition('* => *', [
                animate(`${multiply(linePrimaryEnterTiming)}ms ${multiply(linePrimaryEnterDelay)}ms ${linePrimaryEnterCurve}`, keyframes([
                    style({ strokeDashoffset: '17', offset: 0 }),
                    style({ strokeDashoffset: '0', offset: 1.0}),
               ]))
            ]),
        ]),

        // failure
        trigger('errorIconLine', [
            transition('* => *', [
                animate(`${multiply(linePrimaryEnterTiming)}ms ${multiply(linePrimaryEnterDelay)}ms ${linePrimaryEnterCurve}`, keyframes([
                    style({ strokeDashoffset: '5.90000057220459', offset: 0 }),
                    style({ strokeDashoffset: '0', offset: 1.0}),
               ]))
            ]),
        ]),
        trigger('errorIconDot', [
            transition('* => *', [
                animate(`${multiply(lineSecondaryEnterTiming)}ms ${multiply(lineSecondaryEnterDelay)}ms ${lineSecondaryEnterCurve}`, keyframes([
                    style({ transform: 'scale(0)' }),
                    style({ transform: 'scale(1)' }),
               ]))
            ]),
        ]),

    ]
})
export class VmwTaskComponent {
    @Input() progress: number = null;
    @Input() cancelable = false;
    @Input() dismissable = true;
    @Input() dismissTimeout: number = -1;

    private _state: VmwTaskState;
    @Input() set state(newState: VmwTaskState) {
        if (newState === VmwTaskState.COMPLETED ||
                newState === VmwTaskState.ERROR) {
            this.summarized = true;

            if (this.dismissTimeout !== -1) {
                setTimeout(() => {
                    this.clear();
                }, this.dismissTimeout);
            }
        }

        this._state = newState;
    }
    get state(): VmwTaskState {
        return this._state;
    }

    @Input() summarized: boolean = true;

    @Input() @HostBinding('index') index: number;

    @Output() dismissed = new EventEmitter<number>();
    @Output() cancelled = new EventEmitter<number>();
    @Output() cleared = new EventEmitter<number>();
    @Output() inCancelConfirm = new EventEmitter<boolean>();
    @Output() focusChanged = new EventEmitter<boolean>();

    public readonly VmwTaskState = VmwTaskState;
    public hideButtons = false;
    public focused = false;
    public hidden: boolean = false;
    public StateEnum = VmwTaskState;
    public _cancelConfirm: boolean = false;
    public numTasks: number = 0;
    public removeTaskTriggered: boolean = false;

    get completedOrFailedTrigger() {
        // weird behavior using this.element.nativeElement.clientHeight;
        // use this instead.
        const el = document.querySelector(`vmw-task[ng-reflect-index="${this.index}"]`);
        let height = el ? el.clientHeight : 0;

        return {
            value: this.state,
            params: {
                startHeight: height
            }
        };
    }

    get pushCloseButton(): boolean {
        return this.index === 0;
    }

    get showProgressbar(): boolean {
        return !this.cancelConfirm && this.state === VmwTaskState.IN_PROGRESS;
    }

    get cancelConfirm() {
        return this._cancelConfirm;
    }

    set cancelConfirm(val: boolean) {
        this._cancelConfirm = val;
        this.inCancelConfirm.emit(val);
    }

    get showProgressBesideTitle(): boolean {
        return this.summarized && this.numTasks === 1;
    }

    get showProgressPercentage(): boolean {
        return this.progress !== null && this.progress !== -1;
    }

    get showIndeterminateProgress(): boolean {
        return this.progress === null || this.progress === -1;
    }

    get showExclamationIcon(): boolean {
        return this.state === VmwTaskState.ERROR ||
            this.state === VmwTaskState.CANCELLED;
    }

    get showCheckIcon(): boolean {
        return this.state === VmwTaskState.COMPLETED;
    }

    get showBackButton(): boolean {
        return !this.summarized && this.numTasks > 1;
    }

    get showClearButton(): boolean {
        return this.state !== VmwTaskState.IN_PROGRESS;
    }

    @HostListener('click')
    onClick() {
        if (this.state === VmwTaskState.IN_PROGRESS) {

            // focus this task
            if (this.numTasks > 1) {
                this.summarized = false;
                this.focused = true;
                this.focusChanged.emit(true);

            // this is the only task and we're focused, so go back to an
            // unsummarized but focused state
            } else if (this.summarized) {
                this.summarized = false;
                this.focused = true;
            }
        }
    }

    constructor(private element: ElementRef,
                private vmwTasksService: VmwTasksService) {}

    ngOnInit() {
        this.vmwTasksService.tasks$
            .subscribe((tasks: Array<VmwTask>) => {
                this.numTasks = tasks.length;
            });
    }

    dismiss() {
        this.removeTaskTriggered = true;

        setTimeout(() => {
            this.dismissed.emit(this.index);
            this.back();
        }, multiply(componentPrimaryLeaveTiming));
    }

    clear() {
        this.removeTaskTriggered = true;
        
        setTimeout(() => {
            this.cleared.emit(this.index);
            this.back();
        }, multiply(componentPrimaryLeaveTiming));
    }

    cancel() {
        this.cancelConfirm = false;

        this.cancelled.emit(this.index);

        if (this.dismissTimeout !== -1){
            setTimeout(() => {
                this.cleared.emit(this.index);
            }, this.dismissTimeout);
        }

        this.back();
    }

    back(event?: Event) {
        this.summarized = true;

        this.focusChanged.emit(false);
        
        if (event) {
            event.stopPropagation();
        }
    }
}
