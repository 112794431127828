// Generated at 2018-08-03T19:56:20.734Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { EntityService } from './Entity';
import { FlagTypesService } from './FlagTypes';
import { SampleExchangeFlag } from '../types/SampleExchangeFlag';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class FlagsService {
    public static instance: FlagsService;

    public Entity: EntityService;
    public FlagTypes: FlagTypesService;

    constructor(public config: TangoServiceConfiguration) {
        this.Entity = new EntityService(this.config);
        this.FlagTypes = new FlagTypesService(this.config);

        if (!FlagsService.instance) {
            FlagsService.instance = this;
        }
    }

    getFlagsCreatedByCurrentUser(headers: any = {}): Observable<Array<SampleExchangeFlag> | any> {
        let s = new Subject<Array<SampleExchangeFlag> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeFlag>) => {
            s.next(result.map(item => new SampleExchangeFlag(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/flags', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createFlag(body: SampleExchangeFlag, headers: any = {}): Observable<SampleExchangeFlag | any> {
        let s = new Subject<SampleExchangeFlag | any>();

        let internalSuccessHandler = (result: SampleExchangeFlag) => {
            s.next(new SampleExchangeFlag(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/flags', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeFlag(id: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/flags/{id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
