// Most of this code is taken from version 2.0.0 of
// https://github.com/auth0/angular2-jwt/blob/master/src/jwthelper.service.ts
//
// The reason for the existence of this library is to make it a simple static
// TypeScript class which simplifies consumption, for example if you want to
// use this in your E2E tests.

/**
 * @deprecated Logic has been moved to @vmw/csp-oauth2
 */
export class VmwTokenUtil {
    static getTokenExpirationDate(token: string) {
        let decoded;
        decoded = VmwTokenUtil.decodeToken(token);
        if (!decoded.hasOwnProperty('exp')) {
            return null;
        }
        let date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    static isTokenExpired(token: string, offsetSeconds?: number) {
        let date = VmwTokenUtil.getTokenExpirationDate(token);
        offsetSeconds = offsetSeconds || 0;
        if (date === null) {
            return true;
        }
        return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
    }

    static decodeToken(token: string): any {
        let parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error('The inspected token doesn\'t appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.');
        }
        let decoded = VmwTokenUtil.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Cannot decode the token.');
        }
        return JSON.parse(decoded);
    }

    static urlBase64Decode(str: string): string {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0: {
                break;
            }
            case 2: {
                output += '==';
                break;
            }
            case 3: {
                output += '=';
                break;
            }
            default: {
                throw 'Illegal base64url string!';
            }
        }

        return VmwTokenUtil.b64DecodeUnicode(output);
    }

    static b64decode(str: string): string {
        var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        var output = '';
        str = String(str).replace(/=+$/, '');
        if (str.length % 4 === 1) {
            throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
        }
        for (
        // initialize result and counters
        var bc: number = 0, bs: any = void 0, buffer: any = void 0, idx: number = 0;
        // get next character
        (buffer = str.charAt(idx++));
        // character found in table? initialize bit storage and add its ascii value;
        ~buffer &&
            ((bs = bc % 4 ? bs * 64 + buffer : buffer),
                // and if not first of each 4 characters,
                // convert the first 8 bits to one ascii character
                bc++ % 4)
            ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
            : 0) {
            // try to find character in table (0-63, not found => -1)
            buffer = chars.indexOf(buffer);
        }
        return output;
    }

    static b64DecodeUnicode(str: any) {
        return decodeURIComponent(Array.prototype.map
            .call(VmwTokenUtil.b64decode(str), function (c: any) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''));
    }
}
