export const TRANSLATIONS = {
    'en': {
        'unpin-tooltip': 'Click to unpin the panel',
        'pin-tooltip': 'Click to pin the panel',
        'close-panel': 'Click to close the panel',
    },
    'es': {
        'unpin-tooltip': 'Haga clic para liberar el panel',
        'pin-tooltip': 'Haga clic para fijar el panel',
        'close-panel': 'Haga clic para cerrar el panel.',
    },
    'de': {
        'unpin-tooltip': 'Zum LoslÃ¶sen des Bereichs klicken',
        'pin-tooltip': 'Zum Anheften des Bereichs klicken',
        'close-panel': 'Klicken Sie auf, um das Panel zu schlieÃen',
    },
    'fr': {
        'unpin-tooltip': 'Cliquez ici pour dÃ©tacher le panneau',
        'pin-tooltip': 'Cliquez ici pour Ã©pingler le panneau',
        'close-panel': 'Cliquez pour fermer le panneau',
    },
    'ja': {
        'unpin-tooltip': 'ãã³ãã¯ãªãã¯ãã¦ããã«ã®åºå®ãè§£é¤',
        'pin-tooltip': 'ãã³ãã¯ãªãã¯ãã¦ããã«ãåºå®',
        'close-panel': 'ã¯ãªãã¯ãã¦ããã«ãéãã',
    },
    'ko': {
        'unpin-tooltip': 'í´ë¦­íì¬ í¨ë ê³ ì  í´ì ',
        'pin-tooltip': 'í´ë¦­íì¬ í¨ë ê³ ì ',
        'close-panel': 'í¨ëì ë«ì¼ë ¤ë©´ í´ë¦­íì­ìì¤.',
    },
    'zh_TW': {
        'unpin-tooltip': 'æä¸ä¸ä»¥åæ¶éé¸é¢æ¿',
        'pin-tooltip': 'æä¸ä¸ä»¥éé¸é¢æ¿',
        'close-panel': 'å®æä»¥ééé¢æ¿',
    },
    'zh_CN': {
        'unpin-tooltip': 'åå»ä»¥åæ¶åºå®é¢æ¿',
        'pin-tooltip': 'åå»ä»¥åºå®é¢æ¿',
        'close-panel': 'åå»ä»¥å³é­é¢æ¿',
    },
    'it': {
        'unpin-tooltip': 'Fai clic per sbloccare il pannello',
        'pin-tooltip': 'Fai clic per bloccare il pannello',
        'close-panel': 'Fai clic per chiudere il pannello',
    },
    'nl': {
        'unpin-tooltip': 'Klik om het paneel los te maken',
        'pin-tooltip': 'Klik om het paneel vast te zetten',
        'close-panel': 'Klik om het paneel te sluiten',
    },
    'pt': {
        'unpin-tooltip': 'Clique para desafixar o painel',
        'pin-tooltip': 'Clique para fixar o painel',
        'close-panel': 'Clique para fechar o painel',
    },
    'ru': {
        'unpin-tooltip': 'ÐÐ°Ð¶Ð¼Ð¸ÑÐµ, ÑÑÐ¾Ð±Ñ Ð¾ÑÐºÑÐµÐ¿Ð¸ÑÑ Ð¿Ð°Ð½ÐµÐ»Ñ',
        'pin-tooltip': 'ÐÐ°Ð¶Ð¼Ð¸ÑÐµ, ÑÑÐ¾Ð±Ñ Ð·Ð°ÐºÑÐµÐ¿Ð¸ÑÑ Ð¿Ð°Ð½ÐµÐ»Ñ',
        'close-panel': 'ÐÐ°Ð¶Ð¼Ð¸ÑÐµ, ÑÑÐ¾Ð±Ñ Ð·Ð°ÐºÑÑÑÑ Ð¿Ð°Ð½ÐµÐ»Ñ',
    },
};
