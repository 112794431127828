import { NgModule } from "@angular/core";

import { VmwInfiniteScrollDirective } from "./infinite-scroll.directive";

@NgModule({
    declarations: [
        VmwInfiniteScrollDirective,
    ],
    exports: [
        VmwInfiniteScrollDirective,
    ],
})
export class VmwInfiniteScrollDirectiveModule {
}
