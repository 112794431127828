import * as dompurify from 'dompurify';

const ORIGINAL_COLORS = 'original';

export enum VmwSvgIconColorBrightness {
    NORMAL = 1,
    BRIGHTER = 2,
    BRIGHTEST = 3
};

const DEFAULT_LINK_ICON_SIZE_PX = 20;

var svgRegex = /(<svg)([^<]*|[^>]*)/;

export class VmwSvgIconFormatter {
    public static Brightness = VmwSvgIconColorBrightness;
    private svgElement: SVGElement | HTMLElement | Element;
    private title: string;
    private iconSize: number;
    private originalTitle: string;
    private originalWidth: string;
    private originalHeight: string;
    private isSvg: boolean = false;
    private isLink: boolean = false;
    private invertColors: boolean = false;
    private grayscale: boolean = false;
    private brightness: VmwSvgIconColorBrightness = VmwSvgIconColorBrightness.NORMAL;
    private svgLoadedResolver: Function;
    private svgLoadedRejecter: Function;
    private color: string;

    constructor(svgElement: SVGElement | HTMLElement | Element, svgString: string, title = '', iconSize = DEFAULT_LINK_ICON_SIZE_PX) {;
        this.title = title;
        this.iconSize = iconSize;

        if (svgElement) {
            this.setSvgElement(svgElement, svgString);
        }
    }

    /**
     * Sets the element over which the foratting operations are performed
     * @param svgElement
     *
     */
    public setSvgElement(containerElement: SVGElement | HTMLElement | Element, svgString: string) {
        this.isLink = false;
        const isValidSvgString = svgRegex.test(svgString);
        let svgIcon = svgString;

        if (!isValidSvgString) {
            try {
                svgIcon = decodeURIComponent(atob(svgString));
            } catch {
                this.isLink = true;
            }
        }

        const isDecodedSvgStringValid = svgRegex.test(svgIcon);
        if (!isDecodedSvgStringValid) {
            this.isLink = true;
        }

        this.isSvg = !this.isLink;

        let formattedElement: any;

        if (this.isSvg){
            formattedElement = document.createElement('span');
            formattedElement.style.minWidth = `${this.iconSize}px`;
            formattedElement.style.minHeight = `${this.iconSize}px`;
            formattedElement.style.display = 'inline-grid';
            formattedElement.innerHTML = dompurify.sanitize(svgIcon);
        }

        if (this.isLink) {
            formattedElement = document.createElement('img');
            formattedElement.src = svgIcon;
            formattedElement.alt = this.title;
            formattedElement.height = this.iconSize;
            formattedElement.width = this.iconSize;
            formattedElement.style.display = 'none';
        }
        const originalElement = containerElement.firstChild;

        if (originalElement) {
            containerElement.replaceChild(formattedElement, originalElement);
        } else {
            containerElement.appendChild(formattedElement);
        }

        if(this.isLink) {
            formattedElement.onerror = () => {
                if (typeof this.svgLoadedRejecter === 'function') {
                    containerElement.removeChild(formattedElement);
                    this.svgLoadedRejecter();
                }
            };
            formattedElement.onload = () => {
                formattedElement.style.display = 'inline';
                this.finalHandler(formattedElement);
            };
        } else {
            this.finalHandler(formattedElement);
        }
    }

    public setFallbackElement(element: HTMLElement) {
        this.isSvg = true;
        this.svgElement = element.querySelector('svg');
    }

    private finalHandler(formattedElement: HTMLElement) {
        this.svgElement = this.isSvg ? formattedElement.firstElementChild: formattedElement;

        if (typeof this.svgLoadedResolver === 'function') {
            this.svgLoadedResolver(this.svgElement);
        }

        this.originalHeight = null;
        this.originalWidth = null;
        this.saveOriginalIconSize()

        if (this.title) {
            this.setTitle(this.title);
        }

        if (this.iconSize) {
            this.setIconSize(this.iconSize);
        }
    }

    /**
     * Reset to original colors
     */
    public resetColorFormatting() {
        this.resetColors();
        this.resetColorFilters();
    }

    /**
     * Changes the size of the svg icon
     * @param iconSize
     */
    public setIconSize(iconSize: number) {
        if (this.svgElement && iconSize) {
            this.svgElement.setAttribute('width', iconSize.toString());
            this.svgElement.setAttribute('height', iconSize.toString());
        } else {
            this.resetIconSize();
        }
    }

    /**
     * Save the original icon dimensions
     */
    saveOriginalIconSize() {
        if (this.svgElement) {
            const originalWidthValue = this.svgElement.getAttribute('width');
            const originalHeightValue = this.svgElement.getAttribute('height');

            if (!this.originalWidth && !!originalWidthValue) {
                this.originalWidth = originalWidthValue;
            }

            if (!this.originalHeight && !!originalHeightValue) {
                this.originalHeight = originalHeightValue;
            }
        }
    }
    /**
     * Sets the size of the icon to the original values
     */
    public resetIconSize() {
        if (this.svgElement) {
            if (!!this.originalWidth) {
                this.svgElement.setAttribute('width', this.originalWidth);
            } else {
                this.svgElement.removeAttribute('width');
            }

            if (!!this.originalHeight) {
                this.svgElement.setAttribute('height', this.originalHeight);
            } else {
                this.svgElement.removeAttribute('height');
            }
        }
    }

    /**
     * Checks if svg element has applied 'fill' or 'stroke' by attribute, class or style
     * @param elem
     * @param attributeName
     * @return boolean - whether the svg element has 'fill' or 'stroke' applied.
     */
    public shouldOverrideStyle(elem: SVGElement, attributeName: 'fill' | 'stroke'): boolean {
        if (elem) {
            const attribute = elem.getAttribute(attributeName);

            if (attribute && attribute !== 'none') {
                return true;
            }

            if (elem.style && elem.style[attributeName] && elem.style[attributeName] !== 'none') {
                return true;
            }

            const computedCssStyles = getComputedStyle(elem);
            const hasAttribute = computedCssStyles[attributeName] !== 'none' && computedCssStyles[attributeName] !== '';

            if (hasAttribute) {
                return true;
            }
        }

        return false;
    }

    /**
     * Sets all svg elements to the desired color
     * @param color
     */
    public setColor(color: string) {
        this.color = color;
        this.applyColorFormatting();
    }
    public setColorFilters(invertColors: boolean, grayscale: boolean, brightness: VmwSvgIconColorBrightness) {
        this.invertColors = invertColors;
        this.grayscale = grayscale;
        this.brightness = brightness;
        this.applyColorFormatting();
    }

    public svgLoaded: Promise<HTMLElement> = new Promise((resolve: Function, reject: Function) => {
        this.svgLoadedResolver = resolve;
        this.svgLoadedRejecter = reject;
    });

    /**
     * Resets original icon colors
     */
    public resetColors() {
        this.changeColor(ORIGINAL_COLORS);
        this.applyColorFilters();
    }

    /**
     * Applies appropriate color filtering
     */
    private applyColorFormatting() {
        if (this.isSvg && this.color){
            this.resetColorFormatting();
            this.changeColor(this.color);
        } else {
            this.applyColorFilters();
        }
    }

    private applyColorFilters() {
        let valueArray = [];

        if (this.invertColors) {
            valueArray.push('invert(1)');
        }

        if (this.grayscale) {
            valueArray.push('grayscale(1)');
        }

        if (this.brightness) {
            valueArray.push(`brightness(${this.brightness})`);
        }

        this.applyOrResetStyle(this.svgElement as SVGElement, 'filter', valueArray.join(' '));
    }

    public resetColorFilters() {
        this.applyOrResetStyle(this.svgElement as SVGElement, 'filter');
    }

    public setTitle(title: string) {
        if (this.svgElement && this.isSvg) {
            this.setSVGTitle(title);
        }

        if (this.svgElement && !this.isSvg) {
            this.setImgTitle(title);
        }
    }

    /**
     * Changes the title of the svg icon
     * @param title
     */
    private setSVGTitle(title: string): void {
        if (title) {
            const titleEl = Array.from(this.svgElement.children)
                .filter(child => child.tagName.toLocaleLowerCase() === 'title')
                .pop();

            if (titleEl) {
                if(!this.originalTitle && !!titleEl.innerHTML) {
                    this.originalTitle = titleEl.innerHTML;
                }
                titleEl.innerHTML = title;
            } else {
                const newTitle = document.createElement('title');
                newTitle.innerHTML = title;
                this.svgElement.insertBefore(newTitle, this.svgElement.firstChild);
            }

        } else if (this.originalTitle) {
            this.setSVGTitle(this.originalTitle);
            this.originalTitle = null;
        }
    }

    /**
     * Changes the title of the svg icon
     * @param title
     */
    private setImgTitle(title: string): void {
        if (title) {
            const altAttribute = this.svgElement.getAttribute('alt');

            if(!this.originalTitle && altAttribute) {
                this.originalTitle = altAttribute;
            }

            this.svgElement.setAttribute('alt', title);

        } else if (this.originalTitle) {
            this.setImgTitle(this.originalTitle);
            this.originalTitle = null;
        }
    }

    /**
     * Formats recursicely every element in sub-tree applying format function
     * @param elem
     * @param formatFn
     */
    private applyFormatRecursively(elem: Element, formatFn: (elem: SVGElement) => void) {
        if (elem) {
            for (let i = 0; i < elem.children.length; ++i) {
                let childElem: SVGElement = elem.children.item(i) as SVGElement;
                formatFn(childElem);
                this.applyFormatRecursively(childElem, formatFn);
            }
        }
    }

    private formatFn = (color: string) => (elem: SVGElement) => {
        const colorValue = color && color !== ORIGINAL_COLORS ? color : null;

        if (this.shouldOverrideStyle(elem, 'stroke')) {
            this.applyOrResetStyle(elem, 'stroke', colorValue);
        }

        if (this.shouldOverrideStyle(elem, 'fill')) {
            this.applyOrResetStyle(elem, 'fill', colorValue);
        }
    };


    /**
     * Changes the 'fill' and 'stroke' to white when theme changed to Dark
     */
    private changeColor(color: string): void {
        if (!this.svgElement) {
            return;
        }

        this.applyFormatRecursively(this.svgElement, this.formatFn(color));
    }

    /**
     * Applies or resets inline css style of an element
     * @param element
     * @param styleKey
     * @param value
     */
    private applyOrResetStyle (elem: HTMLElement | SVGElement, styleKey: string, value?: string) {
        if (elem) {
            const originalValue = elem.getAttribute(`data-${styleKey}Original`);
            if (value) {
                if (!!elem.style[styleKey] && !elem.getAttribute(`data-${styleKey}Overriden`)) {
                    elem.setAttribute(`data-${styleKey}Original`, elem.style[styleKey]);
                }
                elem.style[styleKey] = value;
                elem.setAttribute(`data-${styleKey}Overriden`, 'yes');
            } else {
                elem.style[styleKey] = originalValue;
                elem.removeAttribute(`data-${styleKey}Original`);
                elem.removeAttribute(`data-${styleKey}Overriden`);
            }
        }
    }
}
