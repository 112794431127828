// Generated at 2018-08-03T19:56:20.703Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangePoints } from '../../../types/SampleExchangePoints';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class PointsService {
    public static instance: PointsService;


    constructor(public config: TangoServiceConfiguration) {

        if (!PointsService.instance) {
            PointsService.instance = this;
        }
    }

    getAuthorPoints(communitiesUserId: string, headers: any = {}): Observable<SampleExchangePoints | object> {
        let s = new Subject<SampleExchangePoints | object>();

        let internalSuccessHandler = (result: SampleExchangePoints) => {
            s.next(new SampleExchangePoints(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            communitiesUserId: communitiesUserId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/authors/communitiesUser/{communitiesUserId}/points', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
