// Generated at 2018-08-03T19:56:16.944Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXApi } from '../../../types/APIXApi';
import { APIXErrorResponse } from '../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ArtifactsService {
    public static instance: ArtifactsService;


    constructor(public config: TangoServiceConfiguration) {

        if (!ArtifactsService.instance) {
            ArtifactsService.instance = this;
        }
    }

    getApiByArtifactId(artifact_id: string, headers: any = {}): Observable<APIXApi | APIXErrorResponse> {
        let s = new Subject<APIXApi | APIXErrorResponse>();

        let internalSuccessHandler = (result: APIXApi) => {
            s.next(new APIXApi(result));
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            artifact_id: artifact_id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/apix/apis/artifacts/{artifact_id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
