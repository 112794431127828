// tslint:disable

import { APIXApi } from './APIXApi';

export class APIXApiDef {
    public id: number;
    public complete: boolean;
    public api_uid: string;
    public name: string;
    public description: string;
    public technology: string;
    public api_releases: Array<APIXApi>;
    public created_date: string;
    public lastModified: string;
    public updatedBy: string;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.api_releases) {
            this.api_releases = this.api_releases.map(item => new APIXApi(item));
        }
    }
}
