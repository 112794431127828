// tslint:disable


export class SampleExchangeGroup {
    public id: number;
    public sampleId: number;
    public name: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
