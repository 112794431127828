// Generated at 2018-08-03T19:56:20.742Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AssignmentsService } from './Assignments';
import { CategoryService } from './Category';
import { SolutionService } from './Solution';
import { TagService } from './Tag';
import { SampleExchangeRequest } from '../types/SampleExchangeRequest';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class RequestsService {
    public static instance: RequestsService;

    public Assignments: AssignmentsService;
    public Category: CategoryService;
    public Solution: SolutionService;
    public Tag: TagService;

    constructor(public config: TangoServiceConfiguration) {
        this.Assignments = new AssignmentsService(this.config);
        this.Category = new CategoryService(this.config);
        this.Solution = new SolutionService(this.config);
        this.Tag = new TagService(this.config);

        if (!RequestsService.instance) {
            RequestsService.instance = this;
        }
    }

    getRequests(headers: any = {}): Observable<Array<SampleExchangeRequest> | object> {
        let s = new Subject<Array<SampleExchangeRequest> | object>();

        let internalSuccessHandler = (result: Array<SampleExchangeRequest>) => {
            s.next(result.map(item => new SampleExchangeRequest(item)));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.get('/requests', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    createRequest(body: SampleExchangeRequest, headers: any = {}): Observable<SampleExchangeRequest | object> {
        let s = new Subject<SampleExchangeRequest | object>();

        let internalSuccessHandler = (result: SampleExchangeRequest) => {
            s.next(new SampleExchangeRequest(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/requests', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateRequest(body: SampleExchangeRequest, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.put('/requests', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeRequest(id: string, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/requests/{id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
