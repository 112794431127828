/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */

import {
    AfterViewInit,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    EventEmitter, HostListener,
    Injector,
    Input,
    OnDestroy,
    Output,
    Renderer2
} from '@angular/core';
import { debounceTime } from "rxjs/operators";
import { VmwSplitterLayoutComponent } from "./splitter-layout.component";
import {
    DEFAULT_PANEL_SIZE,
    DEFAULT_ELEMENT_COLLAPSE_SIZE,
    DEFAULT_MIN_PANEL_SIZE,
    VmwSplitterElementProperties,
    VmwSplitterConfigOptions
} from "./splitter.interface";
import { VmwSplitterService } from "./splitter.service";
import { VmwSplitterModule } from "./splitter.module";

@Directive({
    selector: '[vmwSplitter]',
    providers: [VmwSplitterService]
})
export class VmwSplitterDirective implements AfterViewInit, OnDestroy {

    @Input('vmwSplitter') splitterConfigOptions: VmwSplitterConfigOptions;

    @Output() vmwSplitterSetSizes: EventEmitter<number[]> = new EventEmitter();
    @Output() vmwSplitterSizeChange: EventEmitter<number[]> = new EventEmitter();
    @Output() vmwSplitterCollapse: EventEmitter<number> = new EventEmitter();
    @Output() vmwSplitterRestore: EventEmitter<void> = new EventEmitter();
    @Output() vmwSplitterDragChange = new EventEmitter();
    @Output() vmwSplitterCollapseChange: EventEmitter<number> = new EventEmitter();

    splitterContainer: VmwSplitterLayoutComponent;
    splitElements: HTMLElement[];

    get collapsed(): boolean {
        return this.splitterContainer.collapsed;
    }

    constructor(private renderer: Renderer2,
                private elementRef: ElementRef,
                private resolver: ComponentFactoryResolver,
                private injector: Injector,
                private vmwSplitterService: VmwSplitterService) {
    }

    ngOnDestroy() {
        this.splitterContainer.unsubscribePreDraggingEvent();
    }

    ngAfterViewInit() {
        const [startSplitElement, endSplitElement] = this.elementRef.nativeElement.children;

        this.splitElements = [startSplitElement, endSplitElement];
        this.createSplitterView(this.splitElements);
        this.vmwSplitterService.initializeOptions(this.splitterContainer, this.splitterConfigOptions);
        this.split(this.splitElements);
        this.splitterContainer.updateViewport();
    }

    /**
     * Using Splitter layout and uses panel elements, gutter element to create the splitter view
     */
    private createSplitterView(elements: HTMLElement[]): void {
        const factory = this.resolver.resolveComponentFactory(VmwSplitterLayoutComponent);
        const viewContent: HTMLElement[][] = this.getViewContent(elements);
        const viewRef = factory.create(this.injector, viewContent);
        viewRef.hostView.detectChanges();
        this.splitterContainer = viewRef.instance;
        const {nativeElement} = viewRef.location;
        this.splitterContainer.splitterRef = nativeElement;
        this.renderer.appendChild(this.elementRef.nativeElement, this.splitterContainer.splitterRef);
        this.splitterContainer.gutter = this.splitterContainer.gutterRef.nativeElement;
        this.splitterContainer.vmwSplitterDragChange.subscribe(() => {
            this.vmwSplitterDragChange.emit();
        });
        this.splitterContainer.vmwSplitterSizeChange.subscribe(() => {
            this.vmwSplitterSizeChange.emit(this.vmwSplitterService.getSizes(this.splitterContainer));
        });
        this.splitterContainer.vmwSplitterCollapseChange.subscribe((elementIndex: number) => {
            this.vmwSplitterCollapseChange.emit(elementIndex);
        });

    }

    /**
     * Returns the set of elements used as view content for the splitter layout
     */
    private getViewContent(elements: HTMLElement[]): HTMLElement[][] {
        const [startSplitElement, endSplitElement] = elements;
        return [[startSplitElement], [endSplitElement]];
    }

    /**
     * Sets up the initial panel sizes, subscribe to drag start events,
     * Prepares for enabling dragging of the splitter
     */
    private split(elementRefs: HTMLElement[]): void {

        const gutterSizes: number[] = [];
        const totalGutterSize = this.splitterContainer.splitPairProperties.gutterSize;
        const gutterAlign = this.splitterContainer.splitPairProperties.gutterAlign;
        gutterSizes[0] = this.vmwSplitterService.getGutterSize(totalGutterSize, true, gutterAlign);
        gutterSizes[1] = this.vmwSplitterService.getGutterSize(totalGutterSize, false, gutterAlign);
        this.splitterContainer.splitElements = elementRefs.map((elem: HTMLElement, index: number): VmwSplitterElementProperties => {
            return {
                index,
                element: elem,
                size: this.splitterConfigOptions.sizes ? this.splitterConfigOptions.sizes[index] : DEFAULT_PANEL_SIZE,
                minSize: this.splitterConfigOptions.minSizes ? this.splitterConfigOptions.minSizes[index] : DEFAULT_MIN_PANEL_SIZE,
                collapseSize: this.splitterConfigOptions.collapseSizes ? this.splitterConfigOptions.collapseSizes[index] : DEFAULT_ELEMENT_COLLAPSE_SIZE,
                gutterSize: gutterSizes[index],
                collapseRestoreSize: 0
            };
        });

        this.splitterContainer.subscribePreDraggingEvent();

        const [startSplitElement, endSplitElement] = this.splitterContainer.splitElements;
        this.splitterContainer.resizePanelElement(startSplitElement.element, startSplitElement.size, gutterSizes[0]);
        this.splitterContainer.resizePanelElement(endSplitElement.element, endSplitElement.size, gutterSizes[1]);
        this.splitterContainer.setGutterSize(totalGutterSize);

        this.splitterContainer.vmwSplitterSizeChange.emit([startSplitElement.size, endSplitElement.size]);

        this.vmwSplitterSetSizes.subscribe((sizes: number[]) => {
            this.vmwSplitterService.setSizes(this.splitterContainer, sizes);
        });

        this.vmwSplitterCollapse.subscribe((elementIndex: number) => {
            if (!this.splitterContainer.collapsed) {
                this.vmwSplitterService.adjustToCollapsed(this.splitterContainer, this.splitterContainer.splitElements[elementIndex]);
                this.splitterContainer.collapsed = true;
            }
        });

        this.vmwSplitterRestore.subscribe(() => {
            if (this.splitterContainer.collapsed) {
                this.vmwSplitterService.restoreFromCollapsedState(this.splitterContainer);
                this.splitterContainer.collapsed = false;
            }
        });
    }
}
