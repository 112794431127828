// tslint:disable

import { SampleExchangeEntity } from './SampleExchangeEntity';

export class SampleExchangeEvent {
    public id: number;
    public type: string;
    public entity: SampleExchangeEntity;
    public created: string;
    constructor(json: any) {
        Object.assign(this, json);
        this.entity = new SampleExchangeEntity(this.entity);
    }
}
