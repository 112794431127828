// Generated at 2018-08-03T19:56:20.687Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AdminService } from './Admin';
import { CommunitiesUserService } from './CommunitiesUser';
import { GistsService } from './Gists';
import { GitHubRepositoriesService } from './GitHubRepositories';
import { SampleExchangeAuthor } from '../types/SampleExchangeAuthor';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class AuthorsService {
    public static instance: AuthorsService;

    public Admin: AdminService;
    public CommunitiesUser: CommunitiesUserService;
    public Gists: GistsService;
    public GitHubRepositories: GitHubRepositoriesService;

    constructor(public config: TangoServiceConfiguration) {
        this.Admin = new AdminService(this.config);
        this.CommunitiesUser = new CommunitiesUserService(this.config);
        this.Gists = new GistsService(this.config);
        this.GitHubRepositories = new GitHubRepositoriesService(this.config);

        if (!AuthorsService.instance) {
            AuthorsService.instance = this;
        }
    }

    createAuthor(body: SampleExchangeAuthor, headers: any = {}): Observable<SampleExchangeAuthor | object> {
        let s = new Subject<SampleExchangeAuthor | object>();

        let internalSuccessHandler = (result: SampleExchangeAuthor) => {
            s.next(new SampleExchangeAuthor(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/authors', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    updateAuthor(body: SampleExchangeAuthor, headers: any = {}): Observable<SampleExchangeAuthor | object> {
        let s = new Subject<SampleExchangeAuthor | object>();

        let internalSuccessHandler = (result: SampleExchangeAuthor) => {
            s.next(new SampleExchangeAuthor(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.put('/authors', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getAuthor(federationId: string, headers: any = {}): Observable<SampleExchangeAuthor | object> {
        let s = new Subject<SampleExchangeAuthor | object>();

        let internalSuccessHandler = (result: SampleExchangeAuthor) => {
            s.next(new SampleExchangeAuthor(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            federationId: federationId,
        };

        let queryParams = {};

        let result = this.config.transport.get('/authors/{federationId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
