// Generated at 2018-08-03T19:56:17.046Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { APIXApi } from '../../../types/APIXApi';
import { APIXErrorResponse } from '../../../types/APIXErrorResponse';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class ApisService {
    public static instance: ApisService;


    constructor(public config: TangoServiceConfiguration) {

        if (!ApisService.instance) {
            ApisService.instance = this;
        }
    }

    addApiToSdk(sdk_id: string, api_id: string, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sdk_id: sdk_id,
            api_id: api_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.post('/apix/sdks/{sdk_id}/apis/{api_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    removeApiFromSdk(sdk_id: string, api_id: string, headers: any = {}): Observable<object | APIXErrorResponse> {
        let s = new Subject<object | APIXErrorResponse>();

        let internalSuccessHandler = (result: object) => {
            s.next(result);
            s.complete();
        };

        let failureHandler = (error: APIXErrorResponse) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sdk_id: sdk_id,
            api_id: api_id,
        };

        let body = {};
        let queryParams = {};

        let result = this.config.transport.delete('/apix/sdks/{sdk_id}/apis/{api_id}', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
