export const TRANSLATIONS = {
    'en': {
        'loading': 'Loading',
        'clear': 'Clear',
        'open-menu': 'Open menu',
        'validation-failed': 'Validation failed',
    },
    'de': {
        'loading': 'Wird geladen',
        'clear': 'LÃ¶schen',
        'open-menu': 'MenÃ¼ Ãffnen',
        'validation-failed': 'Validierung fehlgeschlagen',
    },
    'es': {
        'loading': 'Cargando',
        'clear': 'Anula',
        'open-menu': 'MenÃº Abrir',
        'validation-failed': 'Se produjo un error en la validaciÃ³n',
    },
    'fr': {
        'loading': 'Chargement',
        'clear': 'Effacer',
        'open-menu': 'Ouvrir le menu',
        'validation-failed': 'Ãchec de la validation',
    },
    'ja': {
        'loading': 'ã­ã¼ããã¦ãã¾ã',
        'clear': 'ã¯ãªã¢',
        'open-menu': 'ã¡ãã¥ã¼ãéã',
        'validation-failed': 'æ¤è¨¼ã«å¤±æãã¾ãã',
    },
    'ko': {
        'loading': 'ë¡ë ì¤',
        'clear': 'ì§ì°ê¸°',
        'open-menu': 'ì´ê¸° ë©ë´',
        'validation-failed': 'ì í¨ì± ê²ì¬ì ì¤í¨íìµëë¤',
    },
    'zh_TW': {
        'loading': 'æ­£å¨è¼å¥',
        'clear': 'æ¸é¤',
        'open-menu': 'éååè½è¡¨ ',
        'validation-failed': 'é©è­å¤±æ',
    },
    'zh_CN': {
        'loading': 'æ­£å¨å è½½',
        'clear': 'æ¸é¤',
        'open-menu': 'æå¼èå',
        'validation-failed': 'éªè¯å¤±è´¥',
    },
    'it': {
        'loading': 'Caricamento',
        'clear': 'Cancella',
        'open-menu': 'Apri menu',
        'validation-failed': 'Convalida non riuscita',
    },
    'nl': {
        'loading': 'Laden',
        'clear': 'Wissen',
        'open-menu': 'Menu openen',
        'validation-failed': 'Validatie mislukt',
    },
    'pt': {
        'loading': 'Carregando',
        'clear': 'Limpar',
        'open-menu': 'Abrir menu',
        'validation-failed': 'Falha na validaÃ§Ã£o',
    },
    'ru': {
        'loading': 'ÐÐ°Ð³ÑÑÐ·ÐºÐ°',
        'clear': 'ÐÑÐ¸ÑÑÐ¸ÑÑ',
        'open-menu': 'ÐÑÐºÑÑÑÑ Ð¼ÐµÐ½Ñ',
        'validation-failed': 'ÐÑÐ¾Ð²ÐµÑÐºÐ° Ð½Ðµ Ð¿ÑÐ¾Ð¹Ð´ÐµÐ½Ð°',
    },
};
