// Generated at 2018-08-03T19:56:20.715Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { SampleExchangeContribution } from '../../types/SampleExchangeContribution';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';



export class InfoService {
    public static instance: InfoService;


    constructor(public config: TangoServiceConfiguration) {

        if (!InfoService.instance) {
            InfoService.instance = this;
        }
    }

    getContributionDefaults(body: SampleExchangeContribution, headers: any = {}): Observable<SampleExchangeContribution | any> {
        let s = new Subject<SampleExchangeContribution | any>();

        let internalSuccessHandler = (result: SampleExchangeContribution) => {
            s.next(new SampleExchangeContribution(result));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };

        let queryParams = {};

        let result = this.config.transport.post('/contributions/info', pathParams,
            queryParams, body, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
