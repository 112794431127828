/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */

import { Injectable } from "@angular/core";
import {
    DEFAULT_GUTTER_SIZE,
    VmwSplitterDirection,
    DEAFULT_SNAP_OFFSET,
    VmwSplitterElementProperties,
    VmwSplitterGutterAlign,
    NOOP,
    VmwSplitterConfigOptions
} from "./splitter.interface";
import { VmwSplitterLayoutComponent } from "./splitter-layout.component";

@Injectable()
export class VmwSplitterService {

    /**
     * Initializes the properties for the set of panels
     */
    initializeOptions(splitContainer: VmwSplitterLayoutComponent, splitterConfigOptions: VmwSplitterConfigOptions): void {
        splitContainer.direction = splitterConfigOptions.direction ? splitterConfigOptions.direction : VmwSplitterDirection.Horizontal;
        splitContainer.splitPairProperties = {
            size: 0,
            start: 0,
            end: 0,
            dragOffset: 0,
            snapOffset: DEAFULT_SNAP_OFFSET,
            gutterStartDragging: NOOP,
            dimension: 'width',
            clientAxis: 'clientX',
            position: 'left',
            positionEnd: 'right',
            move: NOOP,
            stop: NOOP,
            dragStartEvents: [],
            dragEvents: [],
            dragStopEvents: [],
            elementDragEvents: [],
            gutterSize: splitterConfigOptions.gutterSize ? splitterConfigOptions.gutterSize : DEFAULT_GUTTER_SIZE,
            gutterAlign: splitterConfigOptions.gutterAlign ? splitterConfigOptions.gutterAlign : VmwSplitterGutterAlign.center,
            dragInterval: splitterConfigOptions.dragInterval ? splitterConfigOptions.dragInterval : 1,
        };

        if (splitContainer.direction === VmwSplitterDirection.Horizontal) {
            splitContainer.splitPairProperties.dimension = 'width';
            splitContainer.splitPairProperties.clientAxis = 'clientX';
            splitContainer.splitPairProperties.position = 'left';
            splitContainer.splitPairProperties.positionEnd = 'right';
        } else if (splitContainer.direction === VmwSplitterDirection.Vertical) {
            splitContainer.splitPairProperties.dimension = 'height';
            splitContainer.splitPairProperties.clientAxis = 'clientY';
            splitContainer.splitPairProperties.position = 'top';
            splitContainer.splitPairProperties.positionEnd = 'bottom';
        }
    }

    /**
     * To update the total size of the 'panels + guttter', start and end positions
     */
    updateSplitPairSize(splitterContainer: VmwSplitterLayoutComponent): void {
        const [startSplitElement, endSplitElement] = splitterContainer.splitElements;
        const startSplitElementBounds = startSplitElement.element.getBoundingClientRect();
        const endSplitElementBounds = endSplitElement.element.getBoundingClientRect();
        splitterContainer.splitPairProperties.size = startSplitElementBounds[splitterContainer.splitPairProperties.dimension] +
            endSplitElementBounds[splitterContainer.splitPairProperties.dimension] + startSplitElement.gutterSize + endSplitElement.gutterSize;
        splitterContainer.splitPairProperties.start = startSplitElementBounds[splitterContainer.splitPairProperties.position];
        splitterContainer.splitPairProperties.end = startSplitElementBounds[splitterContainer.splitPairProperties.positionEnd];
    }

    /**
     * To calculate the new sizes of both the panels based on the offset passed. Used when dragging in action.
     */
    adjust(splitterContainer: VmwSplitterLayoutComponent, offset: number): void {
        const [startSplitElement, endSplitElement] = splitterContainer.splitElements;
        const percentage = startSplitElement.size + endSplitElement.size;
        const newSize = (offset / splitterContainer.splitPairProperties.size) * percentage;
        startSplitElement.size = newSize;
        endSplitElement.size = percentage - newSize;
        splitterContainer.resizePanelElement(startSplitElement.element, startSplitElement.size,
            startSplitElement.gutterSize, startSplitElement.index);
        splitterContainer.resizePanelElement(endSplitElement.element, endSplitElement.size,
            endSplitElement.gutterSize, endSplitElement.index);
        this.updateSplitPairSize(splitterContainer);

        splitterContainer.vmwSplitterSizeChange.emit([startSplitElement.size, endSplitElement.size]);
    }

    /**
     * To vmwSplitterCollapse the panel to its preferred collapsed size ( note: different from user draggable minSize )
     */
    adjustToCollapsed(splitterContainer: VmwSplitterLayoutComponent, element: VmwSplitterElementProperties): void {
        const [startSplitElement, endSplitElement] = splitterContainer.splitElements;
        this.updateSplitPairSize(splitterContainer);

        const size = (element.index === 1)
            ? splitterContainer.splitPairProperties.size - element.collapseSize - endSplitElement.gutterSize
            : element.collapseSize + startSplitElement.gutterSize;

        // recall last known size prior to collapsing, with safety of minimum size should the current size somehow be zero
        startSplitElement.collapseRestoreSize = startSplitElement.size || startSplitElement.minSize;
        endSplitElement.collapseRestoreSize = endSplitElement.size || endSplitElement.minSize;

        this.adjust(splitterContainer, size);
        splitterContainer.vmwSplitterCollapseChange.emit(element.index);
    }

    /**
     * To restore panels from collapsed state.
     */
    restoreFromCollapsedState(splitterContainer: VmwSplitterLayoutComponent): void {
        const [startSplitElement, endSplitElement] = splitterContainer.splitElements;
        this.updateSplitPairSize(splitterContainer);
        if (startSplitElement.collapseRestoreSize && endSplitElement.collapseRestoreSize) {
            startSplitElement.size = startSplitElement.collapseRestoreSize;
            endSplitElement.size = endSplitElement.collapseRestoreSize;
            splitterContainer.resizePanelElement(startSplitElement.element, startSplitElement.size,
                startSplitElement.gutterSize, startSplitElement.index);
            splitterContainer.resizePanelElement(endSplitElement.element, endSplitElement.size,
                endSplitElement.gutterSize, endSplitElement.index);

            this.updateSplitPairSize(splitterContainer);

            splitterContainer.vmwSplitterSizeChange.emit([startSplitElement.size, endSplitElement.size]);
        }
    }

    /**
     * Returns the sizes of the panels
     */
    getSizes(splitterContainer: VmwSplitterLayoutComponent): number[] {
        return splitterContainer.splitElements.map(element => element.size);
    }

    /**
     * Sets the new sizes of the panels
     */
    setSizes(splitterContainer: VmwSplitterLayoutComponent, newSizes: number[]): void {
        const [startSplitElement, endSplitElement] = splitterContainer.splitElements;
        startSplitElement.size = newSizes[0];
        endSplitElement.size = newSizes[1];
        splitterContainer.resizePanelElement(startSplitElement.element, startSplitElement.size, startSplitElement.gutterSize);
        splitterContainer.resizePanelElement(endSplitElement.element, endSplitElement.size, endSplitElement.gutterSize);
    }

    /**
     * calculates gutter size with respect to its panel, depending on gutter alignment.
     */
    getGutterSize(gutterSize: number, isFirst: boolean, gutterAlign: VmwSplitterGutterAlign): number {
        // If the gutter alignment = center, the it is equally divided between the two panels
        if (gutterAlign === VmwSplitterGutterAlign.center) {
            return gutterSize / 2;
        }
        // If the gutter alignment = start then entire gutter is part of the start panel
        // If the gutter alignment = end then entire gutter is part of the end panel
        else if ((isFirst && gutterAlign === VmwSplitterGutterAlign.end) || (!isFirst && gutterAlign === VmwSplitterGutterAlign.start)) {
            return 0;
        }
        return gutterSize;
    }
}
