// Generated at 2018-08-03T19:56:20.760Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AllService } from './All';
import { CategoryService } from './Category';
import { FavoritesService } from './Favorites';
import { StateService } from './State';
import { TagService } from './Tag';
import { TagsService } from './Tags';
import { UserService } from './User';
import { SampleExchangeRequest } from '../../types/SampleExchangeRequest';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class SearchRequestsQueryParams {
    platform?: string;
    tag?: string;
    keyword?: string;
    req_platform?: string;
    req_tag?: string;
    req_keyword?: string;
    days?: string;
    summary?: string;
}

export class RequestsService {
    public static instance: RequestsService;

    public All: AllService;
    public Category: CategoryService;
    public Favorites: FavoritesService;
    public State: StateService;
    public Tag: TagService;
    public Tags: TagsService;
    public User: UserService;

    constructor(public config: TangoServiceConfiguration) {
        this.All = new AllService(this.config);
        this.Category = new CategoryService(this.config);
        this.Favorites = new FavoritesService(this.config);
        this.State = new StateService(this.config);
        this.Tag = new TagService(this.config);
        this.Tags = new TagsService(this.config);
        this.User = new UserService(this.config);

        if (!RequestsService.instance) {
            RequestsService.instance = this;
        }
    }

    searchRequests(queryParams: SearchRequestsQueryParams, headers: any = {}): Observable<Array<SampleExchangeRequest> | any> {
        let s = new Subject<Array<SampleExchangeRequest> | any>();

        let internalSuccessHandler = (result: Array<SampleExchangeRequest>) => {
            s.next(result.map(item => new SampleExchangeRequest(item)));
            s.complete();
        };

        let failureHandler = (error: any) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
        };


        let result = this.config.transport.get('/search/requests', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

    getRequestsById(id: string, headers: any = {}): Observable<SampleExchangeRequest | object> {
        let s = new Subject<SampleExchangeRequest | object>();

        let internalSuccessHandler = (result: SampleExchangeRequest) => {
            s.next(new SampleExchangeRequest(result));
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            id: id,
        };

        let queryParams = {};

        let result = this.config.transport.get('/search/requests/{id}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
