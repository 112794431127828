// Generated at 2018-08-03T19:56:20.716Z

// tslint:disable

import { TangoTransportAdapterInterface } from '@vmw/tango';
import { TangoServiceConfiguration } from '@vmw/tango';
import { TangoListenHandle } from '@vmw/tango';
import { AllService } from './All';
import { CacheService } from './Cache';
import { UrlService } from './Url';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';


export class GetSampleFileQueryParams {
    raw?: string;
}

export class SampleFileService {
    public static instance: SampleFileService;

    public All: AllService;
    public Cache: CacheService;
    public Url: UrlService;

    constructor(public config: TangoServiceConfiguration) {
        this.All = new AllService(this.config);
        this.Cache = new CacheService(this.config);
        this.Url = new UrlService(this.config);

        if (!SampleFileService.instance) {
            SampleFileService.instance = this;
        }
    }

    getSampleFile(sampleFileId: string, queryParams: GetSampleFileQueryParams, headers: any = {}): Observable<any | object> {
        let s = new Subject<any | object>();

        let internalSuccessHandler = (result: any) => {
            s.complete();
        };

        let failureHandler = (error: object) => {
            s.error(error);
            s.complete();
        };

        let pathParams = {
            sampleFileId: sampleFileId,
        };


        let result = this.config.transport.get('/downloads/sampleFile/{sampleFileId}', pathParams,
            queryParams, headers,
            internalSuccessHandler,
            failureHandler);

        return s;
    }

}
