// tslint:disable


export class SampleExchangeCategory {
    public id: number;
    public parentId: number;
    public type: string;
    public name: string;
    public version: string;
    constructor(json: any) {
        Object.assign(this, json);
    }
}
