// tslint:disable

import { SampleExchangeCategory } from './SampleExchangeCategory';
import { SampleExchangeTag } from './SampleExchangeTag';
import { SampleExchangeSolution } from './SampleExchangeSolution';
import { SampleExchangeComment } from './SampleExchangeComment';
import { SampleExchangeRequestAssignment } from './SampleExchangeRequestAssignment';

export class SampleExchangeRequest {
    public id: number;
    public federationId: string;
    public communitiesUser: string;
    public title: string;
    public request: string;
    public created: string;
    public lastUpdated: string;
    public complete: boolean;
    public duplicateRequestId: number;
    public categories: Array<SampleExchangeCategory>;
    public tags: Array<SampleExchangeTag>;
    public solutions: Array<SampleExchangeSolution>;
    public comments: Array<SampleExchangeComment>;
    public assignments: Array<SampleExchangeRequestAssignment>;
    public state: string;
    public favoriteCount: number;
    constructor(json: any) {
        Object.assign(this, json);
        if (this.categories) {
            this.categories = this.categories.map(item => new SampleExchangeCategory(item));
        }
        if (this.tags) {
            this.tags = this.tags.map(item => new SampleExchangeTag(item));
        }
        if (this.solutions) {
            this.solutions = this.solutions.map(item => new SampleExchangeSolution(item));
        }
        if (this.comments) {
            this.comments = this.comments.map(item => new SampleExchangeComment(item));
        }
        if (this.assignments) {
            this.assignments = this.assignments.map(item => new SampleExchangeRequestAssignment(item));
        }
    }
}
