import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { VmwTaskData, VmwTaskIdentifier, VmwTaskStatus } from '../util/task.model';
import { VmwProgressTrackerConfigService } from '../util/tracker-config.service';
import { Subscription } from 'rxjs';
import { VmwTaskGroupService } from '../util/tasks.service';

@Component({
    selector: 'vmw-progress-tracker-task',
    templateUrl: './progress-tracker-task.component.html',
    styleUrls: ['./progress-tracker-task.component.scss']
})
export class VmwProgressTrackerTaskComponent implements OnDestroy {
    public config: any;
    private _task: VmwTaskData;
    @Input() set task(value: VmwTaskData) {
        if (!this._task || (value && (value.status !== this._task.status))) {
            this.taskGroupService.triggerTaskGroupChangeDetector();
        }
        this._task = value;
    }

    get task(): VmwTaskData {
        return this._task;
    }

    public VmwTaskStatus = VmwTaskStatus;
    public subscription: Subscription;
    public lastCompletedTaskSubscription: Subscription;
    public lastCompletedTask: VmwTaskIdentifier;
    public id: VmwTaskIdentifier;
    public isCompleted: boolean;
    public isNextTaskCompleted: boolean;

    constructor(private trackerConfigService: VmwProgressTrackerConfigService, private taskGroupService: VmwTaskGroupService, private cdr: ChangeDetectorRef) {
        this.subscription = this.trackerConfigService.getConfig().subscribe((value) => {
            this.config = value;
        });
        this.lastCompletedTaskSubscription = this.taskGroupService.getLastCompletedTaskId().subscribe((value: VmwTaskIdentifier) => {
            this.lastCompletedTask = value;
            if (this.id && this.lastCompletedTask) {
                if ((this.id.isLastTask && this.task.status !== VmwTaskStatus.NOT_STARTED)) { //last task of progress tracker
                    this.isNextTaskCompleted = true;
                    this.isCompleted = true;
                } else if ((this.id.groupIndex < this.lastCompletedTask.groupIndex)) { // last not started task is in different task group
                    this.isNextTaskCompleted = true;
                    this.isCompleted = true;
                } else if ((this.id.groupIndex === this.lastCompletedTask.groupIndex)) { // if its belongs to same task group
                    this.isNextTaskCompleted = (this.id.taskIndex + 1 <= this.lastCompletedTask.taskIndex);
                    this.isCompleted = (this.id.taskIndex <= this.lastCompletedTask.taskIndex);
                }
                this.cdr.detectChanges();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.lastCompletedTaskSubscription.unsubscribe();
    }

    isExternalLink(link: string): boolean {
        const linkRegex = RegExp('^https?://.*$', 'g');
        return linkRegex.test(link);
    }

}